<template>
  <div>
    <v-toolbar text color="white" class="mb-5">
      <v-layout wrap>
        <v-flex xs12 sm4 md4 pr-2>
          <v-file-input
            truncate-length="15"
            accept=".xlsx"
            placeholder="Click here to select file"
            @change="selectFile"
            v-model="currentFile"
          ></v-file-input>
        </v-flex>
        <v-flex xs12 sm4 md4 pr-2>
          <v-btn
            :loading="buttonDisable"
            :disabled="buttonDisable"
            color="primary"
            class="ma-2 white--text"
            @click="uploadFile"
          >
            Upload Airport Details
            <v-icon right> mdi-cloud-upload </v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm4 md4 pr-2> </v-flex>
      </v-layout>
    </v-toolbar>
    <v-toolbar text color="white">
      <v-toolbar-title
        >Airport Details (<span>{{ airportListLength }}</span
        >)</v-toolbar-title
      >
      <v-spacer></v-spacer>  
        <v-btn @click="backToInvite" rounded color="primary">Back</v-btn>                
        <v-flex md6 pr-2 >
            <h6>{{updatedDetailsForScreen}}</h6>
        </v-flex>
        <v-flex md3 pr-2 >
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="airportList"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right">{{ props.item.iataCode}}</td>
          <td class="text-xs-right">{{ props.item.airportName }}</td>
          <td class="text-xs-right">{{ props.item.location }}</td>
          <td class="text-xs-right">{{ props.item.country }}</td>
          <td class="text-xs-right">{{ props.item.zone}}</td>
          <td class="text-xs-right">{{ props.item.freightPercentage}}</td>

        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.show"
      top
      right
      rounded
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import MasterScreenService from "../../services/master-screenservices";
import router from "../../router/index";

export default {
  mixins: [],
  name: "AirportDetails",
  data() {
    return {
      currentFile: undefined,
      airportListLength: 0,
      buttonDisable: false,
      search: "",
      updatedDetailsForScreen: '',
      snackbar: {
        show: false,
        message: null,
        color: null,
      },

      
      headers: [
        {
          text: "IATA Code",
          align: "left",
          sortable: true,
          value: "iataCode",
        },
        {
          text: "Airport Name",
          align: "left",
          sortable: true,
          value: "airportName",
        },
        {
          text: "Location",
          align: "left",
          sortable: true,
          value: "location",
        },
        {
          text: "Country",
          align: "left",
          sortable: true,
          value: "country",
        },
        {
          text: "Zone",
          align: "left",
          sortable: true,
          value: "zone",
        },
        {
          text: "Freight Percentage",
          align: "left",
          sortable: true,
          value: "freightPercentage",
        }
      ],
      airportList: [],
    };
  },
  validations: {},
  computed: {},
  created() {
    this.updatedDetailsForScreen = sessionStorage.getItem("updatedDetailsForScreen");
    MasterScreenService.getairPortList().then(
      (response) => {
        this.airportList = response.data;
        this.airportListLength = this.airportList.length;
      }
    );
    this.snackbar = false;
    this.snackbarSample = false;
  },

  methods: {            
    backToInvite() {
      router.push("/master-screen/cds-code-lists");
    },        
    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
    },
    selectFile(file) {
      this.currentFile1 = file;
    },
    uploadFile() {
      if (!this.currentFile) {
        this.snackbar = true;
        this.snackbar = {
          message: "Please select a file to upload",
          color: "red",
          show: true,
        };
        return;
      }

      this.buttonDisable = true;
      MasterScreenService.uploadairPortList(this.currentFile)
        .then((response) => {
          this.snackbar = true;
          if (response.data.success == false) {
            this.snackbar = {
              message: response.data.message,
              color: "red",
              show: true,
            };
            this.buttonDisable = false;
            return;
          }

          this.snackbar = {
            message: response.data.message,
            color: "green",
            show: true,
          };
          return MasterScreenService.getairPortList()
            .then((response) => {
              this.airportList = response.data;
              this.airportListLength = this.airportList.length;
              this.buttonDisable = false;
              this.currentFile = undefined;
            })
            .catch((e) => {
              console.log(e);
              this.buttonDisable = false;
            });
        })
        .catch((e) => {
          this.snackbar = true;
          console.log(e)
          this.snackbar = {
            message: e.response.data.errors.File[0],
            color: "red",
            show: true,
          };
          this.buttonDisable = false;
          return;
        });
    },
  },
};
</script>

<style>
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
  justify-content: CENTER;
  height: 100%;
  margin-top: 30px;
}
</style>