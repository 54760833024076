<template lang="html">
    <div>
       <v-toolbar text color="white">
        <v-toolbar-title>CUSTOMS OFFICES CODES ({{getData.length}})</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
        <br>
        <v-card-title class="justify-content-end">
        <v-col cols="3">
        <v-select
          placeholder="Filter By Country Code"
          :items="getCountry"
          class="filter-status mr-2"
          item-text="iso"
          item-value="iso"
          @change="filterByCountry($event)">
        </v-select>
        </v-col>
        </v-card-title>
      <v-data-table :headers="headers" :items="getData" 
           :search="search" class="elevation-1">
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-right" >{{ props.item.usualName }}</td>
            <td class="text-xs-right">{{ props.item.countryCode }}</td>
            <td class="text-xs-right">{{ props.item.colCode }}</td>
          </tr>
        </template>
<template v-slot:no-data>
  <span>No data found</span>
</template>
</v-data-table>

</div>
</template>

<script>
import MasterScreenService from "../../services/master-screenservices";

export default {
  name: "CustomsOffices",
  data() {
    return {
      search: "",
      getData: [],
      getCountry: [],
      headers: [
        {
          text: "Customs office",
          align: "left",
          sortable: true,
          value: "usualName",
        },
        {
          text: "Country Code",
          align: "left",
          sortable: true,
          value: "countryCode",
        },
        {
          text: "Office code to be declared in Data Element 5/27",
          align: "left",
          sortable: true,
          value: "colCode",
        },
      ],
    };
  },
  computed: {},
  created() {
    MasterScreenService.getCustomsOffice({ countryCodes: [] })
      .then((response) => {
        this.getData = response.data;
      })
      .catch((e) => {
        console.log(e);
      });

    MasterScreenService.getMasterCountries()
      .then((response) => {
        this.getCountry = response.data;
        this.getCountry.unshift({ iso: "Select" });
      })
      .catch((e) => {
        console.log(e);
      });
  },

  methods: {
    filterByCountry(value) {
      if (value != "" && value != null && value != "Select") {
        value = { countryCodes: [value] };
      } else {
        value = { countryCodes: [] };
      }
      MasterScreenService.getCustomsOffice(value)
        .then((response) => {
          this.getData = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>

<style>
.justify-content-end {
  justify-content: Flex-end;
}
</style>