// import http from '../store/http-common';
import httpInstance from '../store/interceptor';

class AccountService {
    /**new apis */
    getsiteDetails(id) {
        return httpInstance.get(`/v1/accounts/accountconfiguration/bygroupaccount/${id}`);
    }
    getAccountConfigurations(id) {
        return httpInstance.get(`/v1/accounts/accountconfiguration/${id}`);
    }
    updateAccount(data){
        return httpInstance.put('/v1/accounts/accountconfiguration', data);
    }
    /** */
    inviteAccount(data) {
        return httpInstance.post('v1/accounts/accountinvite/group', data);
    }
    getTariff(supplierChainCode) {
        return httpInstance.get(`v1/accounts/accountinvite/tariff/${supplierChainCode}`);
    }
    getSupplierChain() {
        return httpInstance.get('v1/accounts/accountinvite/supplychain');
    }
    getSiteCodeByGroupCode(group_code) {
        return httpInstance.get(`/account/siteaccount?group_code=${group_code}`);
    }
    getGroupCodeData() {
        return httpInstance.get('/account/group_account_list');
    }
    getGroupDatas() {
        return httpInstance.get('/v1/admin/listgroups');
    }
    getSiteDatas(Id) {
        return httpInstance.get('/v1/admin/listsites?groupId=' + Id);
    }
    changeTariff(data) {
        return httpInstance.put('/v1/admin/Users/' + data.account_id, data);
    }   
    getList(status, supplyChain, grpcode) {
        return httpInstance.get(`/v1/accounts/accountinvite/getinvitedgroupinfo/filter?status=${encodeURIComponent(status)}&supplyChain=${encodeURIComponent(supplyChain)}&groupCode=${encodeURIComponent(grpcode)}`);
    }
    updateSupplyChain(data) {
        return httpInstance.post('v1/accounts/accountinvite/updatesupplychains', data);
    }
    revokeInvite(data) {
        return httpInstance.post('v1/accounts/accountinvite/revokegroupinvitation', data);
    }
    resendInvite(data) {
        return httpInstance.put('v1/accounts/accountinvite/resendinvitegroup', data);
    }
    resendverificationMail(data) {
        return httpInstance.put('v1/accounts/accountinvite/ResendGroupVerificationEmail', data);
    }
    addReleaseNote(data) {
        return httpInstance.put('v1/accounts/account/release_notes', data);
    }
    getReleaseNotes(data) {
        return httpInstance.get('v1/accounts/account/release_notes', data);
    }
    updateReleaseNote(data) {
        return httpInstance.put('v1/accounts/account/release_notes/update', data);
    }
    checkAccount(accountName) {
        return httpInstance.get('v1/accounts/account/check/' + accountName);
    }
    resetAuth0Password(data){
        return httpInstance.post('v1/accounts/user/auth0passwordchangerequest',data);
    }
    getAccountById(id){
        return httpInstance.get('v1/accounts/account/'+id);
    }
    getSiteAccountsForGroup(groupAccountId){
        return httpInstance.get('v1/accounts/account/SiteAccountsForGroup/'+groupAccountId);
    }
    updateAccountDetails(data){
        return httpInstance.put('/v1/accounts/account', data);
    }
    getAllSites(){
        return httpInstance.get('v1/accounts/account/SiteAccounts');
    }
    updateAccountSimple(account_id, update){
        return httpInstance.put(`/v1/accounts/account/update/${account_id}`, update);
    }
    getSupplyChains(accountId, groupCode){
        return httpInstance.get('v1/accounts/account/GetSupplyChains/'+accountId +'/' + groupCode);
    }
    getAllSupplyChains(){
        return httpInstance.get('v1/accounts/account/GetSupplyChains');
    }
    addSupplyChainShipments(data){
        return httpInstance.post('v3/supplychain',data);
    }
    addSupplyChainAccounts(data){
        return httpInstance.post('v1/accounts/account/addSupplyChain',data);
    }
    addTariffsAccounts(data){
        return httpInstance.post('v1/accounts/account/addTariffs',data);
    }

    getSupplyChainById(id){
        return httpInstance.get(`v1/accounts/supplychain/getbyid/${id}`);
    }

    getSupplyChainByIdShipments(suppyChainId){
        return httpInstance.get('v3/supplychain/'+ suppyChainId +'/' + 'configuration_full');
    }
    
    updateGroupAccount(account_id, update){
        return httpInstance.put(`/v1/accounts/account/updategroupaccount/${account_id}`, update);
        
    }
    
    updateSiteAccount(account_id, update){
        return httpInstance.put(`/v1/accounts/account/updatesiteaccount/${account_id}`, update);
    }
}
export default new AccountService();
