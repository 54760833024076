<template>
  <v-menu offset-y close-on-content-click>
    <template v-slot:activator="{ on }">
      <v-icon v-on="on">mdi-account</v-icon>
    </template>
    <v-list>
      <!-- <v-list-item class="pointer" @click="profile">
        <v-list-item-icon>
          <v-icon color="primary">mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="primary--text">Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item class="pointer" @click="logout">
        <v-list-item-icon>
          <v-icon color="primary">mdi-power</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="primary--text">Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { validationMixin } from "vuelidate";
import router from "../../router";

export default {
  mixins: [validationMixin],

  data() {
    return {
      dialog: false,
      snackbar: false,
      error: `Please fill the form correctly.`,
      status: null,
      submitStatus: null,
      user: "",
    };
  },
  validations: {
  },
  computed: {
    mini() {
      return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
    },
  },
  methods: {
    profile() {
      router.push("/profile").catch(() => {});
    },
    logout() {
      this.$auth.logout({ returnTo: document.location.origin });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}

.pointer:hover {
  background: #f8f8f8;
}

.headline {
  color: #ffffff;
  background: #1976d2;
}
</style>