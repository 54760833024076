<template>
  <div>
    <v-toolbar text color="white">
      <v-toolbar-title>Supply Chains</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
            
      <v-dialog persistent v-model="dialog" max-width="1000px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on" @click="addSupplyChainDialog"
            >Add New Supply Chain</v-btn
          >
        </template>
        <v-card>
          <form @submit.prevent="updateSupplyChain">
            <v-card-title class="headline">
              <span>{{ dialog_head }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="name"
                      :error-messages="nameErrors"
                      label="Supply Chain Name"
                      placeholder="Enter supply chain name"
                      required
                      outlined
                      @input="$v.name.$touch()"
                      @blur="$v.name.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-flex xs12 sm6 md6>
                      <v-switch class="ml-5 custom_toggle"
                        v-model="gefs"
                        hide-details
                        color="info"
                        label="GEFS"
                        :disabled="enableGefs"
                      ></v-switch>
                    </v-flex>
                  </v-flex>
                  <v-flex><v-card-text style="font-size: large; margin-top: -40px"></v-card-text></v-flex>
                  <v-flex xs12 sm6 md6></v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="length"
                        :error-messages="lengthErrors"
                        label="Barcode Length"
                        placeholder="Enter Barcode Length"
                        outlined
                        @input="$v.length.$touch()"
                        @blur="$v.length.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="upc_min_length"
                      :error-messages="upc_min_lengthErrors"
                      label="UPC Min Length"
                      placeholder="Enter UPC Minimum Length"
                      outlined
                      @input="$v.upc_min_length.$touch()"
                      @blur="$v.upc_min_length.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="upc_max_length"
                        :error-messages="upc_max_lengthErrors"
                        label="UPC Max Length"
                        placeholder="Enter UPC Maximum Length"
                        outlined
                        @input="$v.upc_max_length.$touch()"
                        @blur="$v.upc_max_length.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="upc_position"
                        :error-messages="upc_positionErrors"
                        label="UPC Position"
                        placeholder="Enter UPC Position"
                        outlined
                        @input="$v.upc_position.$touch()"
                        @blur="$v.upc_position.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="batch_id_length"
                        :error-messages="batch_id_lengthErrors"
                        label="Batch ID Length"
                        placeholder="Enter Batch ID Length"
                        outlined
                        @input="$v.batch_id_length.$touch()"
                        @blur="$v.batch_id_length.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="batch_id_position"
                        :error-messages="batch_id_positionErrors"
                        label="Batch ID Position"
                        placeholder="Enter Batch ID Position"
                        outlined
                        @input="$v.batch_id_position.$touch()"
                        @blur="$v.batch_id_position.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="supplier_code_length"
                        :error-messages="supplier_code_lengthErrors"
                        label="Supplier Code Length"
                        placeholder="Enter Supplier Code Length"
                        outlined
                        @input="$v.supplier_code_length.$touch()"
                        @blur="$v.supplier_code_length.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="supplier_code_position"
                        :error-messages="supplier_code_positionErrors"
                        label="Supplier Code Position"
                        placeholder="Enter Supplier Code Position"
                        outlined
                        @input="$v.supplier_code_position.$touch()"
                        @blur="$v.supplier_code_position.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="validation_text"
                        :error-messages="validation_textErrors"
                        label="Validation Text"
                        placeholder="Enter validation text"
                        outlined
                        @input="$v.validation_text.$touch()"
                        @blur="$v.validation_text.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                        v-model="upc_display_label"
                        label="UPC Display Label"
                        placeholder="Enter UPC Display Label"
                        outlined
                        @input="$v.upc_display_label.$touch()"
                        @blur="$v.upc_display_label.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-flex xs12 sm12 md6>
                      <v-switch class="ml-5 custom_toggle"
                        v-model="isAutoApportionEnabled"
                        hide-details
                        color="info"
                        label="Auto apportion of gross weights"
                      ></v-switch>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-flex xs12 sm12 md6>
                      <v-switch class="ml-5 custom_toggle"
                        v-model="isAuditLogEnabled"
                        hide-details
                        color="info"
                        label="Audit Log"
                      ></v-switch>
                    </v-flex>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-flex xs12 sm12 md8>
                      <v-switch class="ml-5 custom_toggle"
                        v-model="isNotesAndDocumentEnabled"
                        hide-details
                        color="info"
                        label="Notes And Documents"
                      ></v-switch>
                    </v-flex>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions style="margin-top: -30px">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
              <v-btn
                  v-if="editflag === false"
                color="blue darken-1"
                text
                @click="addSupplyChain()"
                >Save</v-btn
              >
              <v-btn
                color="blue darken-1"
                v-if="editflag === true"
                text
                @click="updateSupplyChain()"
                >Update</v-btn
              >
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <br>
      <v-snackbar
                :color="snackbar.color"
                v-model="snackbar.show"
                top
                right
                rounded
              >
                {{ snackbar.message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="closeSnackbar"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
    <!-- <v-data-table :headers="headers" :items="getData" class="elevation-1"> -->
    <v-data-table :headers="headers" :items="getData" 
    :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right">{{ props.item.name }}</td>
          <td class="px-0">
            <v-icon class="mr-2" @click="editSupplyChain(props.item.id, props.item.name, props.item.data_compliance_enabled,props.item.product_approval_enabled)">
              mdi-account-edit
            </v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AccountService from "../services/accountservices";
import {v4 as uuidv4} from 'uuid';
export default {
  mixins: [validationMixin],
  data() {
    return {
      name: "",
      length: "",
      upc_min_length: "",
      upc_max_length: "",
      upc_position: "",
      batch_id_length: "",
      batch_id_position: "",
      supplier_code_length: "",
      supplier_code_position: "",
      gefs: false,
      isAutoApportionEnabled: false,
      isAuditLogEnabled: false,
      isNotesAndDocumentEnabled: false,
      enableGefs : true,
      upc_display_label: "Unique Product Code (UPC)",
      validation_text: "",
      itemId: "",
      dialog_head: "",
      getData: [],
      getType: [],
      nameFilter: "",
      editId: "",
      editbarcode: "",
      data_compliance_enabled: false,
      product_approval_enabled: false,
      editflag: false,
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      dialog: false,
      submitStatus: null,
      sortBy: 'name',
      sortDesc: false,
      headers: [
        { text: "Name", align: "left", sortable: true, value: "name" },
        { text: "Actions", align: "left", sortable: false },
      ],
    };
  },
  validations: {
    name: { required },
    length: { required },
    upc_min_length: { required },
    upc_max_length: { required },
    upc_position: { required },
    batch_id_length: { required },
    batch_id_position: { required },
    supplier_code_length: { required },
    supplier_code_position: { required },
    validation_text: { required },
  },
  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Supply Chain Name is required");
      return errors;
    },
    lengthErrors() {
      const errors = [];
      if (!this.$v.length.$dirty) return errors;
      !this.$v.length.required && errors.push("Length is required");
      return errors;
    },
    upc_min_lengthErrors() {
      const errors = [];
      if (!this.$v.upc_min_length.$dirty) return errors;
      !this.$v.upc_min_length.required && errors.push("UPC Min Length is required");
      return errors;
    },
    upc_max_lengthErrors() {
      const errors = [];
      if (!this.$v.upc_max_length.$dirty) return errors;
      !this.$v.upc_max_length.required && errors.push("UPC Max Length is required");
      return errors;
    },
    upc_positionErrors() {
      const errors = [];
      if (!this.$v.upc_position.$dirty) return errors;
      !this.$v.upc_position.required && errors.push("UPC Position is required");
      return errors;
    },
    batch_id_lengthErrors() {
      const errors = [];
      if (!this.$v.batch_id_length.$dirty) return errors;
      !this.$v.batch_id_length.required && errors.push("Batch ID Length is required");
      return errors;
    },
    batch_id_positionErrors() {
      const errors = [];
      if (!this.$v.batch_id_position.$dirty) return errors;
      !this.$v.batch_id_position.required && errors.push("Batch ID Position is required");
      return errors;
    },
    supplier_code_lengthErrors() {
      const errors = [];
      if (!this.$v.supplier_code_length.$dirty) return errors;
      !this.$v.supplier_code_length.required && errors.push("Supplier Code Length is required");
      return errors;
    },
    supplier_code_positionErrors() {
      const errors = [];
      if (!this.$v.supplier_code_position.$dirty) return errors;
      !this.$v.supplier_code_position.required && errors.push("Supplier Code Position is required");
      return errors;
    },
    validation_textErrors() {
      const errors = [];
      if (!this.$v.validation_text.$dirty) return errors;
      !this.$v.validation_text.required && errors.push("Validation text is required");
      return errors;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.getAllSupplyChains();
  },
  methods: {
    getAllSupplyChains(){
      AccountService.getAllSupplyChains().then((response) => {
        console.log(response.data);
        this.getData = response.data;
      });
    },
    updateSupplyChain() {
      this.editflag = true;
      this.addSupplyChain();
    },
    addSupplyChainDialog() {
      this.enableGefs = false;
      this.dialog_head = "Add New Supply Chain";
    },
    addSupplyChain(){
      var barcodeuuid = uuidv4();
      var supplychainuuid = uuidv4();
      if(this.editId && this.editflag) supplychainuuid = this.editId;
      if(this.editbarcode && this.editflag) barcodeuuid = this.editbarcode;
      if (this.upc_display_label ==  null || this.upc_display_label === "") {
        this.upc_display_label = "Unique Product Code (UPC)";
      }
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.snackbar = true;
        this.snackbar = {
          message: "Please fill the form correctly",
          color: "error",
          show: true,
        };
      } else {
      var supplychainconfig = {
        id: barcodeuuid,
        name: this.name,
        supply_chain_id: supplychainuuid,
        barcode : {
          length: this.length,
          upc_min_length: this.upc_min_length,
          upc_max_length: this.upc_max_length,
          upc_position: this.upc_position,
          batch_id_length: this.batch_id_length,
          batch_id_position: this.batch_id_position,
          supplier_code_length: this.supplier_code_length,
          supplier_code_position: this.supplier_code_position,
          validation_text: this.validation_text,
        },
        upc_display_label: this.upc_display_label,
      };
      AccountService.addSupplyChainShipments(supplychainconfig)
          .then((response) => {
            if (response) {
                var supplychain = {
                  id: supplychainuuid,
                  name: this.name,
                  barcode_id: barcodeuuid,
                  data_compliance_enabled: this.data_compliance_enabled,
                  product_approval_enabled: this.product_approval_enabled,
                  isGefsProductCheckingEnabled : this.gefs,
                  auto_apportion_enabled : this.isAutoApportionEnabled,
                  audit_log_enabled : this.isAuditLogEnabled,
                  notes_documents_enabled : this.isNotesAndDocumentEnabled,
                };
                AccountService.addSupplyChainAccounts(supplychain)
                    .then((response) => {
                      if (response) {
                        if (!this.editflag) {this.saveTariffs(supplychainuuid)} else {
                          this.getAllSupplyChains();
                          this.snackbar = true;
                          this.snackbar = {
                            message: "Supply Chain updated succesfully",
                            color: "success",
                            show: true,
                          };
                          this.dialog = false;
                          this.resetFields();
                          this.editflag = false;
                        }
                      }
                    })
                    .catch((e) => {
                      console.log(e);
                      this.snackbar = true;
                      this.snackbar = {
                        message: "OOPS!SOMETHING WENT WRONG",
                        color: "error",
                        show: true,
                      };
                    });
              }
            }).catch((e) => {
        console.log(e);
        this.snackbar = true;
        this.snackbar = {
          message: "OOPS!SOMETHING WENT WRONG",
          color: "error",
          show: true,
        };
      });
     }
    },
    getSupplyChainById(){
      AccountService.getSupplyChainById(this.editId)
        .then((response) => {
          this.isAuditLogEnabled = response.data.audit_log_enabled;
          this.isAutoApportionEnabled = response.data.auto_apportion_enabled;
          this.isNotesAndDocumentEnabled = response.data.notes_documents_enabled;
        })
        .catch((e) => {
          console.log(e);
          this.snackbar = true;
          this.snackbar = {
            message: "OOPS!SOMETHING WENT WRONG",
            color: "error",
            show: true,
          };
        });
    },
    saveTariffs(supplychainuuid){
      var tariffUuid = uuidv4();
      var tariff = {
        id: tariffUuid,
        name: this.name,
        supply_chain_id: supplychainuuid,
        trial_expires: new Date().toLocaleDateString(),
        has_trial_period: "False",
        default_site_tariff: "a8fbaddc-6f2a-4fbf-bd8e-58883f245dc1"
      };
      AccountService.addTariffsAccounts(tariff)
          .then((response) => {
            if (response) {
              console.log(response);
              this.getAllSupplyChains();
              this.dialog = false;
              this.snackbar = true;
              this.snackbar = {
                message: "Supply Chain created succesfully",
                color: "success",
                show: true,
              };
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
    },
    editSupplyChain(id, name, data_compliance_enabled, product_approval_enabled) {
      this.fetchSupplyChainConfig(id);
      this.editId = id;
      this.editflag = true;
      this.dialog_head = name;
      this.data_compliance_enabled = data_compliance_enabled;
      this.product_approval_enabled = product_approval_enabled;
      this.dialog = true;
      this.getSupplyChainById();
    },
    fetchSupplyChainConfig(id){
      AccountService.getSupplyChainByIdShipments(id).then((response) => {
        if (response) {
          console.log("incoming response", response)
          this.checkSupplyChain(response.data.name);
          this.name = response.data.name;
          this.length = response.data.barcode.length;
          this.upc_min_length = response.data.barcode.upc_min_length;
          this.upc_max_length = response.data.barcode.upc_max_length;
          this.upc_position = response.data.barcode.upc_position;
          this.batch_id_length = response.data.barcode.batch_id_length;
          this.batch_id_position = response.data.barcode.batch_id_position;
          this.supplier_code_length = response.data.barcode.supplier_code_length;
          this.supplier_code_position = response.data.barcode.supplier_code_position;
          this.validation_text = response.data.barcode.validation_text;
          this.upc_display_label = response.data.upc_display_label;
          this.editbarcode = response.data.id;
          this.editId = response.data.supply_chain_id;
          this.gefs = response.data.isGefsProductCheckingEnabled;
        }
      }).catch((e) => {
        console.log(e);
        this.snackbar = true;
        this.snackbar = {
          message: "OOPS!SOMETHING WENT WRONG",
          color: "error",
          show: true,
        };
      });
    },
    checkSupplyChain(name){
    this.enableGefs = (name === 'P2DL' || name === 'p2dl') ? false : true;
    },
    close() {
      this.dialog = false;
    },
    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
      this.resetFields();
    },
    resetFields(){
      this.editflag = false;
      this.length = '';
      this.upc_min_length = '';
      this.upc_max_length = '';
      this.upc_position = '';
      this.batch_id_length = '';
      this.batch_id_position = '';
      this.supplier_code_length = '';
      this.supplier_code_position = '';
      this.upc_display_label = '';
      this.name = '';
      this.validation_text = '';
      this.editbarcode = null;
      this.editId = null;
      this.gefs = false;
      this.isAuditLogEnabled = false;
      this.isAutoApportionEnabled = false;
      this.isNotesAndDocumentEnabled = false;
    },
    cancel() {
      this.resetFields();
      this.dialog = false;
      this.editflag = false;
      this.$v.$reset(); 
      this.snackbar = {
        show: false,
      };
    },
  },
};
</script>
<style>
.pointer {
    cursor: pointer;
}
.custom_toggle .v-input__control .v-input__slot{
  width: fit-content;
}
</style>