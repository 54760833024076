<template>
    <v-layout row wrap>
        <v-flex xs12 sm6 xl6 px-5>
            <v-card class="py-3 mh-300">
                <v-flex xs12 sm12 md12 align-center justify-center layout text-xs-center>
                    <v-avatar color="grey lighten-4">
                        <v-img class="white--text" height="200px"
                            src="https://cdn.vuetifyjs.com/images/cards/docks.jpg" />

                    </v-avatar>
                </v-flex>
                <v-container fill-height fluid>
                    <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                            <v-icon>mdi-account</v-icon>
                            <span>Personal Info</span>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-title>
                    <div>
                        <span class="grey--text">Number 10</span><br>
                        <span>Whitehaven Beach</span><br>
                        <span>Whitsunday Island, Whitsunday Islands</span>
                    </div>
                </v-card-title>

            </v-card>
        </v-flex>
        <v-flex xs12 sm6 xl6 px-5>
            <v-card class="py-3 mh-300">
                <v-container fill-height fluid>
                    <v-layout fill-height>
                        <v-flex xs12 align-end flexbox>
                            <v-icon>mdi-phone-hangup</v-icon>
                            <span>Contact Info</span>
                        </v-flex>
                    </v-layout>
                </v-container>
                <v-card-title>
                    <div>
                        <span class="grey--text">Number 10</span><br>
                        <span>Whitehaven Beach</span><br>
                        <span>Whitsunday Island, Whitsunday Islands</span>
                    </div>
                </v-card-title>
            </v-card>
        </v-flex>
        <v-flex xs12 sm12 xl12 px-5>
            <v-card-actions>
                <v-btn color="blue" class="text-white">Edit Profile</v-btn>
                <v-btn color="blue" class="text-white">Delete</v-btn>
            </v-card-actions>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        // name: 'Dashboard',
    }
</script>
<style>
    .text-white {
        color: #ffffff !important;
    }

    .mh-300 {
        min-height: 300px;
    }
</style>