// import http from '../store/http-common';
import httpInstance from '../store/interceptor';

class RoleManagementService {
    saveRole(data) {
        return httpInstance.post('/v1/accounts/role', data);
    }
    editRole(data) {
        return httpInstance.put('/v1/accounts/role/' + data.id, data);
    }
    getAllRole() {
        return httpInstance.get('/v1/accounts/role');
    }
    getAllRoleByAccountType(accountType) {
        return httpInstance.get(`/v1/accounts/role?type=${accountType}`);
    }
    deleteRole(id) {
        return httpInstance.delete('/v1/accounts/role/' + id);
    }
    getRoleById(id) {
        return httpInstance.get('/v1/accounts/role/' + id);
    }
}
export default new RoleManagementService();