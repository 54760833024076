
import httpInstance from '../store/interceptor';

class UserManagementService {
    getgroupaccounts(){
      return httpInstance.get(`/v1/accounts/account/groupaccounts`);
    }
    getsiteAccountonSearch(accountId){
      return httpInstance.get(`/v1/accounts/account/siteaccounts/${accountId}`);
    }
    getuserlist(siteid){
      return httpInstance.get(`/v1/accounts/userrole/${siteid}`);
    }
    getUserById(id) {
      return httpInstance.get(`/v1/accounts/userrole/${id}`);
    }
    saveUser(user) {
      return httpInstance.put('v1/accounts/userrole', user);
    }
    getAllRoles(){
      return httpInstance.get(`/v1/accounts/role`);
    }
    getRoleForUser(user_id, account_id){
        return httpInstance.get(`/v1/accounts/user/userrole/${user_id}/${account_id}`);
    }
    getUserByEmail(email){
        return httpInstance.get(`/v1/accounts/user/byemail/${email}`);
    }
    getEmails(){
        return httpInstance.get(`/v1/accounts/user/emails`);
    }
    getGroupsByEmail(email){
        return httpInstance.get(`/v1/accounts/user/groupsbyemail/${email}`);
    }
    getMappedAccountsForUser(user_id){
        return httpInstance.get(`/v1/accounts/user/${user_id}/accounts`);
    }
    getUsers() {
      return httpInstance.get('/api/user/get');
    }
    updateUserDetails(user_id, user){
        return httpInstance.put(`/v1/accounts/user/${user_id}`, user);
    }
    updateSupportUser(user_id, account_id, update){
        return httpInstance.put(`/v1/accounts/user/supportuser/${user_id}/${account_id}`, update);
    }
    addSupportUserToAccount(user_id, account_id, update){
        return httpInstance.put(`/v1/accounts/user/addsupportuser/${user_id}/${account_id}`, update);
    }
    getUsersforAccount(account_id){
        return httpInstance.get(`/v1/accounts/user/iambyaccount/${account_id}`);
    }
    addDefaultRoleToAccount(user_id, account_id, update){
        return httpInstance.put(`/v1/accounts/user/addsupportuserrole/${user_id}/${account_id}`, update);
    }
}
export default new UserManagementService();
