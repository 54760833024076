<template>
  <div>
    <v-snackbar
        :color="snackbar.color"
        v-model="snackbar.show"
        top
        right
        rounded
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-spacer></v-spacer>
    <v-card>
      <v-card-title class="headline">
        <b>{{this.account.group_code + this.account.site_code}}</b>
      </v-card-title>
    <v-spacer></v-spacer>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.site_code" label="Site Code" placeholder="Enter Site Code" outlined disabled />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.account_type" label="Account Type" outlined disabled />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.site_name" label="Site Name" placeholder="Site Name" outlined  />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.site_type" label="Site Type" placeholder="Site Type" outlined disabled />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-select
                  :items="modules"
                  outlined
                  v-model="selectedModule"
                  label="Account Module"
                  multiple
                  @change="getSelectedModule($event)"
                  item-text="displayText"
                  item-value="key"
              ></v-select>
            </v-flex>
             <v-flex xs12 sm6 md6>
              <v-text-field type="number"  oninput="if(this.value <= 0) this.value = null;"
               v-if = "account.account_type === 'SiteAccount'" 
               v-model="account.rossum_queue_number" :disabled="accountQueueNumberDisable"
               label="Rossum Queue Number" placeholder="Rossum Queue Number" outlined />
            </v-flex>
          </v-layout>
        </v-container>
        
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text v-on:click="updateAccount()">
          Update</v-btn>
        <v-btn color="blue darken-1" text v-on:click="cancel">Back</v-btn>
      </v-card-actions>
    </v-card>
    <v-spacer></v-spacer>
    <v-card v-if="userlist.length > 0" style="margin-top: 10px">
      <v-card-title> User List</v-card-title>
    <v-data-table :headers="headers" :items="userlist" class="elevation-1">
      <template v-slot:item="props">
        <tr @click="onClick(props.item)">
          <td class="text-xs-right">
            {{ props.item.first_name +'                    '+ props.item.last_name}} <span v-if="props.item.is_support_access"> ( Support User ) </span>
          </td>
          <td class="text-xs-right">
            {{ props.item.email}}
          </td>
        </tr>
        <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.show"
            top
            right
            rounded
        >
          {{ snackbar.message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
    </v-card>
    <v-spacer></v-spacer>
    <v-card v-if="siteAccounts.length > 0" style="margin-top: 10px">
      <v-card-title> Site List</v-card-title>
    <v-data-table :headers="siteheaders" :items="siteAccounts" class="elevation-1">
      <template v-slot:item="props">
        <tr @click="onClickSiteAccount(props.item)">
          <td class="text-xs-right">
            {{ props.item.site_code}}
          </td>
          <td class="text-xs-right">
            {{ props.item.site_name}}
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
    </v-card>
  </div>
</template>
<script>
import UserManagementService from "../../services/user-managementservices";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AccountService from "../../services/accountservices";

// minLength
export default {
  mixins: [validationMixin],
  data() {
    return {
      modules: [
        { key: 'cds', displayText: 'CDS' },
        { key: 'sps', displayText: 'SPS' },
        { key: 'trackandtrace', displayText: 'Track & Trace' },
      ],
      account: null,
      supplyChainList: [],
      siteAccounts: [],
      supplyid: [],
      supplyList: [],
      entries:[],
      userlist:[],
      getAllroles:[],
      role: [],
      user_id:"",
      selectedModule: [],
      account_id: "",
      name: "",
      userDialoghead: "",
      getData: [],
      siteId: "",
      title: "",
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      phone: "",
      company: "",
      edituserint: false,
      currentUser: null,
      account_email_map: null,
      accountQueueNumberDisable: false,
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      dialog: false,
      user: false,
      selected_user: null,
      accountsforuser : [],
      item : null,
      headers: [
        { text: "User", align: "left", sortable: true, value: "sitename" },
        { text: "Email", align: "left", sortable: true, value: "" },
      ],
      siteheaders: [
        { text: "Site Code", align: "left", sortable: true, value: "site_code" },
        { text: "Site Name", align: "left", sortable: true, value: "site_name" },
      ],
    };
  },
  validations: {
    role:{ required },
  },
  computed: {
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  getSelectedModule(event){
    this.selectedModule = event;
  },

  created() {
    console.log(this.$router.currentRoute.params)
    if (!this.$router.currentRoute.params.site) {
      //leave page
      this.$router.push("/ManageGroups");
    }
    this.account_id = this.$router.currentRoute.params.id;
    this.item = this.$router.currentRoute.params.site;
    console.log(this.item);
    this.account = this.item;
     if(this.account.account_type === 'SiteAccount'){
        this.modules.push({ key: 'ocr', displayText: 'OCR' })
     }
    this.load();
    AccountService.getAccountConfigurations(this.item.id)
        .then((response) => {
          this.selectedModule = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
   // this.getUserRoles();
    //this.getEmails();
    //this.getSupplyChain();
  },
  reload() {
    this.$router.go();
  },
  methods: {
    getSitesForGroupAccount(){
      AccountService.getSiteAccountsForGroup(this.account.id).then((response) => {
        console.log(response.data);
        this.siteAccounts = response.data;
      });
    },
    getEmails(){
      UserManagementService.getEmails().then((response) => {
        this.entries = response.data;
      });
    },
   onClickSiteAccount(site){
     this.site = site;
     this.account_id = site.account_id;
     this.item = site;
     this.account = this.item;
     this.load();
  },
    getSelectedModule(event){
      this.selectedModule = event;
    },
   getUserRoles(){
     UserManagementService.getAllRoles()
        .then((response) => {
          this.getAllroles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
   },
    getMappedAccountsForUser(user_id){
      UserManagementService.getMappedAccountsForUser(user_id)
        .then((response) => {
          this.accountsforuser = response.data;
          console.log('ACCOUNTS');
          console.log(this.accountsforuser);
        }).catch((e) => {
          console.log(e);
        });
    },
    userCreateForm() {
      this.$v.$touch();
      if (this.$v.role.$invalid) {
        this.snackbar = true;
        this.snackbar = {
          message: "Please fill the form correctly",
          color: "error",
          show: true,
        };
      } else {
        var userEditData = {
            "user_id": this.selected_user.id,
            "account_id": this.account_id,
            "roles": this.role
        };
        UserManagementService.saveUser(userEditData)
          .then((response) => {
            if (response) {
              console.log(response);
              this.snackbar = {
                message: "User role updated succesfully",
                color: "success",
                show: true,
              };
            }

          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      }
    },
    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    cancel() {
      this.clear();
      this.dialog = false;
      this.user = false;
      this.$router.push("/ManageGroups");
    },
    clear() {
      this.$v.$reset();
      this.first_name = "";
      this.last_name = "";
      this.username = "";
      this.title = "";
      this.role = [];
      this.email = "";
      this.phone = "";
      this.user_id = "";
      this.account_id = "";
    },
    assign() {
      this.user = false;
    },

    getSupplyChain() {
      AccountService.getSupplierChain()
          .then((response) => {
            if (response) {
              this.supplyChainList = response.data;
              this.load();
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      this.forFilterSullpChain.push({"name":"M&S"});
      this.forFilterSullpChain.push({"name":"P2DL"});
      this.forFilterSullpChain.push({"name":"Costa"});
      this.forFilterSullpChain.push({"name":"XPO"});
      this.forFilterSullpChain.push({"name":"All"});
    },
    load() {
      console.log('load')
      var item = this.account;
      console.log(item);
      UserManagementService.getUsersforAccount(item.id)
          .then((response) => {
            if (response) {
              this.userlist = response.data;
              console.log(this.userlist);
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      AccountService.getAccountById(item.id)
          .then((response) => {
            if (response) {
              this.account = response.data;
              this.account_id = this.account.id;
              this.accountQueueNumberDisable = this.account.rossum_queue_number ? true : false;
              console.log(response.data);
              this.getSitesForGroupAccount();
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      this.emailedit = item.email;
      this.groupcodeedit = item.groupcode;
      this.accountEdit = item.accountid;
      this.dialog = true;
    },
  onClick(user){
      console.log('clicked');
      console.log(user);
    this.$router.push({
      name: 'userManagement',
      params: {
        user:user,
      },
    });
  },
    updateAccountConfig(){
      var updateAccounts = {
        "account_id": this.account.id,
        "modules": this.selectedModule
      };
      AccountService.updateAccount(updateAccounts)
          .then((response) => {
            if (response) {
              AccountService.getAccountById(this.account.id).then((response) => {
                  this.account = response.data;
                  console.log(response.data);
                  this.snackbar = {
                    message: "Account updated succesfully",
                    color: "success",
                    show: true,
                  };
              });
              this.accountQueueNumberDisable = this.account.rossum_queue_number ? true : false;
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
    },
    updateAccount(){
        var data = {
          "account_id": this.account.id,
          "site_name": this.account.site_name,
          "account_type": this.account.account_type,
          "rossum_queue_number": this.account.rossum_queue_number == "" ? null : this.account.rossum_queue_number
        };

        AccountService.updateSiteAccount(this.account.id, data)
            .then((response) => {
              if (response) {
                this.updateAccountConfig();
              }
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = true;
              this.snackbar = {
                message: e.response.data.detail ? e.response.data.detail : "OOPS!SOMETHING WENT WRONG",
                color: "error",
                show: true,
              };
            });
    }
  },
  onClickSiteAccount(site){
    console.log('clicked');
    console.log(site);
    this.$router.push({
      name: 'siteAccountManagement',
      params: {
        site: site,
      },
    });
  },
};
</script>
