<template>
  <div>
    <v-toolbar text color="white">
      <v-toolbar-title>User Management</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-autocomplete style="margin-top: 20px"
          :items="entries"
          item-text="user_email"
          item-value="email"
          placeholder="Enter Email"
          class="mb-2 mr-2"
          @change="filterByEmail($event)"
      ></v-autocomplete>
      <v-dialog persistent v-model="dialog" max-width="1000px">
        <v-card>
          <form @submit.prevent="userCreateForm">
            <v-card-title class="headline">
              <span>{{ userDialoghead }}</span>
            </v-card-title>
            <v-card-text v-if="edituserint == true">
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-card-text color="blue darken-1"
                    ><v-icon> mdi-account</v-icon>ROLE ASSIGNMENT</v-card-text
                  >
                  <v-flex xs12 sm6 md6>
                    <v-select
                      :items="getAllroles"
                      v-model="role"
                      item-text="name"
                      item-value="id"
                      label="Role"
                      placeholder="Role"
                      outlined
                      multiple
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="blue darken-1"
                  v-if="edituserint == true && this.support_user"
                  text
                  @click="updateSupportUser(false)"
              >Remove support user
              </v-btn>
              <v-spacer></v-spacer>
		<v-btn
                color="blue darken-1"
                v-if="edituserint == true"
                text
                @click="userCreateForm()"
                >Update
              </v-btn>
              <v-btn color="blue darken-1" text @click="cancel">Close</v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-spacer></v-spacer>
    <v-card v-if="selected_user" style="margin: 5px; padding:10px">
      <v-card-title class="headline">
        <span>User Profile</span>
      </v-card-title>
      <v-container grid-list-md>
        <v-layout wrap>
          <v-flex xs12 sm6 md6>
            <v-img v-if="selected_user.user_photo"
                   :src="'https://storage.googleapis.com/cybaseal-media-test/' + selected_user.user_photo"
                   aspect-ratio="1.1"
                   contain max-height="250" max-width="250"
                   class="mx-auto"/>
          </v-flex>
          <v-flex xs12 sm6 md6>
            <v-text-field
                v-model="selected_user.first_name"
                item-text="name"
                item-value="id"
                label="First Name"
                placeholder="First Name"
                outlined
                multiple
                disabled
            >
            </v-text-field>

            <v-text-field
                v-model="selected_user.last_name"
                item-text="name"
                item-value="id"
                label="Last Name"
                placeholder="Last name"
                outlined
                multiple
                disabled
            >
            </v-text-field>

            <v-text-field
                v-model="selected_user.iam_sub"
                item-text="name"
                item-value="id"
                label="Auth0 Id"
                placeholder="Auth0 Id"
                outlined
                multiple
                disabled
            >
            </v-text-field>
            <v-btn style="float:right"
                color="blue darken-1"
                text
                @click="resetPassword()"
            >Reset Password
            </v-btn>
          </v-flex>
          <v-flex>
            <div style="display: inline-block; margin-left: 8rem">
            <v-autocomplete style="display: inline-block"
                :items="sitecodes" clearable
                item-text="group_code"
                item-value="groupcode"
                placeholder="Enter Group Code"
                class="mb-2 mr-2"
                @change="selectAccount($event)"
            ></v-autocomplete>
              <v-btn style="display: inline-block"
                     color="blue darken-1"
                     text
                     @click="addSupportUserToAccount()"
              >Add as Support User to Account
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <v-spacer></v-spacer>
    <v-card style="margin-top: 15px"  v-if="accountsforuser.length > 0">
      <v-card-title>User Sites</v-card-title>
    <v-data-table :headers="headers" :items="accountsforuser" class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right" @click="onClickSiteAccount(props.item)">
            {{ props.item.site_name }}
          </td>
          <td class="text-xs-right">
            {{ props.item.account_name  }}
          <td class="px-0">
            <v-icon class="mr-2" style="float:right; padding-right: 30px; padding-left: 30px" @click="edituser(props.item)">
              mdi-account-edit
            </v-icon>
          </td>
        </tr>
        <v-snackbar
          :color="snackbar.color"
          v-model="snackbar.show"
          top
          right
          rounded
        >
          {{ snackbar.message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
    </v-card>
    <v-card style="margin-top: 15px" v-if="supportaccountsforuser.length > 0">
      <v-card-title>Support User Accounts</v-card-title>
      <v-data-table :headers="headers" :items="supportaccountsforuser" class="elevation-1">
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-right" @click="onClickSiteAccount(props.item)">
              {{ props.item.site_name }} <span v-if="props.item.is_support_access"> ( Support User ) </span>
            </td>
            <td class="text-xs-right">
              {{ props.item.account_name }}
            </td>
            <td class="px-0">
              <v-icon class="mr-2" style="float:right; padding-right: 30px; padding-left: 30px" @click="edituser(props.item)">
                mdi-account-edit
              </v-icon>
            </td>
          </tr>
          <v-snackbar
              :color="snackbar.color"
              v-model="snackbar.show"
              top
              right
              rounded
          >
            {{ snackbar.message }}
            <template v-slot:action="{ attrs }">
              <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
          </v-snackbar>
        </template>
        <template v-slot:no-data>
          <span>No data found</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import UserManagementService from "../../services/user-managementservices";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AccountService from "../../services/accountservices";
import router from "@/router";
// minLength
export default {
  mixins: [validationMixin],
  data() {
    return {
      entries:[],
      userlist:[],
      getAllroles:[],
      role: [],
      user_id:"",
      account_id: "",
      name: "",
      userDialoghead: "",
      getData: [],
      siteId: "",
      title: "",
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      phone: "",
      company: "",
      support_user: false,
      edituserint: false,
      currentUser: null,
      selectedAccount: null,
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      dialog: false,
      user: false,
      selected_user: null,
      accountsforuser : [],
      supportaccountsforuser: [],
      sitecodes: [],
      site_list: [],
      headers: [
        { text: "Site Name", align: "left", sortable: true, value: "sitename" },
        { text: "Account ID", align: "left", sortable: true, value: "" },
        { text: "Role Assignment", align: "right", sortable: false, value: "" },
      ],
    };
  },
  validations: {
    role:{ required },
  },
  computed: {
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    if (this.$router.currentRoute.params.user) {
      this.user = router.currentRoute.params.user;
      console.log(this.user);
      this.selected_user = this.user;
      this.getMappedAccountsForUser(this.selected_user.id);
    }
    this.getAllSites();
    this.getUserRoles();
    this.getEmails();
  },
  methods: {
    getEmails(){
      UserManagementService.getEmails().then((response) => {
        this.entries = response.data;
      });
    },
   getUserRoles(){
     UserManagementService.getAllRoles()
        .then((response) => {
          this.getAllroles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
   },
    getSiteUserlists(){
     UserManagementService.getuserlist(this.siteId)
        .then((response) => {
          this.userlist = response.data
        })
        .catch((e) => {
          console.log(e);
        });
   },
    filterByEmail(event){
      UserManagementService.getUserByEmail(event)
        .then((response) => {
          this.selected_user = response.data[0];
          console.log('USER');
          console.log(this.selected_user);
          this.getMappedAccountsForUser(this.selected_user.id);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getMappedAccountsForUser(user_id){
      UserManagementService.getMappedAccountsForUser(user_id)
        .then((response) => {
          console.log('here');
          console.log(response.data);
          this.accountsforuser = response.data.filter(function (el) {
            return el.is_support_access === false;
          });
          this.supportaccountsforuser = response.data.filter(function (el) {
            return el.is_support_access === true;
          });
          console.log('ACCOUNTS');
          console.log(this.accountsforuser);
        }).catch((e) => {
          console.log(e);
        });
    },
    userCreateForm() {
      this.$v.$touch();
      if (this.$v.role.$invalid) {
        this.snackbar = true;
        this.snackbar = {
          message: "Please fill the form correctly",
          color: "error",
          show: true,
        };
      } else {
        var userEditData = {
            "user_id": this.selected_user.id,
            "account_id": this.account_id,
            "roles": this.role
        };
        UserManagementService.saveUser(userEditData)
          .then((response) => {
            if (response) {
              console.log(response);
              this.snackbar = {
                message: "User role updated succesfully",
                color: "success",
                show: true,
              };
            }

          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      }
    },
    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
    },
    edituser(account) {
      UserManagementService.getRoleForUser(this.selected_user.id,account.id)
        .then((response) => {
          this.role = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
      this.clear();
      console.log(account)
      this.edituserint = true;
      this.userDialoghead = "Account";
      this.account_id = account.id;
      this.support_user = account.is_support_access;

      this.dialog = true;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    cancel() {
      this.clear();
      this.dialog = false;
      this.user = false;
    },
    clear() {
      this.$v.$reset();
      this.first_name = "";
      this.last_name = "";
      this.username = "";
      this.title = "";
      this.role = [];
      this.email = "";
      this.phone = "";
      this.user_id = "";
      this.account_id = ""; 
    },
    assign() {
      this.user = false;
    },
    resetPassword() {
      var data = {
        Email: this.selected_user.email
            };
      AccountService.resetAuth0Password(data)
          .then((response) => {
            console.log(response);
            this.snackbar = {
              message: "Password reset email sent to user",
              color: "success",
              show: true,
            };
          })
          .catch((e) => {
            console.log(e);
            if (e.request.status === 400) {
              this.snackbar = {
                message: e,
                color: "error",
                show: true,
              };
            } else {
              this.snackbar = {
                message: e,
                color: "error",
                show: true,
              };
            }
          });
    },
    saveUserDetails() {
      var userEditData = {
        "user_id": this.user_id,
        "account_id": this.account_id,
        "first_name": this.first_name,
        "last_name" : this.last_name,
        "email" : this.email,
        "phone" : this.phone
      };
      UserManagementService.updateUserDetails(this.user_id, userEditData)
          .then((response) => {
            console.log(response);
            this.snackbar.show = true;
            this.snackbar.message = "User saved successfully";
            this.snackbar.color = "success";
            this.getSiteUserlists();
            this.dialog = false;
          })
          .catch((e) => {
            console.log(e);
          });
      this.clear();
    },
   onClickSiteAccount(site){
    this.$router.push({
      name: 'siteAccountManagement',
      params: {
        site: site,
      },
    });
  },
    updateSupportUser(set) {
      console.log(this.support_user);
      var supportAccessUpdate = {
        "is_support_access": set,
      };
      UserManagementService.updateSupportUser(this.selected_user.id, this.account_id,supportAccessUpdate)
          .then((response) => {
            console.log(response);
            this.snackbar.show = true;
            this.snackbar.message = "Support User updated successfully";
            this.snackbar.color = "success";
            this.dialog = false;
            this.getMappedAccountsForUser(this.selected_user.id);
          })
          .catch((e) => {
            console.log(e);
          });
    },
    getAllSites() {
      AccountService.getAllSites()
          .then((response) => {
            if (response) {
              console.log(response);
              this.sitecodes = response.data.map(x => x.group_code + x.site_code + ' (' + x.site_name + ')');
              this.site_list = response.data;
            }
          })
          .catch((e) => {
            console.log(e);
          });
    },
    addSupportUserToAccount() {
      var supportAccessUpdate = {
        "is_support_access": true,
      };
      var defaultRoleUpdate = {
        "role_id": "1b88647b-cfdd-4f6e-b0e0-284d34e0d2fa",
      };
      console.log(this.selectedAccount);
      UserManagementService.addSupportUserToAccount(this.selected_user.id, this.selectedAccount.id,supportAccessUpdate)
          .then((response) => {
            console.log(response);
            UserManagementService.addDefaultRoleToAccount(this.selected_user.id, this.selectedAccount.id,defaultRoleUpdate)
                .then((response) => {
                  console.log(response);
                  this.snackbar.show = true;
                  this.snackbar.message = "Support User added to account successfully";
                  this.snackbar.color = "success";
                  this.dialog = false;
                  this.getMappedAccountsForUser(this.selected_user.id);
                })
                .catch((e) => {
                  console.log(e);
                });
          })
          .catch((e) => {
            console.log(e);
            this.snackbar.show = true;
            this.snackbar.message = "User already has support access to this account";
            this.snackbar.color = "red";
          });
    },
    selectAccount(account) {
      this.selectedAccount = this.site_list.find((item) => item.group_code + item.site_code === account.split(' ')[0]);
      console.log(this.selectedAccount);
    },
  },
};
</script>
