<template>
  <div>
    <v-toolbar text color="white">
      <v-toolbar-title>Users</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="1000px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on"
            >Add New User</v-btn
          >
        </template>
        <v-card>
          <v-card-title class="headline">
            <span>{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-form @submit.prevent="handleSubmit">
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="userName"
                      :error-messages="emailErrors"
                      label="User Id"
                      placeholder="Enter Id"
                      required
                      outlined
                      @input="$v.userid.$touch()"
                      @blur="$v.userid.$touch()"
                    />
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="editedItem.nickname"
                      label="Mail Nick Name"
                      placeholder="Mail Nick Name"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="editedItem.company"
                      label="Company Name"
                      placeholder="Company Name"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="editedItem.site"
                      label="Site Name"
                      placeholder="Site Name"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                      placeholder="Email"
                      required
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="editedItem.password"
                      :type="showPassword ? 'text' : 'password'"
                      label="Password"
                      placeholder="Enter Password"
                      required
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    />
                  </v-flex>
                </v-layout>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="createUSer">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="role" max-width="500px">
        <template v-slot:activator="{ on }">
          <v-icon color="primary" dark class="mb-2" v-on="on"></v-icon>
        </template>
        <v-card>
          <v-card-title class="headline">
            <span>Assign Roles</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.upc"
                    label="User Email"
                    placeholder="User Email"
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12>
                  <v-text-field
                    v-model="editedItem.BatchCode"
                    label="Role"
                    placeholder="User Email"
                  >
                  </v-text-field>
                  <v-select :items="roles" label="Standard"></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="assign">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table :headers="headers" :items="users" class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right">{{ props.item.displayName }}</td>
          <td class="text-xs-right">{{ props.item.surname }}</td>
          <td class="text-xs-right">{{ props.item.mail }}</td>
          <td class="text-xs-right">{{ props.item.companyName }}</td>
          <td class="text-xs-right">{{ props.item.site }}</td>
          <td class="text-xs-right">{{ props.item.roles }}</td>
          <td class="px-0">
            <v-icon class="mr-2" @click="editUser(props.item)">
              mdi-account-edit
            </v-icon>
            <v-icon @click="deleteItem(props.item)"> mdi-account-key </v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">No data</v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import UserManagementService from "../services/user-managementservices";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  data: () => ({
    dialog: false,
    role: false,
    showPassword: false,
    submitStatus: null,
    users: [],
    roles: ["Manager", "Senior Manager", "Accounts Manager"],
    headers: [
      {
        text: "User Name",
        align: "left",
        sortable: true,
        value: "displayName",
      },
      { text: "Mail Nick Name", sortable: true, value: "surname" },
      { text: "Email", sortable: true, value: "mail" },
      { text: "Company", sortable: true, value: "companyName" },
      { text: "Site", sortable: true, value: "site" },
      { text: "Roles", sortable: true, value: "roles" },
      { text: "Actions", value: "name", sortable: false },
    ],
    data() {
      return {
        userName: "",
        status: null,
        showPassword: false,
        users: "",
        snackbar: {
          show: false,
          message: null,
          color: null,
        },
      };
    },
    traceList: [],
    editedIndex: -1,
    editedItem: {
      username: "",
      nickname: "",
      email: "",
      company: "",
      site: "",
      password: "",
    },
    defaultItem: {
      username: "",
      nickname: "",
      email: "",
      company: "",
      site: "",
      password: "",
    },
    user: {
      userName: "",
    },
    submitted: false,
    validations: {
      user: {
        userName: { required },
        lastName: { required },
        email: { required, email },
        password: { required, minLength: minLength(6) },
        confirmPassword: { required, sameAsPassword: sameAs("password") },
      },
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add New User" : "Edit User";
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.userName.$dirty) return errors;
      !this.$v.userName.required && errors.push("E-mail is required");
      return errors;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      UserManagementService.getUsers()
        .then((response) => {
          this.users = response.data.results;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.user));
    },
    /*dummy data ENDS */

    editUser(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      const index = this.traceList.indexOf(item);
      this.traceList.splice(index, 1);
      this.role = true;
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }
      this.dialog = false;
    },
    cancel() {
      this.role = false;
    },
    assign() {
      this.role = false;
    },
  },
};
</script>
