<template lang="html">
  <div>
     <v-toolbar text color="white">
      <v-toolbar-title>Site Accounts</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
       <v-btn @click="sendInvitation" rounded >Back</v-btn>
    </v-toolbar>
      <br>
    <v-data-table :headers="headers" :items="getData" class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right" >{{ props.item.name }}</td>
          <td class="text-xs-right">{{ props.item.status }}</td>
          <td class="text-xs-right">{{ props.item.email_id }}</td>
          <td class="text-xs-right">{{ props.item.seals }}</td>
          <td class="text-xs-right">{{ props.item.users }}</td>
          <td class="text-xs-right">{{ props.item.traces }}</td>
          <!-- <td class="text-xs-right">
            {{ props.item.tariff }}
            <v-btn  color="primary" @click="showChangeTariff(props.item.account_id, props.item.tariff_id)">Change</v-btn>
          </td> -->
          <!-- <td class="px-0">
            <v-icon class="mr-2" @click="editrole(props.item.id)">
              mdi-account-edit
            </v-icon>
            <v-icon class="mr-2" @click="deleterole(props.item.id)">
              mdi-delete
            </v-icon>
          </td> -->
        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>

    <!-- <v-dialog v-model="changeTariff" max-width="500px" persistent>
        <v-card>
          <v-card-title class="headline">
            <span>Change Group Tariff</span>
          </v-card-title>
          <form @submit.prevent="resend">

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-select
                    :items="tariffList"
                    v-model="tariffId"
                    :error-messages="tariffIdErrors"
                    item-text="name"
                    item-value="id"
                    label="Tariff"
                    placeholder="Tariff is required"
                    required
                    outlined
                    @input="$v.tariffId.$touch()"
                    @blur="$v.tariffId.$touch()"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click = "cancelTariff" >Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="changeGroupTariff">Change</v-btn>
          </v-card-actions>
          </form>
        </v-card>
      </v-dialog> -->

  </div>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import AccountService from "../services/accountservices";
  import { required} from "vuelidate/lib/validators";
  import router from "../router";

    export default {
      name: 'AccountDetails',
      mixins: [validationMixin],
      data () {
        return {
            urlId:"",
            name: "",
            getData:[],
            tariffId: "",
            tariffList: [],
            changeTariff: false,
            accountId: "",
            headers: [
                { text: "Sites", align: "left", sortable: true, value: "type" },
                { text: "Status", align: "left", sortable: true, value: "type" },
                {
                text: "Primary Contacts",
                align: "left",
                sortable: false,
                value: "description",
                },
                { text: "Seals", align: "left", sortable: true, value: "type" },
                { text: "Users", align: "left", sortable: true, value: "type" },
                { text: "Traces", align: "left", sortable: true, value: "type" },
                // { text: "Tariff", align: "left", sortable: true, value: "type" },
            ],
        }
      },
      validations: {
        emailaddress: { required },
        tariffId: { required },
      },
      computed: {
        tariffIdErrors() {
          const errors = [];
          if (!this.$v.tariffId.$dirty) return errors;
          !this.$v.tariffId.required && errors.push("Tariff required");
          return errors;
        },
      },
      created() {
        this.urlId = this.$route.params.id;
        this.siteValues(this.urlId);
      },

      methods: {
        sendInvitation() {
        router.push("/group");
        },


      siteValues(accountid){
      console.log("id",accountid);
      AccountService.getSiteDatas(accountid).then(response => {
        if (response){
          this.getData = response.data;
          console.log("response",response.data);
        }
      })
      .catch(e => {
        console.log(e);

      });
      },

        // showChangeTariff(account_id, tariff_id) {
        //   this.changeTariff = true
        //   this.accountId = account_id
        //   this.tariffId = tariff_id
        //   this.getTariff();
        // },

        // cancelTariff(){
        //   this.changeTariff = false;
        //   this.required = false;
        // },
        // changeGroupTariff() {
        //   this.required = true
        //   this.accountId = ""
        //   var data = {
        //     account_id: this.accountId,
        //     tariff_id: this.tariffId,
        //   };
        //   AccountService.changeTariff(data).then(response => {
        //     if (response){
        //       console.log(response)
        //     }
        //   })
        //   .catch(e => {
        //     console.log(e);

        //   });
        // },
        // getTariff() {
        //   AccountService.getTariff()
        //     .then((response) => {
        //       if (response) {
        //         this.tariffList = response.data;
        //       }
        //     })
        //     .catch((e) => {
        //       console.log(e);
        //       this.snackbar = {
        //         message: "OOPS!SOMETHING WENT WRONG",
        //         color: "error",
        //         show: true,
        //       };
        //     });
        // },
    
      }
    }
</script>