<template>
  <div>
    <v-toolbar text color="white">
      <v-toolbar-title>Request Seal</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <v-dialog persistent v-model="dialog" max-width="1000px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on" @click="addRequest"
            >+ Request Seal</v-btn
          >
        </template>
        <v-card>
          <form @submit.prevent="requestSealForm">
            <v-card-title class="headline">{{ sealTitle }} </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      :items="getGroupData"
                      :error-messages="groupCodeErrors"
                      v-model="groupCode"
                      item-text="group_code"
                      item-value="id"
                      label="Group Code"
                      placeholder="Select Group Code"
                      @change="changeAccountType($event)"
                      required
                      outlined
                      @input="$v.groupCode.$touch()"
                      @blur="$v.groupCode.$touch()"
                    >
                    </v-select>
                  </v-flex>
                  <v-select
                    :items="getSiteData"
                    :error-messages="siteCodeErrors"
                    v-model="siteCode"
                    item-text="site_code"
                    item-value="id"
                    label="Site Code"
                    placeholder="Select Site Code"
                    @change="changeSiteType($event)"
                    required
                    outlined
                    @input="$v.siteCode.$touch()"
                    @blur="$v.siteCode.$touch()"
                  >
                  </v-select>

                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="noOfSeals"
                      :error-messages="noOfSealsErrors"
                      label="Number Of Seals"
                      placeholder="Enter Number Of Seals"
                      required
                      outlined
                      @input="$v.noOfSeals.$touch()"
                      @blur="$v.noOfSeals.$touch()"
                    />
                  </v-flex>
                  <v-menu
                    v-model="fromDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    lazy
                    transition="scale-transition"
                    offset-y
                    full-width
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        label="Request Date"
                        :error-messages="requestingDateErrors"
                        readonly
                        :value="fromDateDisp"
                        v-on="on"
                        placeholder="Enter Date"
                        outlined
                        @input="$v.requestingDate.$touch()"
                        @blur="$v.requestingDate.$touch()"
                      >
                        ></v-text-field
                      >
                    </template>
                    <v-date-picker
                      locale="en-in"
                      :min="minDate"
                      v-model="requestingDate"
                      no-title
                      @input="fromDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
              <v-btn color="blue darken-1"  v-if="addSeal == true" @click="requestSealForm()" text
                >Save</v-btn
              >
              <v-btn
                color="blue darken-1"
                text
                v-if="editSeal == true"
                @click="requestSealForm()"
                >Update</v-btn
              >
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table :headers="headers" :items="getSeals" class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right">{{ props.item.noOfSeals }}</td>
          <td class="text-xs-right">{{ props.item.requestingDate }}</td>
          <td class="px-0">
            <v-icon class="mr-2" @click="editRequestSeal(props.item)"> mdi-pencil-box</v-icon>
            <v-icon class="mr-2" @click="deleteRequestSeal(props.item.id)">
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" :color="color" :top="top" :right="right">
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import RequestSealService from "../services/request-sealservice";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import moment from "moment";

export default {
  mixins: [validationMixin],

  name: "RequestSeal",
  data() {
    return {
      noOfSeals: "",
      requestingDate: "",
      getSeals: [],
      sealTitle: "",
      on: "",
      siteCode: "",
      groupCode: "",
      dialog: false,
      getGroupData: [],
      getGroupDataList: [],
      groupCodeName: "",
      getSiteData: [],
    //  accountInfo: "",
    //  accountId: "",
      fromDateMenu: false,
      minDate: moment(new Date()).format("YYYY-MM-DD"),
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      snackbarText: "",
      color: "",
      top: "",
      right: "",
      addSeal: false,
      editSeal: false,
      

      headers: [
        {
          text: "No.Of Seals",
          align: "left",
          sortable: true,
          value: "noOfSeals",
        },
        {
          text: "Request Date",
          align: "left",
          sortable: true,
          value: "requestingDate",
        },
      ],
    };
  },
  validations: {
    groupCode: { required },
    siteCode: { required },
    noOfSeals: { required },
    requestingDate: { required },
  },
  computed: {
    groupCodeErrors() {
      const errors = [];
      if (!this.$v.groupCode.$dirty) return errors;
      !this.$v.groupCode.required && errors.push("Group Code is required");
      return errors;
    },
    siteCodeErrors() {
      const errors = [];
      if (!this.$v.siteCode.$dirty) return errors;
      !this.$v.siteCode.required && errors.push("Site Code is required");
      return errors;
    },
    noOfSealsErrors() {
      const errors = [];
      if (!this.$v.noOfSeals.$dirty) return errors;
      !this.$v.noOfSeals.required && errors.push("Number Of Seals is required");
      return errors;
    },
    requestingDateErrors() {
      const errors = [];
      if (!this.$v.requestingDate.$dirty) return errors;
      !this.$v.requestingDate.required &&
        errors.push("Request Date is required");
      return errors;
    },
    fromDateDisp() {
      return this.requestingDate;
    },
  },

  created() {
    this.getRequestSeals();
    this.getGroupCodes();
    console.log(moment(new Date()).format("YYYY-MM-DD"));
    this.snackbar = false;
    this.snackbarText = false;
  },

  methods: {
    getRequestSeals() {
      RequestSealService.getAllRequestSeals()
        .then((response) => {
          this.getSeals = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getGroupCodes() {
      RequestSealService.getGroups()
        .then((response) => {
          this.getGroupData = response.data;
          this.getGroupDataList = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeAccountType(event) {
      this.accountType = event;
      this.groupCodeName = this.getGroupDataList.filter(
        (x) => x.id == event
      )[0].group_code;
      this.getSiteCodes();
    },
    getSiteCodes() {
      RequestSealService.getSites(this.groupCodeName)
        .then((response) => {
          this.getSiteData = response.data;
          this.getSiteDataList = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeSiteType(event) {
      this.siteId = event;
    },
    addRequest() {
      this.sealTitle = "Add Request Seal";
       this.addSeal = true;
       this.editSeal = false;
      this.dialog = true;
      this.required = true;
    },
    cancel() {
      this.dialog = false;
      this.clear();
    },
    clear() {
      this.$v.$reset();
      this.groupCode = "";
      this.siteCode = "";
      this.noOfSeals = "";
      this.requestingDate = "";
    },
    requestSealForm() {
      if(this.addSeal == true)
      {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.snackbar = true;
        this.snackbarText = "Please fill the form correctly";
        this.color = "error";
        this.right = true;
        this.top = true;
      } else {
        var requestSealData = {
          accountId: this.accountType,
          siteAccountId: this.siteId,
          noOfSeals: this.noOfSeals,
          requestingDate: this.requestingDate,
        };
       
        RequestSealService.createSeal(requestSealData)
          .then((response) => {
            if (response != null) {
              this.getRequestSeals();
              this.dialog = false;
              this.snackbar = true;
              this.snackbarText = "Created Request Seal succesfully";
              this.color = "success";
              this.top = true;
              this.right = true;
            }
            this.submitted = true;
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbarText = "OOPS!SOMETHING WENT WRONG";
            this.color = "error";
            this.top = true;
            this.right = true;
          });

        this.clear();
      }
}
 else if(this.editSeal == true){
this.$v.$touch();
      if (this.$v.$invalid) {
        this.snackbar = true;
        this.snackbarText = "Please fill the form correctly";
        this.color = "error";
        this.right = true;
        this.top = true;
      } else {
        var requestEditData = {
          id: this.Id,
          accountId: this.groupCode,
          siteAccountId:  this.siteCode,
          noOfSeals: this.noOfSeals,
          requestingDate: this.requestingDate,
        };
       
        RequestSealService.updateSeal(requestEditData)
          .then((response) => {
            if (response != null) {
              this.getRequestSeals();
              this.dialog = false;
              this.snackbar = true;
              this.snackbarText = "Updated Request Seal succesfully";
              this.color = "success";
              this.top = true;
              this.right = true;
            }
            this.submitted = true;
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbarText = "OOPS!SOMETHING WENT WRONG";
            this.color = "error";
            this.top = true;
            this.right = true;
          });

        this.clear();
      }
 }
    },
    deleteRequestSeal(id) {
      if (confirm("Do you really want to delete?")) {
        RequestSealService.deleteSeal(id)
          .then((response) => {
            if (response) {
              this.snackbar = true;
              this.snackbarText = "Request Seal deleted succesfully";
              this.color = "success";
              this.top = true;
              this.right = true;
            }
            this.getRequestSeals();
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbarText = "OOPS!SOMETHING WENT WRONG";
            this.color = "error";
            this.top = true;
            this.right = true;
          });
      }
    },
    editRequestSeal(item){
      this.editSeal = true;
      this.addSeal = false;
      this.sealTitle ="Update Request Seal";
      this.dialog= true;
      this.Id = item.id;
      this.groupCode = item.accountId;
      this.groupCodeName =  this.getGroupDataList.filter(
        (x) => x.id == item.accountId)[0].group_code;
      this.getSiteCodes();
      this.siteCode =item.siteAccountId;
      this.noOfSeals = item.noOfSeals;
      this.requestingDate = item.requestingDate;
     
 },
  },
};
</script>



