// import http from '../store/http-common';
import httpInstance from '../store/interceptor';

class RolePermissionManagementService {
    saveRolePermission(data) {
        return httpInstance.put('/v1/accounts/rolepermission', data);
    }
    getRolePermissionById(id) {
        return httpInstance.get('/v1/accounts/rolepermission/' + id);
    }
}
export default new RolePermissionManagementService();