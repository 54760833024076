<template lang="html">

  <section class="module-permissons">
    <v-flex xs12 sm6 md6>
    <v-select :items="filteredRoles" v-model="selectedRoleId"  item-value="id" v-on:change ="onChangeRole()" item-text="name"  label="Roles"
                           placeholder="Select Role Name" outlined> 
    </v-select>
    </v-flex>

     <v-data-table :headers="headers" :items="filteredPermissions" class="elevation-1" :hide-default-footer="true" disable-pagination>
            <template v-slot:item="props">
                <tr>
                    <td class="text-xs-right">{{ props.item.name }}</td>
                    <td class="text-xs-right">
                      <input type="checkbox" v-if = "props.item.check == 1" checked @change="onPermissionSelectionChange($event,props.item)">
                      <input type="checkbox" v-else  @change="onPermissionSelectionChange($event,props.item)" >
                    </td>                  
                </tr>
            </template>
        </v-data-table>
        <br/>
        <v-btn color="primary" dark class="mb-2" style="float: right;"  @click="saveRolePermission()">Save</v-btn>
  </section>
  

</template>

<script>
import RoleManagementService from "../../services/role-managementservices";
import PermissionManagementService from "../../services/permissionservice";
import RolePermissionManagementService from "../../services/rolepermissionservice";
import router from "../../router";
export default {
  name: "modulePermissons",
  props: [],
  mounted() {},
  data() {
    return {
      filteredRoles: [],
      checkbox: true,
      selectedRoleId: "",
      permissions: "",
      filteredPermissions: [],
      filterChecked: [],
      headers: [
        { text: "Name", align: "left", value: "name" },
        { text: "Permissions", align: "left", value: "permissions" },
      ],
    };
  },
  methods: {
    getAllRoles() {
      RoleManagementService.getAllRole()
        .then((response) => {
          if (response) {
            this.filteredRoles = response.data;
            this.filteredRoles.sort((a, b) => (a.name > b.name ? 1 : -1));
            this.getRolePermission();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadPermissions() {
      PermissionManagementService.getAllPermission()
        .then((response) => {
          if (response) {
            this.displayPermissions = true;
            this.permissions = response.data;
            for (var i = 0; i < this.permissions.length; i++) {
              this.filteredPermissions.push(this.permissions[i]);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onChangeRole() {
      this.getRolePermission();
    },
    onPermissionSelectionChange(event, values) {
      id = "";
      var id = values.id;
      if (event.target.checked == true) {
        this.filterChecked.push(values);
      } else if (event.target.checked == false) {
        this.filterChecked = this.filterChecked.filter(
          (value) => value.id !== id
        );
      }
    },
    getRolePermission() {
      let rolePermissionsIds = [];
      this.roleId = this.selectedRoleId;
      const selectedRole = this.filteredRoles.find(role => role.id === this.roleId);
      RolePermissionManagementService.getRolePermissionById(this.roleId)
        .then((response) => {
          if (response) {
            rolePermissionsIds = response.data.permissionIds || [];
            var result = [];
            for (var j = 0; j < this.permissions.length; j++) {
              if (selectedRole.type.some(type => this.permissions[j].type.includes(type))) {
                this.permissions[j].check = rolePermissionsIds.includes(this.permissions[j].id) ? 1 : 0;
                result.push(this.permissions[j]);
              }
            }
            this.filteredPermissions = result;
            this.filterChecked = this.filteredPermissions.filter(
              (checked) => checked.check == 1
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    saveRolePermission() {
      this.filterChecked.forEach(function (v) {
        delete v.check,
          delete v.description,
          delete v.name,
          delete v.status,
          delete v.type;
      });

      var rolePermission = {
        roleId: this.roleId,
        permissionIds: []
      };
      for (var i = 0; i < this.filterChecked.length; i++) {
        rolePermission.permissionIds.push(this.filterChecked[i].id);
      }
      RolePermissionManagementService.saveRolePermission(rolePermission)
        .then(() => {
          router.push("/role").catch(() => {});
        })
        .catch((e) => {
          console.log(e);
          this.snackbar = {
            message: "OOPS!SOMETHING WENT WRONG",
            color: "error",
            show: true,
          };
        });
    },
  },
  computed: {},
  created() {
    this.getAllRoles(), this.loadPermissions();
    this.selectedRoleId = this.$route.query.roleId;
  },
};
</script>

<style scoped lang="scss">
.module-permissons {
}
</style>
