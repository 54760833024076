<template lang="html">
  <div>
     <v-toolbar text color="white">
      <v-toolbar-title>3 DIGIT PROCEDURE CODES ({{getData.length}})</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn @click="backToInvite" rounded color="primary">Back</v-btn>
    </v-toolbar>
    <div>
        <v-card class="mt-5 mb-5">
        <v-card-text class="resize">
            <v-container>
            <v-row>
            <v-col class="p-0 justify-content-center" cols="12">
                    <h5>{{updatedDetailsForScreen}}</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12" sm="12" lg="8" class="pt-0">
                <UploadDocuments @sendResponse="ReceiveResponse"/> 
                </v-col>
                <v-col cols="12" sm="12" lg="4" class="pt-0">
                <v-text-field v-model="search" label="Search" class="mt-3"
                    append-icon="mdi-magnify" single-line hide-details ></v-text-field>
                </v-col>
            </v-row>
            </v-container>
        </v-card-text>
        </v-card>
    </div>
    <v-data-table :headers="headers" :items="getData" 
        :sort-by="sortBy" :sort-desc="sortDesc" :search="search" class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right" >{{ props.item.code }}</td>
          <td class="text-xs-right">{{ props.item.description }}</td>
        </tr>
      </template>
<template v-slot:no-data>
  <span>No data found</span>
</template>
</v-data-table>
<template>
    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.show"
      top
      right
      rounded
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
 </template>
</div>
</template>

<script>
import MasterScreenService from "../../services/master-screenservices";
import router from "../../router/index";
import UploadDocuments from '../uploaddocuments.vue';

export default {
  name: "AdditionalProcedureCode",
  components: {
    UploadDocuments
  },
  data() {
    return {
      search: "",
      pdatedDetailsForScreen: '',
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      sortDesc: false,
      sortBy: "code",
      getData: [],
      headers: [
        {
          text: "Code",
          align: "left",
          sortable: true,
          value: "code",
        },
        {
          text: "Description of Procedure",
          align: "left",
          sortable: true,
          value: "description",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.updatedDetailsForScreen = sessionStorage.getItem("updatedDetailsForScreen");
    this.getAdditionalProcedureCode();
  },

  methods: {
    getAdditionalProcedureCode(){
      MasterScreenService.getAdditionalProcedureCode()
      .then((response) => {
        this.getData = response.data;
      })
      .catch((e) => {
        console.log(e);
      });
    },
    ReceiveResponse(res){
        if(res == undefined){
            this.snackbar = true;
            this.snackbar = {
            message: "Please select a file to upload",
            color: "red",
            show: true,
            };
        }
        else if(res == false){
            this.snackbar = true;
            this.snackbar = {
            message: "Something went wrong! please try again",
            color: "red",
            show: true,
            };
        }
        else if(res == true){
            this.snackbar = true;
            this.snackbar = {
            message: "File uploaded successfully!",
            color: "green",
            show: true,
            };
          this.updatedDetailsForScreen = sessionStorage.getItem("updatedDetailsForScreen");          
          this.getAdditionalProcedureCode();
        }
        else if(res == 'completed'){
            this.snackbar = true;
            this.snackbar = {
            message: "File downloaded successfully!",
            color: "green",
            show: true,
            };
        }
        else if(res == 'nofile'){
            this.snackbar = true;
            this.snackbar = {
            message: "No file exists! please upload a new file first!!!",
            color: "orange",
            show: true,
            };
        }
        else if(res == 'discard'){
            this.snackbar = true;
            this.snackbar = {
            message: "File changes discarded successfully!",
            color: "orange",
            show: true,
            };
        }
    },
    backToInvite() {
      router.push("/master-screen/cds-code-lists");
    },
    closeSnackbar(){
      this.snackbar = {
        show: false,
        };
    },
  },
};
</script>

<style>
.justify-content-end {
  justify-content: Flex-end;
}
.resize {
  padding: 0px !important;
}
.justify-content-center {
  display: flex;
  justify-content: center;
}
</style>