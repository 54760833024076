<template>
    <div>
        <v-toolbar text color="white" class="mb-5">
            <v-toolbar-title>Select the Period</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-layout wrap>
                <v-flex xs12 sm6 md6 pr-2>
                    <!-- <v-select
                :items="getGroupData"
                :error-messages="groupCodeErrors"
                v-model="groupCode"
                item-text="group_code"
                item-value="id"
                label="Group Code"
                placeholder="Select Group Code"
                @change="changeAccountType($event)"
                required
                outlined
                @input="$v.groupCode.$touch()"
                @blur="$v.groupCode.$touch()"
               class="height-custom">
              </v-select> -->
                    <v-select :items="months" item-text="value" item-value="id" v-model="selected"
                     @change="setMonth($event)" label="Select Month" 
                         solo>
                    </v-select>
                </v-flex>
                <v-flex xs12 sm6 md6 pr-2>
                    <v-select :items="years" label="Select Year" v-model="selected2" solo @change="setYear($event)">
                    </v-select>
                </v-flex>

            </v-layout>
            <v-btn color="primary" dark class="mb-2 btn-import" v-on="on" @click="importExchange" v-bind:class="{ active: isActive}">Import Exchange Rates
            </v-btn>

        </v-toolbar>
        <v-toolbar text color="white">
            <v-toolbar-title>Exchange Rates (<span>{{length}}</span>)</v-toolbar-title>
            <v-toolbar-title class="mx-5">Period :<span>{{Period}}</span> </v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-flex md4 pr-2>
                <v-text-field v-model="search" label="Search"
                append-icon="mdi-magnify" single-line hide-details ></v-text-field>
            </v-flex>

        </v-toolbar>
        <v-data-table :headers="headers" :items="Exchange" :search="search" class="elevation-1">
            <template v-slot:item="props">
                <tr>
                    <td class="text-xs-right">{{ props.item.countryCode }}</td>
                    <td class="text-xs-right">{{ props.item.countryName }}</td>
                    <td class="text-xs-right">{{ props.item.currencyCode }}</td>
                    <td class="text-xs-right">{{ props.item.currencyName }}</td>
                    <td class="text-xs-right">{{ props.item.exchangeRate }}</td>

                </tr>
            </template>
<template v-slot:no-data>
                <span>No data found</span>
            </template>
</v-data-table>
<!-- <v-snackbar v-model="snackbar" :color="color" :top="top" :right="right">
    
</v-snackbar> -->
<v-snackbar :color="snackbar.color" v-model="snackbar.show" top right rounded>
    {{ snackbar.message }}
    <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
</v-snackbar>

</div>
</template>

<script>
    import MasterScreenService from "../../services/master-screenservices"
    // import {
    //     required
    // } from "vuelidate/lib/validators";
    import {
        validationMixin
    } from "vuelidate";
    import moment from "moment";

    export default {
        mixins: [validationMixin],
        name: "ExchangeRates",
        data() {
            return {

                buttonDisable: false,
                fromDateMenu: false,
                year: "",
                monthIndex: "",
                selected: "",
                selected2: "",
                monthDetails: "",
                isActive: false,
                hasError: false,
                search: '',
                activeClass: 'active',
                minDate: moment(new Date()).format("YYYY-MM-DD"),
                snackbar: {
                    show: false,
                    message: null,
                    color: null,
                },
                // isInvalidValidDuration: boolean = false,

                headers: [{
                    text: "Country Code",
                    align: "left",
                    sortable: true,
                    value: "countryCode",
                }, {
                    text: "Country Name",
                    align: "left",
                    sortable: true,
                    value: "countryName",
                }, {
                    text: "Currency Code",
                    align: "left",
                    sortable: true,
                    value: "currencyCode",
                }, {
                    text: "Currency Name",
                    align: "left",
                    sortable: true,
                    value: "currencyName",
                }, {
                    text: "Exchange Rate",
                    align: "left",
                    sortable: true,
                    value: "exchangeRate",
                }],


                months: [{
                    value: "January",
                    id: "01"
                }, {
                    value: "February",
                    id: "02"
                }, {
                    value: "March",
                    id: "03"
                }, {
                    value: "April",
                    id: "04"
                }, {
                    value: "May",
                    id: "05"
                }, {
                    value: "June",
                    id: "06"
                }, {
                    value: "July",
                    id: "07"
                }, {
                    value: "August",
                    id: "08"
                }, {
                    value: "September",
                    id: "09"
                }, {
                    value: "October",
                    id: "10"
                }, {
                    value: "November",
                    id: "11"
                }, {
                    value: "December",
                    id: "12"
                }, ],
                Exchange: [],

            };
        },
        validations: {

        },
        computed: {

            fromDateDisp() {
                return this.requestingDate;
            },
            years() {
                const year = new Date().getFullYear()
                return Array.from({
                    length: year - 2015
                }, (value, index) => 2015 + (index + 1));
            },
        },


        created() {
            this.getCurrentMonth();
            this.getCurrentYear();
            this.month = this.getCurrentMonth();
            this.year = this.getCurrentYear();
            for (var i = 0; i < this.months.length; i++) {
                if (this.month == this.months[i].id) {
                    this.selected = this.months[i].id;
                }
            }
            for (var j = 0; j < this.years.length; j++) {
                if (this.year == this.years[j]) {
                    this.selected2 = this.years[j];
                }
            }
            MasterScreenService.getImportExchangeRates(this.month, this.year).then(response => {
                    this.Exchange = response.data.exchangeRate.exchangeRates;
                    this.Period = response.data.exchangeRate.period;
                    this.length = this.Exchange.length;
                },
                // error => {
                //     this.toastr.error('There was a problem fetching Exchange Rate. Please try again.');
                // }
            );

            this.snackbar = false;
            this.snackbarSample = false;
        },

        methods: {
            importExchange() {
                this.month = this.monthIndex;
                MasterScreenService.getImportExchangeRates(this.month, this.year).then(response => {
                        this.snackbar = true;
                        this.snackbar = {
                                message: "Successfully Fetched Exchange rates",
                                color: "green",
                                show: true,
                            },
                            this.Exchange = response.data.exchangeRate.exchangeRates;
                        this.Period = response.data.exchangeRate.period;
                        this.length = this.Exchange.length;

                    })
                    .catch(e => {
                        console.log(e);
                    });

                this.snackbar = false;
            },
            closeSnackbar() {
                this.snackbar = {
                    show: false,
                };
            },
            setMonth(i) {
                this.monthIndex = i;
                if ((Number(this.year) < new Date().getFullYear())) {
                    this.snackbar = false;
                    this.isActive = false;
                    return;
                } else {
                    if (Number(i) > new Date().getMonth() + 1) {
                        this.isActive = true;
                        this.snackbar = {
                            message: "Exchange Rates Information for Selected Month and Year is not available!",
                            color: "error",
                            show: true,
                        }
                        return;
                    } else {
                        this.snackbar = false;
                        this.isActive = false;
                        this.buttonDisable = false;
                    }
                }

            },
            setYear(val) {
                this.year = val;
                if ((Number(this.year) < new Date().getFullYear())) {
                    this.snackbar = false;
                    this.isActive = false;
                    return;
                } else {
                    if (Number(this.monthIndex) > new Date().getMonth() + 1) {
                        this.isActive = true;
                        this.snackbar = {
                            message: "Exchange Rates Information for Selected Month and Year is not available!",
                            color: "error",
                            show: true,
                        }
                        return;
                    } else {
                        this.snackbar = false;
                        this.isActive = false;
                        this.buttonDisable = false;
                    }
                }
            },
            getCurrentMonth() {
                var currentDate = new Date();
                var currentMonth = "";
                var month = Number(currentDate.getMonth()) + 1;
                if (month < 10) {
                    currentMonth = "0" + month;
                } else {
                    currentMonth = month.toString();
                }
                return currentMonth;
            },

            getCurrentYear() {
                var currentDate = new Date();
                return (currentDate.getFullYear()).toString();
            }
        },
    };
</script>

<style>
    .v-text-field.v-text-field--solo .v-input__control {
        min-height: 40px !important;
        justify-content: CENTER;
        height: 100%;
        margin-top: 30px;
    }
    
    .layout.wrap {
        align-items: center;
    }
    
    .btn-import {
        margin-top: 7px;
        height: 40px;
    }
    
    .active {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.5;
    }
</style>