<template lang="html">
    <div>
       <v-toolbar text color="white">
        <v-toolbar-title>MEASUREMENT UNIT CODE ({{getData.length}})</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn @click="backToInvite" rounded color="primary">Back</v-btn>
      </v-toolbar>
        <br>
         <v-card-title class="justify-content-end">            
            <v-flex md6 pr-2 >
                <h6>{{updatedDetailsForScreen}}</h6>
            </v-flex>
            <v-flex md3 pr-2 >
                <v-text-field v-model="search" label="Search"
                append-icon="mdi-magnify" single-line hide-details ></v-text-field>
            </v-flex>
        </v-card-title>
      <v-data-table :headers="headers" :items="getData" 
           :search="search" class="elevation-1">
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-right" >{{ props.item.code }}</td>
            <td class="text-xs-right">{{ props.item.description }}</td>
          </tr>
        </template>
<template v-slot:no-data>
          <span>No data found</span>
        </template>
</v-data-table>

</div>
</template>

<script>
    import MasterScreenService from "../../services/master-screenservices"
    import router from "../../router/index";

    export default {
        name: 'MeasurementUnitCode',
        data() {
            return {
                search: '',
                updatedDetailsForScreen: '',
                getData: [],
                headers: [{
                    text: "Code",
                    align: "left",
                    sortable: true,
                    value: "code"
                }, {
                    text: "Description",
                    align: "left",
                    sortable: true,
                    value: "description"
                }],
            }
        },
        computed: {},
        created() {
            this.updatedDetailsForScreen = sessionStorage.getItem("updatedDetailsForScreen");
            MasterScreenService.getMeasurementUnitCode().then(response => {
                    this.getData = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        methods: {            
            backToInvite() {
             router.push("/master-screen/cds-code-lists");
            }
        }
    }
</script>

<style>
    .justify-content-end {
        justify-content: Flex-end;
    }
</style>