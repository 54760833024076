<template>
  <div>
    <v-toolbar text color="white">
      <v-toolbar-title>Role Management</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark class="mb-2 mr-2" v-on="on" @click="addPermission('')"
        >Add Permission</v-btn
      >
      <v-dialog persistent v-model="dialog" max-width="1000px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="mb-2" v-on="on" @click="addrole"
            >Add New Role</v-btn
          >
        </template>
        <v-card>
          <form @submit.prevent="roleCreateForm">
            <v-card-title class="headline">
              <span>{{ roleDialoghead }}</span>
            </v-card-title>

            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="name"
                      :error-messages="nameErrors"
                      label="Role Name"
                      placeholder="Enter role Name"
                      required
                      outlined
                      @input="$v.name.$touch()"
                      @blur="$v.name.$touch()"
                    />
                  </v-flex>
                  <v-select
                    multiple
                    :items="getType"
                    v-model="type"
                    :error-messages="typeErrors"
                    item-text="type"
                    item-value="id"
                    label="Account Type"
                    placeholder="Account Type is required"
                    required
                    outlined
                    @input="$v.type.$touch()"
                    @blur="$v.type.$touch()"
                  >
                  </v-select>
                  <v-flex xs12 sm6 md6>
                    <v-text-field
                      v-model="description"
                      label="Description"
                      placeholder="Enter Description"
                      outlined
                      @input="$v.description.$touch()"
                      @blur="$v.description.$touch()"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
              <v-btn
                color="blue darken-1"
                v-if="addroleint == true"
                text
                @click="roleCreateForm()"
                >Save</v-btn
              >
              <v-btn
                color="blue darken-1"
                v-if="editroleint == true"
                text
                @click="roleCreateForm()"
                >Update</v-btn
              >
            </v-card-actions>
            <v-col cols="12">
              <v-snackbar
                :color="snackbar.color"
                v-model="snackbar.show"
                top
                right
                rounded
              >
                {{ snackbar.message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="closeSnackbar"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
              </v-snackbar>
            </v-col>
          </form>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <br>
    <!-- <v-data-table :headers="headers" :items="getData" class="elevation-1"> -->
    <v-data-table :headers="headers" :items="getData" 
    :sort-by="sortBy"
      :sort-desc="sortDesc"
      class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right pointer" @click="addPermission(props.item)">{{ props.item.name }}</td>
          <td class="text-xs-right">{{ props.item.type }}</td>
          <td class="text-xs-right">{{ props.item.description }}</td>
          <td class="px-0">
            <v-icon class="mr-2" @click="editrole(props.item.id)">
              mdi-account-edit
            </v-icon>
            <v-icon class="mr-2" v-if="!props.item.is_system_defined" @click="deleterole(props.item.id)">
              mdi-delete
            </v-icon>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import RoleManagementService from "../services/role-managementservices";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import router from "../router";
export default {
  mixins: [validationMixin],
  data() {
    return {
      name: "",
      type: "",
      description: "",
      itemId: "",
      roleDialoghead: "",
      getData: [],
      getType: [],
      nameFilter: "",
      editId: "",
      editflag: false,
      addroleint: false,
      editroleint: false,
      roleCreateData: {},
      snackbar: {
        show: false,
        message: null,
        color: null,
      },

      dialog: false,
      role: false,
      submitStatus: null,
      roleData: [],
      editroleValues: "",
      sortBy: 'name',
      sortDesc: false,
      headers: [
        { text: "Role Name", align: "left", sortable: true, value: "name" },
        { text: "Site Type", align: "left", sortable: true, value: "type" },
        {
          text: "Description",
          align: "left",
          sortable: false,
          value: "description",
        },
        { text: "Actions", align: "left", sortable: false },
      ],
    };
  },
  validations: {
    name: { required },
    type: { required },
  },

  computed: {
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Role Name is required");
      return errors;
    },
    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;
      !this.$v.type.required && errors.push("Account type is required");
      return errors;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.getAllroles();
    this.getAccountType();
  },

  methods: {
    getAllroles() {
      RoleManagementService.getAllRole()
        .then((response) => {
          this.getData = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getAccountType() {
      this.getType = ["Admin","GroupAccount","SiteAccount"];
    },
    roleCreateForm(e) {
      console.log(e);
      if (this.addroleint == true) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.snackbar = true;
          this.snackbar = {
            message: "Please fill the form correctly",
            color: "error",
            show: true,
          };
        } else {
          var roleCreateData = {
            name: this.name,
            description: this.description,
            type: this.type,
          };
          RoleManagementService.saveRole(roleCreateData)
            .then((response) => {
              if (response) {
                if (response == "Role name already exists") {
                  this.snackbar = true;
                  this.snackbar = {
                    message: "Already Existing",
                    color: "error",
                    show: true,
                  };
                } else {
                  this.getAllroles();
                  this.dialog = false;
                  this.snackbar = true;
                  this.snackbar = {
                    message: "Role created succesfully",
                    color: "success",
                    show: true,
                  };
                }
              }
              this.submitted = true;
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = true;
              this.snackbar = {
                message: "OOPS!SOMETHING WENT WRONG",
                color: "error",
                show: true,
              };
            });

          this.clear();
        }
      } else if (this.editroleint == true) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.snackbar = true;
          this.snackbar = {
            message: "Please fill the form correctly",
            color: "error",
            show: true,
          };
        } else {
          var roleEditData = {
            id: this.editId,
            name: this.name,
            description: this.description,
            type: this.type,
          };
          RoleManagementService.editRole(roleEditData)
            .then((response) => {
              if (response) {
                this.getAllroles();
                this.dialog = false;
                this.snackbar = true;
                this.snackbar = {
                  message: "Role updated succesfully",
                  color: "success",
                  show: true,
                };
              }
              this.submitted = true;
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = true;
              this.snackbar = {
                message: "OOPS!SOMETHING WENT WRONG",
                color: "error",
                show: true,
              };
            });
          this.clear();
        }
      }
    },
    addPermission(role) {
      const permissionUrl = role != '' ? "/user/modulePermissions?roleId="+role.id : "/user/modulePermissions"
      router.push( permissionUrl ).catch(() => {});
    },
    addrole() {
      this.editroleint = false;
      this.addroleint = true;
      this.roleDialoghead = "Add New Role";
    },
    deleterole(id) {
      if (confirm("Do you really want to delete?")) {
        RoleManagementService.deleteRole(id)
          .then((response) => {
            if (response) {
              this.getAllroles();
              this.snackbar = true;
              this.snackbar = {
                message: "Role deleted succesfully",
                color: "success",
                show: true,
              };
            }
            this.submitted = true;
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      }
    },
    editrole(id) {
      this.editId = id;
      this.addroleint = false;
      this.editroleint = true;
      this.roleDialoghead = "Edit Role";
      this.dialog = true;
      RoleManagementService.getRoleById(id)
        .then((response) => {
          if (response) {
            this.editflag = true;
            this.editroleValues = response.data;
            this.name = this.editroleValues.name;
            this.type = this.editroleValues.type;
            this.description = this.editroleValues.description;
          }
        })
        .catch((e) => {
          console.log(e);
          this.snackbar = true;
          this.snackbar = {
            message: "OOPS!SOMETHING WENT WRONG",
            color: "error",
            show: true,
          };
        });
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.roles[this.editedIndex], this.editedItem);
      } else {
        this.roles.push(this.editedItem);
      }
      this.dialog = false;
    },
    cancel() {
      this.clear();
      this.dialog = false;
      this.role = false;
    },
    clear() {
      this.$v.$reset();
      this.name = "";
      this.type = "";
      this.description = "";
    },
    assign() {
      this.role = false;
    },
  },
};
</script>
<style>
.pointer {
    cursor: pointer;
}
</style>