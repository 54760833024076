<template>
  <div>
    <v-toolbar text color="white">
        <v-toolbar-title>Declaration Categories ({{categoryListLength}})</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn @click="backToInvite" rounded color="primary">Back</v-btn>
      </v-toolbar>
    <!-- <v-toolbar text color="white" class="mb-5">
      <v-layout wrap>
        <v-flex xs12 sm4 md4 pr-2>
          <v-file-input
            truncate-length="15"
            accept=".xlsx"
            placeholder="Click here to select file"
            @change="selectFile"
            v-model="currentFile"
          ></v-file-input>
        </v-flex>
        <v-flex xs12 sm4 md4 pr-2>
          <v-btn
            :loading="buttonDisable"
            :disabled="buttonDisable"
            color="primary"
            class="ma-2 white--text"
            @click="uploadFile"
          >
            Upload Declaration Categories
            <v-icon right> mdi-cloud-upload </v-icon>
          </v-btn>
        </v-flex>
        <v-flex xs12 sm4 md4 pr-2>
          <v-btn @click="backToInvite" class="ma-2" rounded color="primary">Back</v-btn> 
        </v-flex>
      </v-layout>
    </v-toolbar> -->
    <!-- <v-toolbar text color="white">
      <v-toolbar-title
        >Declaration Categories (<span>{{ categoryListLength }}</span
        >)</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-col class="mt-3 justify-content-end" cols="4">
        <h5>{{updatedDetailsForScreen}}</h5>
      </v-col>
      <v-spacer></v-spacer>
      <v-flex md4 pr-2>
        <v-text-field
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
    </v-toolbar> -->

        <!--new BarAdded-->
        <div>
            <v-card class="mt-5 mb-5">
            <v-card-text class="resize">
                <v-container>
                <v-row>
                  <v-col class="p-0 justify-content-center" cols="12">
                        <h5>{{updatedDetailsForScreen}}</h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col  cols="12" sm="12" lg="8" class="pt-0">
                    <!-- <UploadDocuments :Screename="ActiveScreen" @sendResponse="ReceiveResponse"/> -->
                     <UploadDocuments @sendResponse="ReceiveResponse"/> 
                    </v-col>
                    <v-col cols="12" sm="12" lg="4" class="pt-0">
                    <v-text-field v-model="search" label="Search" class="mt-3"
                        append-icon="mdi-magnify" single-line hide-details ></v-text-field>
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
            </v-card>
        </div>
        <!--end-->
    <v-data-table
      :headers="headers"
      :items="categoryList"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right">{{ props.item.type }}</td>
          <td class="text-xs-right">{{ props.item.name }}</td>
          <td class="text-xs-right">{{ props.item.description }}</td>
          <td class="text-xs-right">{{ props.item.procedureCode }}</td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
   <template>
    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.show"
      top
      right
      rounded
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
 </template>
  </div>
</template>

<script>
import MasterScreenService from "../../services/master-screenservices";
import router from "../../router/index";
import UploadDocuments from '../uploaddocuments.vue';

export default {
  mixins: [],
  components: {
         UploadDocuments
  },
  name: "DeclarationCategories",
  data() {
    return {
      updatedDetailsForScreen: '',
      currentFile: undefined,
      categoryListLength: 0,
      buttonDisable: false,
      search: "",
      snackbar: {
        show: false,
        message: null,
        color: null,
      },

      headers: [
        {
          text: "Type",
          align: "left",
          sortable: true,
          value: "type",
        },
        {
          text: "Name",
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: "Description",
          align: "left",
          sortable: true,
          value: "description",
        },
        {
          text: "Procedure Code",
          align: "left",
          sortable: true,
          value: "procedureCode",
        }
      ],
      categoryList: [],
    };
  },
  validations: {},
  computed: {},
  created() {
    this.updatedDetailsForScreen = sessionStorage.getItem("updatedDetailsForScreen");
    this.DeclarationCategorieslist();
  },

  methods: {  
    DeclarationCategorieslist(){
    MasterScreenService.getdeclarationCategoryList().then(
      (response) => {
        response.data.forEach(element => {
            element.procedureCode = element.procedureCode.join();
        });
        this.categoryList = response.data;
        this.categoryListLength = this.categoryList.length;
      }
    );
  },

    backToInvite() {
      router.push("/master-screen/cds-code-lists");
    },        
    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
    },

    ReceiveResponse(res){
    if(res == undefined){
        this.snackbar = true;
        this.snackbar = {
        message: "Please select a file to upload",
        color: "red",
        show: true,
        };
    }
    else if(res == false){
        this.snackbar = true;
        this.snackbar = {
        message: "Something went wrong! please try again",
        color: "red",
        show: true,
        };
    }
    else if(res == true){
        this.snackbar = true;
        this.snackbar = {
        message: "File uploaded successfully!",
        color: "green",
        show: true,
        };
    this.updatedDetailsForScreen = sessionStorage.getItem("updatedDetailsForScreen");      
    this.DeclarationCategorieslist();
    }
    else if(res == 'completed'){
        this.snackbar = true;
        this.snackbar = {
        message: "File downloaded successfully!",
        color: "green",
        show: true,
        };
      }
   else if(res == 'nofile'){
        this.snackbar = true;
        this.snackbar = {
        message: "No file exists! please upload a new file first!!!",
        color: "orange",
        show: true,
        };
      }
      else if(res == 'discard'){
      this.snackbar = true;
      this.snackbar = {
      message: "File changes discarded successfully!",
      color: "orange",
      show: true,
      };
      }
    },
    // selectFile(file) {
    //   this.currentFile1 = file;
    // },
    // uploadFile() {
    //   if (!this.currentFile) {
    //     this.snackbar = true;
    //     this.snackbar = {
    //       message: "Please select a file to upload",
    //       color: "red",
    //       show: true,
    //     };
    //     return;
    //   }

    //   this.buttonDisable = true;
    //   MasterScreenService.uploadDeclarationCategoryList(this.currentFile)
    //     .then((response) => {
    //       this.snackbar = true;
    //       if (response.data.success == false) {
    //         this.snackbar = {
    //           message: response.data.message,
    //           color: "red",
    //           show: true,
    //         };
    //         this.buttonDisable = false;
    //         return;
    //       }

    //       this.snackbar = {
    //         message: response.data.message,
    //         color: "green",
    //         show: true,
    //       };
    //       return MasterScreenService.getdeclarationCategoryList()
    //         .then((response) => {
    //           this.categoryList = response.data;
    //           this.categoryListLength = this.categoryList.length;
    //           this.buttonDisable = false;
    //           this.currentFile = undefined;
    //         })
    //         .catch((e) => {
    //           console.log(e);
    //           this.buttonDisable = false;
    //         });
    //     })
    //     .catch((e) => {
    //       this.snackbar = true;
    //       this.snackbar = {
    //         message: e.response.data.errors.File[0],
    //         color: "red",
    //         show: true,
    //       };
    //       this.buttonDisable = false;
    //       return;
    //     });
    // },
  },
};
</script>

<style>
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
  justify-content: CENTER;
  height: 100%;
  margin-top: 30px;
}
    .resize {
       padding: 0px !important;
    }
  .justify-content-center {
        display: flex;
        justify-content: center;
   }
</style>