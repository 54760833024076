<template>
  <v-container fluid fill-width>
    <v-snackbar
        :color="snackbar.color"
        v-model="snackbar.show"
        top
        right
        rounded
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="closeSnackbar"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <div style="margin-left:30px">
      <v-row color="primary" dark class="mb-2 btn-import" :disabled="editing">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
          <template v-slot:activator="{ attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                @click="reset();dialog=true;createNew = true;"
            >
              New Release Note
            </v-btn>
          </template>
          <v-card>
            <v-card-title v-if="createNew">Create</v-card-title>
            <v-card-title v-if="editing">Edit</v-card-title>
            <v-row class="text-center" justify="center">
              <v-col cols="10">
                <form ref="dialog_form" @submit.prevent="submit">
                  <v-text-field
                      v-model="title"
                      :error-messages="titleErrors"
                      label="Banner Title"
                      required
                      outlined
                      @input="$v.title.$touch()"
                      @blur="$v.title.$touch()"
                  />
                  <v-textarea
                      v-model="description"
                      :error-messages="descriptionErrors"
                      label="Message"
                      required
                      outlined
                      @input="$v.description.$touch()"
                      @blur="$v.description.$touch()"
                  ></v-textarea>
                  <v-text-field
                      v-model="url"
                      label="Release notes URL"
                      outlined
                      @input="$v.url.$touch()"
                      @blur="$v.url.$touch()"
                  />
                  <v-select
                      v-model="selectedsupplychains"
                      :items="supplychains"
                      label="Supply Chain"
                      outlined
                      multiple/>
                  <v-row style="margin-left: 0px">
                    <v-col>
                      <template>
                        <v-row>
                          <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="activeFromDate"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                          </v-menu>
                          <v-menu
                              v-model="menu1"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="formatted_date_from"
                                  label="Active From Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                :error-messages="dateFromErrors"
                                v-model="activeFromDate"
                                @input="menu1 = false;$v.activeFromDate.$touch()"
                                @blur="$v.activeFromDate.$touch()"
                            ></v-date-picker>
                          </v-menu>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col>
                      <template>
                        <v-row>
                          <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="activeToDate"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                          </v-menu>
                          <v-menu
                              v-model="menu2"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="formatted_date_to"
                                  label="Active To Date"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="activeToDate"
                                @input="menu2 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-row>
                      </template>
                    </v-col>
                  </v-row>
                  <v-row style="margin-left: 0px; margin-top: -15px">
                    <v-switch v-if="editing"
                              v-model="published"
                              :label="this.checkPublished()"
                    ></v-switch>
                  </v-row>
                </form>
              </v-col>
            </v-row>
            <v-card-actions style="margin-top: -10px">
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="reset();"
              >
                Close
              </v-btn>
              <v-btn v-if="createNew"
                     color="blue darken-1"
                     text
                     @click="submit()"
              >
                Save
              </v-btn>
              <v-btn v-if="editing"
                     color="blue darken-1"
                     text
                     @click="updateNote"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
    <v-card style="margin: 20px; padding:20px">
      <div>
        <v-data-table :headers="headers" :items="getData" class="elevation-1"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
        >
          <template v-slot:item="entry">
            <tr>
              <td class="text-xs-right" >{{ format_date_ui(entry.item.active_from) }}</td>
              <td class="text-xs-right" >{{ format_date_ui(entry.item.active_till) }}</td>
              <td class="text-xs-right">{{ entry.item.title }}</td>
              <td class="text-xs-right">
                <p style="margin: auto">{{checkReleased(entry.item.released)}}</p>
              </td>

              <td class="text-xs-right">
                <v-btn color="primary" dark class="mb-2 btn-import" @click="editNote(entry.item)"> Edit </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            <span>No data found</span>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import { validationMixin } from "vuelidate";
import {
  required,
} from "vuelidate/lib/validators";
import AccountService from "../services/accountservices";
import moment from 'moment'
export default {
  components: {
  },
  mixins: [validationMixin],
  data: vm => ({
    formatted_date_to: vm.format_date_calendar((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    formatted_date_from: vm.format_date_calendar((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    modal: false,
    menu2: false,
    menu1: false,
    sortBy: 'active_from',
    sortDesc: true,
    current_note: null,
    published: false,
    dialog: false,
    editing: false,
    createNew: false,
    activeToDate: null,
    activeFromDate: null,
    menu: false,
    getData: [],
    title: "",
    description: "",
    url: "",
    supplychains: [],
    selectedsupplychains: [],
    headers: [{
      text: "Active From",
      align: "left",
      sortable: true,
      value: "active_from"
    },{
      text: "Active To",
      align: "left",
      sortable: true,
      value: "active_till"
    }, {
      text: "Title",
      align: "left",
      sortable: true,
      value: "title"
    },
      {
        text: "Released",
        align: "left",
        sortable: true,
        value: "released"
      },
      {
        text: "Options",
        align: "left",
        sortable: false
      }
    ],
    snackbar: {
      show: false,
      message: null,
      color: null,
    },
  }),
  validations: {
    title: { required },
    description: { required },
    activeFromDate: {required},
    activeToDate: {required}
  },
  watch: {
    activeToDate () {
      this.formatted_date_to = this.format_date_calendar(this.activeToDate)
    },
    activeFromDate () {
      this.formatted_date_from = this.format_date_calendar(this.activeFromDate)
    },
  },
  computed: {
    titleErrors() {
      const errors = [];
      if (!this.$v.title.$dirty) return errors;
      !this.$v.title.required && errors.push("Title is required");
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.required && errors.push("Description required");
      return errors;
    },
    dateFromErrors() {
      const errors = [];
      if (!this.$v.activeFromDate.$dirty) return errors;
      !this.$v.activeFromDate.required && errors.push("Date From is required");
      return errors;
    },
    dateToErrors() {
      const errors = [];
      if (!this.$v.activeToDate.$dirty) return errors;
      !this.$v.activeToDate.required && errors.push("Date To is required");
      return errors;
    }
  },
  created() {
    this.getReleaseNotes();
    this.getAllSupplyChains();
  },
  methods: {
    getAllSupplyChains(){
      AccountService.getAllSupplyChains().then((response) => {
        this.supplychains = response.data.map((item) => item.name);
      });
    },
    format_date_ui(value) {
      if (value) {
        return moment((value)).format('DD/MM/YY')
      }
    },
    format_date_calendar (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    getReleaseNotes() {
      AccountService.getReleaseNotes()
          .then((response) => {
            if (response) {
              this.getData = response.data;
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid || (this.activeToDate == null) || (!this.activeFromDate == null)) {
        this.snackbar = true;
        this.snackbar = {
          message: "Please fill the form correctly",
          color: "error",
          show: true,
        };
        console.log(this.snackbar)
      } else {
        if ((this.activeToDate) && (this.activeFromDate)) {
          var data = {
            title: this.title,
            description: this.description,
            url: this.url,
            released: false,
            active_till: this.activeToDate,
            active_from: this.activeFromDate,
            supplychain: this.selectedsupplychains
          };
          console.log(data);
          AccountService.addReleaseNote(data)
              .then((response) => {
                this.dialog = false;
                console.log(response);
                this.snackbar = {
                  message: "Success",
                  color: "success",
                  show: true,
                };
                this.title = "";
                this.description = "";
                this.url = "";
                this.getReleaseNotes();
              })
              .catch((e) => {
                console.log(e);
                if (e.request.status === 400) {
                  this.snackbar = {
                    message: e,
                    color: "error",
                    show: true,
                  };
                } else {
                  this.snackbar = {
                    message: e,
                    color: "error",
                    show: true,
                  };
                }
              });
          this.$v.$reset();
        } else {
          console.log('date incorrect')
          this.snackbar = {
            message: "Date format is invalid",
            color: "error",
            show: true,
          };
        }
      }
    },
    reset(){
      if(this.$refs.dialog_form) {
        this.$refs.dialog_form.reset();
        this.$v.$reset();
      }
      this.editing = false;
      this.createNew = false;
      this.current_note = null;
      this.published = false;
      this.dialog = false;
      this.activeToDate = null;
      this.activeFromDate = null;
      this.title = null;
      this.description = null;
      this.url = null;
      this.published = null;
      this.formatted_date_from = null;
      this.formatted_date_to = null;
      this.selectedsupplychains = [];
    },
    checkReleased(bool){
      if (bool) return "PUBLISHED"
      else return "DRAFT"
    },
    checkPublished() {
      if(this.published) {
        return 'Published'
      } else {
        return 'Draft'
      }
    },
    editNote(note) {
      this.current_note = note;
      this.dialog = true;
      this.editing = true;
      this.createNew = false;
      this.activeToDate = new Date(note.active_till).toISOString().split('T')[0];
      this.activeFromDate =  new Date(note.active_from).toISOString().split('T')[0];
      this.title = note.title;
      this.description = note.description;
      this.url = note.url;
      this.published = note.released;
      this.selectedsupplychains = note.supplychain;
    },
    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
    },
    updateNote() {
      console.log(this.current_note.id);
      if (this.activeToDate && this.activeFromDate) {
        var data = {
          id: this.current_note.id,
          title: this.title,
          description: this.description,
          url: this.url,
          released: this.published,
          active_till: this.activeToDate,
          active_from: this.activeFromDate,
          supplychain: this.selectedsupplychains
        };
        AccountService.updateReleaseNote(data)
            .then((response) => {
              console.log(response);
              this.snackbar = {
                message: "Success",
                color: "success",
                show: true,
              };
              this.title = "";
              this.description = "";
              this.url = "";
              this.current_note = null;
              this.getReleaseNotes();
              this.editing = false;
              this.dialog = false;
            })
            .catch((e) => {
              console.log(e);
              if (e.request.status === 400) {
                this.snackbar = {
                  message: e,
                  color: "error",
                  show: true,
                };
              } else {
                this.snackbar = {
                  message: e,
                  color: "error",
                  show: true,
                };
              }
            });
      }
    }
  },
};
</script>
<style>
.code input {
  text-transform: uppercase;
}
</style>
