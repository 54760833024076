import { getInstance } from "./index";

const AuthGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      return next();
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  
  if (!authService.loading) {
    return fn();
  }
  
  authService.$watch("loading", loading => {
    if (loading === false) {
      return fn();
    }
  });
};

export default AuthGuard