<template lang="html">
   <div>
      <v-toolbar text color="white">
      <v-toolbar-title>Group/Site Management</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
       <v-btn @click="sendInvitation" rounded color="primary">Invite</v-btn>
    </v-toolbar>
      <br>
      <v-data-table :headers="headers" :items="getData" class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right pointer" @click="accountDetails(props.item.account_id)"><a>{{ props.item.name }}</a></td>
          <td class="text-xs-right">{{ props.item.status }}</td>
          <td class="text-xs-right">{{ props.item.email }}</td>
          <td class="text-xs-right">{{ props.item.subscriptions }}</td>
          <td class="text-xs-right">{{ props.item.sites }}</td>
          <td class="text-xs-right">{{ props.item.seals }}</td>
          <td class="text-xs-right">{{ props.item.users }}</td>
          <td class="text-xs-right">{{ props.item.traces }}</td>
          <td class="text-xs-right">{{ props.item.tariff }}
            <v-btn  color="primary" @click="showChangeTariff(props.item.account_id, props.item.tariff_id)">Change</v-btn>
          </td>
          <td class="px-0">
            <v-btn v-if="props.item.status == 'Invitation Sent'"  @click="reSendInvitation(props.item.group_code,props.item.email)" color="primary">Resend</v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
  <span>No data found</span>
</template>
    </v-data-table>
     <v-dialog v-model="resendInvite" max-width="500px" persistent>
        <v-card>
          <v-card-title class="headline">
            <span>Resend Invitation</span>
          </v-card-title>
          <form @submit.prevent="resend">

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                   <v-text-field
              v-model="groupcode" disabled
              label="Group Code"
              placeholder="Enter Group Code"
              outlined
            />
                </v-flex>
                <v-flex xs12 sm12 md12>
               <v-text-field
              v-model="emailaddress"
              :error-messages="emailAddressErrors"
              label="Email Address"
              placeholder="Enter Email Address"
              required
              outlined
              @input="$v.emailaddress.$touch()"
              @blur="$v.emailaddress.$touch()"
            />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click = "cancelInvite" >Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="resend"
              >Resend</v-btn
            >
          </v-card-actions>
          </form>
        </v-card>
      </v-dialog>

      <v-dialog v-model="changeTariff" max-width="500px" persistent>
        <v-card>
          <v-card-title class="headline">
            <span>Change Group Tariff</span>
          </v-card-title>
          <form @submit.prevent="resend">

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12>
                  <v-select
                    :items="tariffList"
                    v-model="tariffId"
                    :error-messages="tariffIdErrors"
                    item-text="name"
                    item-value="id"
                    label="Tariff"
                    placeholder="Tariff is required"
                    required
                    outlined
                    @input="$v.tariffId.$touch()"
                    @blur="$v.tariffId.$touch()"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm12 md12>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click = "cancelTariff" >Cancel</v-btn>
            <v-btn color="blue darken-1" text @click="changeGroupTariff">Change</v-btn>
          </v-card-actions>
          </form>
        </v-card>
      </v-dialog>

   </div>
</template>

<script>
import router from "../router";
import AccountService from "../services/accountservices";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Group",
  mixins: [validationMixin],

  data() {
    return {
      name: "",
      type: "",
      description: "",
      itemId: "",
      emailaddress: "",
      roleDialoghead: "",
      groupcode: "",
      accountId: "",
      getData: [],
      getType: [],
      nameFilter: "",
      editId: "",
      tariffId: "",
      tariffList: [],
      editflag: false,
      addroleint: false,
      editroleint: false,
      resendInvitation: false,
      resendInvite: false,
      changeTariff: false,
      roleCreateData: {},
      snackbar: {
        show: false,
        message: null,
        color: null,
      },

      dialog: false,
      role: false,
      submitStatus: null,
      roleData: [],
      editroleValues: "",
      headers: [
        { text: "Name", align: "left", sortable: true, value: "name" },
        { text: "Status", align: "left", sortable: true, value: "type" },
        {
          text: "Primary Contacts",
          align: "left",
          sortable: false,
          value: "description",
        },
        { text: "Subscriptions", align: "left", sortable: true, value: "type" },
        { text: "Sites", align: "left", sortable: true, value: "type" },
        { text: "Seals", align: "left", sortable: true, value: "type" },
        { text: "Users", align: "left", sortable: true, value: "type" },
        { text: "Traces", align: "left", sortable: true, value: "type" },
        { text: "Tariff", align: "left", sortable: true, value: "tariff" },
        { text: "Actions", align: "left" },
      ],
    };
  },
  validations: {
    emailaddress: { required },
    tariffId: { required },
  },
  computed: {
    emailAddressErrors() {
      const errors = [];
      if (!this.$v.emailaddress.$dirty) return errors;
      !this.$v.emailaddress.email && errors.push("Must be valid e-mail");
      !this.$v.emailaddress.required && errors.push("E-mail is required");
      return errors;
    },
    tariffIdErrors() {
      const errors = [];
      if (!this.$v.tariffId.$dirty) return errors;
      !this.$v.tariffId.required && errors.push("Tariff required");
      return errors;
    },
  },

  created() {
    this.getGroupValues();
  },
  methods: {
    sendInvitation() {
      router.push("/invite");
    },

    accountDetails(account_id) {
      router.push("/user/AccountDetails/" + account_id);
    },

    reSendInvitation(group, email) {
      this.groupcode = group;
      this.emailaddress = email;
      this.resendInvite = true;
    },

    cancelInvite() {
      this.resendInvite = false;
      this.required = false;
    },

    resend() {
      this.required = true;
      var data = {
        group_code: this.groupcode,
        email: this.emailaddress,
      };
      console.log("invitedatatoAPI->", data);

      AccountService.inviteAccount(data)
        .then((response) => {
          if (response) {
            console.log("invite", response);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    showChangeTariff(account_id, tariff_id) {
      this.getTariff();
      this.changeTariff = true;
      this.accountId = account_id;
      this.tariffId = tariff_id;
    },

    changeGroupTariff() {
      this.required = true;
      var data = {
        account_id: this.accountId,
        tariff_id: this.tariffId,
      };
      AccountService.changeTariff(data)
        .then((response) => {
          if (response) {
            this.changeTariff = false;
            console.log(response);
          }
        })
        .catch((e) => {
          console.log(e);
          this.changeTariff = false;
        });
    },

    cancelTariff() {
      this.changeTariff = false;
      this.required = false;
    },

    getGroupValues() {
      AccountService.getGroupDatas()
        .then((response) => {
          if (response) {
            this.getData = response.data;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getTariff() {
      AccountService.getTariff()
        .then((response) => {
          if (response) {
            this.tariffList = response.data;
          }
        })
        .catch((e) => {
          console.log(e);
          this.snackbar = {
            message: "OOPS!SOMETHING WENT WRONG",
            color: "error",
            show: true,
          };
        });
    },
  },
};
</script>
<style>
.pointer {
  cursor: pointer;
}
</style>
