<template>
    <v-navigation-drawer v-model="sidebarMenu" app floating :permanent="sidebarMenu" :mini-variant.sync="mini"
        class="shadow">
        <v-list dense dark class="py-0">
            <v-list-item class="logo-dash">
                <v-list-item-action class="mr-1">
                    <v-icon @click="toggleMini = !toggleMini" color="primary">mdi-chevron-left</v-icon>
                </v-list-item-action>
                <v-list-item-content class="py-0">
                    <v-list-item-title>
                        <v-img :src="require('../../assets/p2d_logo.png')" class="my-3 mx-auto" contain width="500" />
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list v-for="item in items" :key="item.title">
            <v-list-item v-if="!item.subLinks" link :to="item.href" v-model="item.active">
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-group v-else :key="item.title" link :prepend-icon="item.icon" v-model="item.active">
                <template v-slot:activator>
                    <v-list-item-title class="font-size-1">{{ item.title }}</v-list-item-title>
                </template>

<v-list-item v-for="sublink in item.subLinks" :to="sublink.href" link :key="sublink.title">
    <v-list-item-icon>
        <v-icon>{{ sublink.icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-title>{{ sublink.title }}</v-list-item-title>

</v-list-item>

</v-list-group>
</v-list>
</v-navigation-drawer>
</template>

<script>
    export default {
        computed: {
            mini() {
                return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
            },

        },
        data: () => ({
            sidebarMenu: true,
            toggleMini: false,
            items: [{
                        title: "Invite User",
                        href: "/invite",
                        icon: "mdi-email"
                    }, 
                    {
                        title: 'CDS Code Lists',
                        href: "/master-screen/cds-code-lists",
                        icon: 'mdi-folder',
                        // subLinks: [{
                        //         title: "Exchange Rates",
                        //         href: "/master-screen/exchangerates",
                        //         active: true,
                        //     }, {
                        //         title: "Trade Blocs",
                        //         href: "/master-screen/tradeblocs"
                        //     }, {
                        //         title: "Countries",
                        //         href: "/master-screen/countries"
                        //     }, {
                        //         title: "Inco Terms",
                        //         href: "/master-screen/incoterms"
                        //     }, {
                        //         title: "Freight Locations",
                        //         href: "/master-screen/freightlocations"
                        //     // }, {
                        //     //     title: "Supervising Customs Offices",
                        //     //     href: "/master-screen/supervisingcustomsoffices"
                        //     }, {
                        //         title: "Customs Offices",
                        //         href: "/master-screen/customsOffices"
                        //     // }, {
                        //     //     title: "UK Customs Offices",
                        //     //     href: "/master-screen/ukcustomsoffices"
                        //     }, {
                        //         title: "Package Types",
                        //         href: "/master-screen/packagetypes"
                        //     }, {
                        //         title: "Nature of Transactions",
                        //         href: "/master-screen/NatureOfTransactions"
                        //     }, {
                        //         title: "Border Control Posts",
                        //         href: "/master-screen/Bordercontrolposts"
                        //     }, {
                        //         title: "Goods Locations",
                        //         href: "/master-screen/goodslocations"
                        //     }, {
                        //         title: "Transport Methods Of Payment",
                        //         href: "/master-screen/transportmethodsofpayment"
                        //     }, {
                        //         title: "Modes Of Transport",
                        //         href: "/master-screen/modesoftransport"
                        //     }, {
                        //         title: "Identity Of Means Of Transport",
                        //         href: "/master-screen/identityofmeansoftransport"
                        //     }, {
                        //         title: "Document Code",
                        //         href: "/master-screen/documentcode"
                        //     }, {
                        //         title: "Condition Code",
                        //         href: "/master-screen/conditioncode"
                        //     }, {
                        //         title: "Tax Types",
                        //         href: "/master-screen/taxtypes"
                        //     },
                        //     //{
                        //     //     title: "Commodity Code Master",
                        //     //     href: "/master-screen/commodity-code-master"
                        //     // }, {
                        //     //     title: "Commodity Code Lookup",
                        //     //     href: "/master-screen/commodity-code-lookup"
                        //     // }, 
                        //     {
                        //         title: "Party Sub Role",
                        //         href: "/master-screen/partysubrole"
                        //     }, {
                        //         title: "Vat Party Sub Role",
                        //         href: "/master-screen/vatpartysubrole"
                        //     }, {
                        //         title: "Measurement Unit Code",
                        //         href: "/master-screen/measurementunitcode"
                        //     }, {
                        //         title: "Statement Code",
                        //         href: "/master-screen/statementcode"
                        //     }, {
                        //         title: "Authorization Type Code",
                        //         href: "/master-screen/authorizationtypecode"
                        //     }, {
                        //         title: "4 Digit Procedure",
                        //         href: "/master-screen/four-digit-procedure-code"
                        //     }, {
                        //         title: "Additional Procedure",
                        //         href: "/master-screen/additional-procedure-code"
                        //     },

                        //     {
                        //         title: "Document Status Code",
                        //         href: "/master-screen/document-status-code"
                        //     },{
                        //         title: "WCO Code List",
                        //         href: "/master-screen/wco-code-list"
                        //     },{
                        //         title: "Declaration Categories",
                        //         href: "/master-screen/declaration-categories"
                        //     },{
                        //         title: "Airport Details",
                        //         href: "/master-screen/airport-details"
                        //     }
                        // ]
                },
                {
                    title: "Group/Site Management",
                    href: "/managegroups",
                    icon: "mdi-email"
                },
              {
                title: "Release Notes",
                href: "/releasenotes",
                icon: "mdi-email"
              },
                 { title: "Role Management", href: "/role", icon: "mdi-account" },
                 { title: "User Management", href: "/userManagement", icon: "mdi-account" },
              {
                title: "Supply Chains",
                href: "/supplychains",
                icon: "mdi-email"
              },
                // { title: "Dashboard", href: "/dashboard", icon: "mdi-home-outline" },
                 //{ title: "Account Configuration", href: "/accountconfigurations", icon: "mdi-account" },
                // { title: "Role Management", href: "/role", icon: "mdi-account" },
                //  {title: "Request Seals", href: "/requestseal", icon: "mdi-arrange-bring-forward" }
            ],
        }),
        created() {
            if (this.activeMasterData == true) {
                console.log("hai");
            }
        },
        methods: {
            init() {
                this.activeMasterData = true;
                window.sessionStorage.setItem('activeMasterData', this.activeMasterData);
                console.log(this.activeMasterData);
            }
        }
    }
</script>
<style>
    .text {
        color: #ffffff;
    }
    
    .shadow {
        box-shadow: rgb(213, 213, 213) 0px 0px 6px;
    }
    
    .v-list-item__title,
    .v-list-item__subtitle {
        display: flex;
        flex-wrap: wrap;
        white-space: normal;
    }
    
    .v-application--is-ltr .v-list-item__action:first-child,
    .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: 15px;
    }
    
    .v-application--is-ltr .v-list-group--no-action>.v-list-group__items>.v-list-item {
        padding-left: 25px;
    }
    
    .v-application a {
        color: #1976d2 !important;
    }
    
    .v-list-group--active .v-list-item__title {
        font-size: 13px;
    }
    
    .font-size-1 {
        font-size: 1rem !important;
    }
    
    .v-list-group__items .v-list-item {
        min-height: 40px !important;
    }
</style>
