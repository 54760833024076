<template lang="html">
    <div>
       <v-toolbar text color="white">
        <v-toolbar-title>DOCUMENT CODE ({{getData.length}})</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-btn @click="backToInvite" rounded color="primary">Back</v-btn>
      </v-toolbar>

        <!--new BarAdded-->
        <div>
            <v-card class="mt-5 mb-5">
            <v-card-text class="resize">
                <v-container>
                <v-row>
                  <v-col class="p-0 justify-content-center" cols="12">
                        <h5>{{updatedDetailsForScreen}}</h5>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col  cols="12" sm="12" lg="8" class="pt-0">
                    <!-- <UploadDocuments :Screename="ActiveScreen" @sendResponse="ReceiveResponse"/> -->
                     <UploadDocuments @sendResponse="ReceiveResponse"/> 
                    </v-col>
                    <v-col cols="12" sm="12" lg="4" class="pt-0">
                    <v-text-field v-model="search" label="Search" class="mt-3"
                        append-icon="mdi-magnify" single-line hide-details ></v-text-field>
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
            </v-card>
        </div>
        <!--end-->

      <v-data-table :headers="headers" :items="getData" 
           :search="search" class="elevation-1">
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-right" >{{ props.item.documentCode }}</td>
            <td class="text-xs-right">{{ props.item.licenceType }}</td>
            <td class="text-xs-right" >{{ props.item.description }}</td>
            <td class="text-xs-right">{{ props.item.details }}</td>
          </tr>
        </template>
<template v-slot:no-data>
          <span>No data found</span>
        </template>
</v-data-table>

 <template>
    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.show"
      top
      right
      rounded
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
 </template>

</div>
</template>

<script>
    import MasterScreenService from "../../services/master-screenservices"
    import UploadDocuments from '../uploaddocuments.vue';
    import router from "../../router/index";

    export default {
         components: {
         UploadDocuments
        },
        name: 'DocumentCode',
        data() {
            return {
               // ActiveScreen : "DocumentCode",
                updatedDetailsForScreen: '',
                search: '',
                getData: [],
                snackbar: {
                show: false,
                message: null,
                color: null,
                },
                headers: [{
                    text: "Document Code",
                    align: "left",
                    sortable: true,
                    value: "documentCode"
                }, {
                    text: "License Type",
                    align: "left",
                    sortable: true,
                    value: "licenceType"
                }, {
                    text: "Description and Usage Of Code",
                    align: "left",
                    sortable: true,
                    value: "description"
                }, {
                    text: "Details to be entered on the declaration",
                    align: "left",
                    sortable: true,
                    value: "details"
                }],
            }
        },
        computed: {},
        created() {
            this.updatedDetailsForScreen = sessionStorage.getItem("updatedDetailsForScreen");
            this.documentCodelist();
        },

        methods: {
              backToInvite() {
              router.push("/master-screen/cds-code-lists");
              },

              closeSnackbar(){
              this.snackbar = {
                show: false,
               };
              },

              documentCodelist(){
                MasterScreenService.getDocumentCode().then(response => {
                    this.getData = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
              },

              ReceiveResponse(res){
                if(res == undefined){
                    this.snackbar = true;
                    this.snackbar = {
                    message: "Please select a file to upload",
                    color: "red",
                    show: true,
                    };
                }
                else if(res == false){
                    this.snackbar = true;
                    this.snackbar = {
                    message: "Something went wrong! please try again",
                    color: "red",
                    show: true,
                    };
                }
                else if(res == true){
                    this.snackbar = true;
                    this.snackbar = {
                    message: "File uploaded successfully!",
                    color: "green",
                    show: true,
                    };
                this.updatedDetailsForScreen = sessionStorage.getItem("updatedDetailsForScreen");      
                this.documentCodelist();
                }
                else if(res == 'completed'){
                    this.snackbar = true;
                    this.snackbar = {
                    message: "File downloaded successfully!",
                    color: "green",
                    show: true,
                    };
              }
              else if(res == 'nofile'){
                    this.snackbar = true;
                    this.snackbar = {
                    message: "No file exists! please upload a new file first!!!",
                    color: "orange",
                    show: true,
                    };
                }
                else if(res == 'discard'){
                this.snackbar = true;
                this.snackbar = {
                message: "File changes discarded successfully!",
                color: "orange",
                show: true,
                };
               }
              },
           }
    }
</script>

<style>
    .justify-content-end {
        justify-content: Flex-end;
    }
   .resize {
       padding: 0px !important;
    }
  .justify-content-center {
        display: flex;
        justify-content: center;
   }
</style>