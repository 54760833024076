<template>
  <v-app>
    <template v-if="!$route.meta.allowAnonymous">
      <v-app-bar app color="primary" dark elevation="0">
        <!-- <v-app-bar-nav-icon @click.stop="sidebarMenu = !sidebarMenu"></v-app-bar-nav-icon> -->
        <v-spacer></v-spacer>
        <Header></Header>
      </v-app-bar>
      <Navigation></Navigation>
      <v-main>
        <v-container fluid>
          <v-row class="fill-height">
            <v-col>
              <transition name="fade">
                <router-view></router-view>
              </transition>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
      <v-footer>
        ...
      </v-footer>
    </template>
    <template v-else>
      <v-main>
        <router-view />
      </v-main>
    </template>
  </v-app>
</template>

<script>
  import Header from './components/common/header.vue'
  import Navigation from './components/common/sidebar.vue'
  // import router from '../../router'



  export default {
    name: 'App',

    components: {
      Header,
      Navigation
    },

    data() {
      return {
        sidebarMenu: true,
        toggleMini: false,
        dialog: false,


      }
      //
    },
    computed: {
      mini() {
        return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
      },
    },
    created() {
      if (location.hostname !== 'localhost' && (location.protocol === 'http' || location.protocol === 'http:')) {
        window.location.href = location.href.replace('http', 'https');
        return;
      }
    }
  };
</script>