
import httpCommodity from '../store/http-commodity';
class MasterScreenService {
    getCDScodeLists() {
        return httpCommodity.get(`/v1/cds/cdscodelists`);
    }
    getDocumentStatusCode() {
        return httpCommodity.get(`/v1/cds/documentstatuscode`);
    }
    getAdditionalProcedureCode() {
        return httpCommodity.get(`/v1/cds/additionalprocedurecodes`);
    }
    getFourDigitProcedureCode() {
        return httpCommodity.get(`/v1/cds/procedurecodes`);
    }
    getUnLocationCode() {
        return httpCommodity.get(`/v1/cds/unlocationcode`);
    }
    getImportExchangeRates(month, year) {
        return httpCommodity.get(`/v1/cds/exchangerates?month=${month}&year=${year}`);
    }
    getTradeBlocsCode() {
        return httpCommodity.get(`/v1/cds/tradeblocs`);
    }
    getMasterCountries() {
        return httpCommodity.get(`/v1/cds/mastercountry`);
    }
    getIncoTerms() {
        return httpCommodity.get(`/v1/cds/incoterms`);
    }
    getFreightLocations() {
        return httpCommodity.get(`/v1/cds/freightlocationcodes`);
    }
    getSupervisingCustomCodes() {
        return httpCommodity.get(`/v1/cds/supervisingcustomsoffice`);
    }
    getUKCustomsCode() {
        return httpCommodity.get(`/v1/cds/ukcustomsofficecodes`);
    }
    getPackageTypes() {
        return httpCommodity.get(`/v1/cds/packagetypecodes`);
    }
    getNatureOfTransactions() {
        return httpCommodity.get(`/v1/cds/masternatureoftranscation`);
    }
    getBorderControlPosts() {
        return httpCommodity.get(`/v1/cds/bordercontrolposts`);
    }
    getGoodsLocationCodes() {
        return httpCommodity.get(`/v1/cds/goodslocationcodes`);
    }
    getTransportMethodsPayment() {
        return httpCommodity.get(`/v1/cds/transportmethodofpayment`);
    }
    getModesOfTransport() {
        return httpCommodity.get(`/v1/cds/modeoftransport`);
    }
    getIdentityTypesOfMeansOfTransport() {
        return httpCommodity.get(`/v1/cds/identitytypesofmeansoftransport`);
    }
    getDocumentCode() {
        return httpCommodity.get(`/v1/cds/documentcode`);
    }
    getConditionCode() {
        return httpCommodity.get(`/v1/cds/conditioncode`);
    }
    getTaxTypes() {
        return httpCommodity.get(`/v1/cds/taxtypes`);
    }
    getHsclookupData(HsclookupCode) {
        return httpCommodity.get(`/v1/hsc_lookup?q=${HsclookupCode}`);
    }
    getPartySubRole() {
        return httpCommodity.get(`/v1/cds/partysubroletype`);
    }
    getVatPartySubRole() {
        return httpCommodity.get(`/v1/cds/vatpartysubroletype`);
    }
    getMeasurementUnitCode() {
        return httpCommodity.get(`/v1/cds/measurementunitcode`);
    }
    getStatementCode() {
        return httpCommodity.get(`/v1/cds/statementcode`);
    }
    getAuthorisationTypeCode() {
        return httpCommodity.get(`/v1/cds/authorisationtypecode`);
    }
    getCustomsOffice(filterByCountry) {
        return httpCommodity.post(`/v1/cds/customsoffice/byfilter`, filterByCountry);
    }
    uploadWCOCodeList(file) {
        let formData = new FormData();

        formData.append("file", file);
    
        return httpCommodity.post("/v1/cds/wcocodelist/fileimport", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      }
    getWCOCodeList() {
         return httpCommodity.get(`/v1/cds/wcocodelist`);
    }
    uploadDeclarationCategoryList(file) {
        let formData = new FormData();

        formData.append("file", file);
    
        return httpCommodity.post("/v1/cds/declarationcategory/fileimport", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
      }
    getdeclarationCategoryList() {
         return httpCommodity.get(`/v1/cds/declarationcategory`);
    }
    getairPortList() {
        return httpCommodity.get(`/v1/cds/airportcode/list`);
    }
    uploadairPortList(file) {
        let formData = new FormData();

        formData.append("file", file);

        return httpCommodity.post("/v1/cds/airportcode/fileimport", formData, {
            headers: {
            "Content-Type": "multipart/form-data"
            }
        });
    }
    customDownload(fileKey,fileName) {
       return httpCommodity.get(`/v1/cds/cdscodelists/fileuri?FileKey=${fileKey}&FileName=${fileName}`);
    }


    customUpload(file,key) {
        let formData = new FormData();

        formData.append("file", file);
        return httpCommodity.post(`/v1/cds/cdscodelists/compare/${key}`, formData, {
            headers: {
            "Content-Type": "multipart/form-data"
            }
        });
    }

    disacrdFileChanges(key){
      const filekey = encodeURIComponent(key);
      console.log(filekey);
       return httpCommodity.delete(`/v1/cds/cdscodelists/discard?fileKey=${filekey}`);
    }

    saveFileChanges( activeRouterKey,formData){
     
       return httpCommodity.post(`/v1/cds/cdscodelists/confirm/${activeRouterKey}`, formData);
    }
}
export default new MasterScreenService();