// import http from '../store/http-common';
import httpInstance from    '../store/interceptor';

class PermissionManagementService {
    savePermission(data) {
        return httpInstance.post('/v1/accounts/permission', data);
    }
    editPermission(data) {
        return httpInstance.put('/v1/accounts/permission', data);
    }
    getAllPermission() {
        return httpInstance.get('/v1/accounts/permission');
    }
    deletePermission(id) {
        return httpInstance.delete('/v1/accounts/permission/' + id);
    }
    getPermissionById(id) {
        return httpInstance.get('/v1/accounts/permission/' + id);
    }
}
export default new PermissionManagementService();