<template>
  <v-container fill-height class="auth-width">
    <v-card class="d-inline-block mx-auto">
      <v-row class="text-center" justify="center">
        <v-col cols="12">
          <v-img
            :src="require('../assets/p2d_logo.png')"
            class="my-3 mx-auto"
            contain
            width="400"
          />
        </v-col>
        <v-col cols="10">
          <form @submit.prevent="submit">
            <v-text-field
              v-model="groupcode"
              :error-messages="groupcodeErrors"
              label="Group Code"
              placeholder="Enter Group Code"
              :maxlength="max"
              required
              outlined
              @input="$v.groupcode.$touch()"
              @blur="$v.groupcode.$touch()"
              class="code"
            />

            <v-text-field
              v-model="emailaddress"
              :error-messages="emailAddressErrors"
              label="Email Address"
              placeholder="Enter Email Address"
              required
              outlined
              @input="$v.emailaddress.$touch()"
              @blur="$v.emailaddress.$touch()"
            />
            <v-select
              :items="site_type_List"
              v-model="site_type"
              :error-messages="site_typeErrors"
              label="Account Type"
              placeholder="Account type is required"
              required
              outlined
              @input="$v.site_type.$touch()"
              @blur="$v.site_type.$touch()"
            > 
            </v-select>
            <v-select
              :items="supplyChainList"
              v-model="supplychain"
              :error-messages="supplyChainErrors"
              item-text="name"
              item-value="id"
              label="Supply Chain"
              placeholder="Supply Chain is required"
              @change="changeSupplyChain(supplychain)"
              required              
              outlined
              @input="$v.supplychain.$touch()"
              @blur="$v.supplychain.$touch()"
            >
            </v-select>
            <v-select
              :items="tariffList"
              v-model="tariffId"
              :error-messages="tariffIdErrors"
              item-text="name"
              item-value="id"
              label="Tariff"
              placeholder="Tariff is required"
              required
              outlined
              @input="$v.tariffId.$touch()"
              @blur="$v.tariffId.$touch()"
            >
            </v-select>
            <v-row>
              <v-col cols="12">
                <v-btn @click="submit" rounded color="primary"
                  >Send Invite</v-btn
                >
                <!-- &nbsp;
                <v-btn @click="backToInvite" rounded>Back</v-btn> -->
              </v-col>
              <v-col cols="12">
                <v-snackbar
                  :color="snackbar.color"
                  v-model="snackbar.show"
                  top
                  right
                  rounded
                >
                  {{ snackbar.message }}
                  <template v-slot:action="{ attrs }">
                    <v-btn
                      color="white"
                      text
                      v-bind="attrs"
                      @click="closeSnackbar"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-snackbar>
              </v-col>
            </v-row>
          </form>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import AccountService from "../services/accountservices";
import router from "../router";
export default {
  tariffList: "",
  supplyChainList: "",
  name: "Invite",
  mixins: [validationMixin],
  data() {
    return {
      groupcode: "",
      tariffId: "",
      max: 4,
      emailaddress: "",
      supplychain: "",
      tariffList: [],
      supplyChainList: [],
      site_type: "",
      site_type_List: ["Retailer","Supplier","Logistics","Client"],
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
    };
  },
  validations: {
    groupcode: { required, minLength: minLength(4), maxLength: maxLength(4) },
    tariffId: { required },
    emailaddress: { required, email },
    supplychain: { required },
    site_type: { required },
  },
  computed: {
    groupcodeErrors() {
      const errors = [];
      if (!this.$v.groupcode.$dirty) return errors;
      !this.$v.groupcode.minLength &&
        errors.push("Group Code must be 4 characters long");
      !this.$v.groupcode.maxLength &&
        errors.push("Group Code must be 4 characters long");
      this.$v.groupcode.$model == "0000" &&
        errors.push("Group Code should not be 0000");
      !this.$v.groupcode.required && errors.push("Group Code is required");
      return errors;
    },
    tariffIdErrors() {
      const errors = [];
      if (!this.$v.tariffId.$dirty) return errors;
      !this.$v.tariffId.required && errors.push("Tariff required");
      return errors;
    },
    emailAddressErrors() {
      const errors = [];
      if (!this.$v.emailaddress.$dirty) return errors;
      !this.$v.emailaddress.required && errors.push("E-mail is required");
      !this.$v.emailaddress.email && errors.push("Must be valid e-mail");
      return errors;
    },
    supplyChainErrors() {
      const errors = [];
      if (!this.$v.supplychain.$dirty) return errors;
      !this.$v.supplychain.required && errors.push("Supply Chain is required");
      return errors;
    },
    site_typeErrors() {
      const errors = [];
      if (!this.$v.site_type.$dirty) return errors;
      !this.$v.site_type.required && errors.push("Site type is required");
      return errors;
    },
  },
  created() {
    this.getSupplyChain();
  },
  methods: {
    getTariff(value) {
      //  debugger; // eslint-disable-line no-debugger
      if (value == [] || value == null) {
        this.tariffList = [];
      } else {
        this.tariffList = []
        // for(var i = 0; i < value.length; i++){
          AccountService.getTariff(value)
            .then((response) => {
              if (response) {
                for(var i = 0; i < response.data.length; i++){
                this.tariffList.push(response.data[i]);
                }
              }
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = {
                message: "OOPS!SOMETHING WENT WRONG",
                color: "error",
                show: true,
              };
            });
        // }
      }
    },
    getSupplyChain() {
      AccountService.getSupplierChain()
        .then((response) => {
          if (response) {
            this.supplyChainList = response.data;
          }
        })
        .catch((e) => {
          console.log(e);
          this.snackbar = {
            message: "OOPS!SOMETHING WENT WRONG",
            color: "error",
            show: true,
          };
        });
    },
    changeSupplyChain(value) {
      if (value != null || value != []) {
        this.getTariff(value);
      }
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.snackbar = true;
        this.snackbar = {
          message: "Please fill the form correctly",
          color: "error",
          show: true,
        };
      } else {
        var data = {
          group_code: this.groupcode.toUpperCase(),
          email: this.emailaddress,
          supply_chain_id: this.supplychain,
          group_tariff_id: this.tariffId,
          site_type: this.site_type
        };
        AccountService.inviteAccount(data)
          .then((response) => {
            if (response.data.message == "Account name already taken") {
              this.snackbar = {
                message: `The group code "${this.groupcode.toUpperCase()}0000" is already in use. Please choose another account name.`,
                color: "error",
                show: true,
              };
            } else {
              this.snackbar = {
                message: "Success",
                color: "success",
                show: true,
              };
              this.groupcode = "";
              this.tariffId = "";
              this.emailaddress = "";
              this.supplychain = "";
              this.site_type = "";
            }
          })
          .catch((e) => {
            console.log(e);
            if (e.request.status === 400) {
              this.snackbar = {
                message: `The group code "${this.groupcode.toUpperCase()}0000" is already in use. Please choose another account name.`,
                color: "error",
                show: true,
              };
            } else {
              this.snackbar = {
                message: "There was a problem on the server. Please try again.",
                color: "error",
                show: true,
              };
            }
          });
        this.$v.$reset();
      }
    },
    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
    },
    backToInvite() {
      router.push("/userManagement");
    },
  },
};
</script>
<style>
.code input {
  text-transform: uppercase;
}
</style>