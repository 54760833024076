<template lang="html">
    <div>
       <v-toolbar text color="white">
        <v-toolbar-title>PARTY SUB ROLE ({{getData.length}})</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
        <br>
         <v-card-title class="justify-content-end">
            <v-flex md4 pr-2 >
                <v-text-field v-model="search" label="Search"
                append-icon="mdi-magnify" single-line hide-details ></v-text-field>
            </v-flex>
        </v-card-title>
      <v-data-table :headers="headers" :items="getData" 
           :search="search" class="elevation-1">
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-right" >{{ props.item.roleCode }}</td>
            <td class="text-xs-right">{{ props.item.party }}</td>
            <td class="text-xs-right">{{ props.item.description }}</td>
          </tr>
        </template>
<template v-slot:no-data>
          <span>No data found</span>
        </template>
</v-data-table>

</div>
</template>

<script>
    import MasterScreenService from "../../services/master-screenservices"

    export default {
        name: 'PartySubRole',
        data() {
            return {
                search: '',
                getData: [],
                headers: [{
                    text: "Role Code",
                    align: "left",
                    sortable: true,
                    value: "roleCode"
                }, {
                    text: "Party",
                    align: "left",
                    sortable: true,
                    value: "party"
                }, {
                    text: "Description",
                    align: "left",
                    sortable: true,
                    value: "description"
                }],
            }
        },
        computed: {},
        created() {
            MasterScreenService.getPartySubRole().then(response => {
                    this.getData = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        methods: {}
    }
</script>

<style>
    .justify-content-end {
        justify-content: Flex-end;
    }
</style>