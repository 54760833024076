import axios from "axios";
import AccountService  from "../services/accountservices";
import { getInstance } from "../auth/index";

const httpInstance = axios.create({
    baseURL: process.env.VUE_APP_BASEURL,
    headers: {
        "Content-type": "application/json"
    },
    // timeout: 10000,
    params: {} // do not remove this, its added to add params later in the config
});

httpInstance.interceptors.request.use(async (config) => {
    const authService = getInstance();
    const token = await authService.getTokenSilently();
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    let accountId = sessionStorage.getItem("x-account-id");
    if (!accountId && !config.url.startsWith("v1/accounts/account/check")){
        const response = await AccountService.checkAccount('P2DL0000');
        accountId = response.data.account_id;
        sessionStorage.setItem("x-account-id", accountId);
    }
    if (accountId){
        config.headers['X-Account-Id'] = accountId;
    }
    return config;
}, function (error) {
    this.snackbar = {
        message: "OOPS!SOMETHING WENT WRONG",
        color: "error",
        show: true,
    };
    return Promise.reject(error)
})

httpInstance.interceptors.response.use((response) =>{
    return response;
},
(error)=>{
    console.log(error);
    const status = error.response ? error.response.status : null
    if (status == 401) {
        const authService = getInstance();
        authService.logout({ returnTo: document.location.origin });
    }
    return Promise.reject(error);
});

export default httpInstance