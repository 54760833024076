<template lang="html">
    <div>
       <v-toolbar text color="white">
        <v-toolbar-title>FREIGHT LOCATIONS CODES ({{getData.length}})</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
        <br>
         <v-card-title class="justify-content-end">
            <v-flex md4 pr-2 >
                <v-text-field v-model="search" label="Search"
                append-icon="mdi-magnify" single-line hide-details ></v-text-field>
            </v-flex>
        </v-card-title>
      <v-data-table :headers="headers" :items="getData" 
           :search="search" class="elevation-1">
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-right" >{{ props.item.port }}</td>
            <td class="text-xs-right">{{ props.item.freightLocationCode }}</td>
          </tr>
        </template>
<template v-slot:no-data>
          <span>No data found</span>
        </template>
</v-data-table>

</div>
</template>

<script>
    import MasterScreenService from "../../services/master-screenservices"

    export default {
        name: 'FreightLocations',
        data() {
            return {
                search: '',
                getData: [],
                headers: [{
                    text: "Port",
                    align: "left",
                    sortable: true,
                    value: "port"
                }, {
                    text: "Freight location code",
                    align: "left",
                    sortable: true,
                    value: "freightLocationCode"
                }, ],
            }
        },
        computed: {},
        created() {
            MasterScreenService.getFreightLocations().then(response => {
                    this.getData = response.data;
                })
                .catch(e => {
                    console.log(e);
                });
        },

        methods: {}
    }
</script>

<style>
    .justify-content-end {
        justify-content: Flex-end;
    }
</style>