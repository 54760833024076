import httpInstance from '../store/interceptor';


class RequestSealService {
   
    getAllRequestSeals() {
        return httpInstance.get('/v3/seals/get_seal_request_list');
    }

    getGroups(){
        return httpInstance.get('/account/group_account_list');
    }
    getSites(groupcode){
        return httpInstance.get(`account/siteaccount?group_code=${groupcode}`);
    }
   createSeal(data){
    return httpInstance.post('/v3/seals/save_request_seals', data);
}
   deleteSeal(id){
    return httpInstance.delete(`/v3/seals/v3/seals/delete/${id}`);

}
updateSeal(data){
    return httpInstance.post('/v3/seals/update', data);
 
}
}
export default new RequestSealService();