<template>
  <div>
    <v-toolbar text color="white">
      <v-toolbar-title>
        <v-btn v-on:click="sendInvitation" rounded color="primary"
          >Invite</v-btn
        >
      </v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-col cols="3">
        <v-select placeholder="Filter By Status" :items="status" class="filter-status mr-2" item-value="text" @change="filterByStatus($event)">
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-select
          placeholder="Filter By Supply Chain" :items="forFilterSullpChain" item-text="name" item-value="name" v-model="supplyList"
          class="filter-status mr-2 filter-supply" @change="filterBySullpChain($event)"
        >
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-autocomplete style="margin-top: 20px"
            :items="groupCodes" clearable
            item-text="group_code"
            item-value="groupcode"
            placeholder="Enter Group Code"
            class="mb-2 mr-2"
            @change="filterByGroupCode($event)"
        ></v-autocomplete>
      </v-col>
      <v-dialog persistent v-model="dialog" max-width="1000px">
        <template v-slot:activator="{ on }">
          <v-card v-on="on"></v-card>
        </template>
<v-card>
    <form @submit.prevent="updateManageUser">
        <v-card-title class="headline">
            <span>{{ roleDialoghead }}</span>
        </v-card-title>

        <v-card-text>
            <v-container grid-list-md>
                <v-layout wrap>
                    <v-flex xs12 sm6 md6>
                        <v-select :items="supplyChainList" item-text="name" item-value="id" v-model="supplyList" @change="changedState(supplyList)" :error-messages="supplyChainErrors" label="Supply Chain" placeholder="Enter Supply Chain" required outlined @input="$v.supplyList.$touch()"
                            @blur="$v.supplyList.$touch()" multiple>
                        </v-select>
                        <v-text-field v-model="groupcodeedit" label="Group Code" placeholder="Enter Group Code" required outlined @input="$v.emailedit.$touch()" @blur="$v.emailedit.$touch()" disabled />
                    </v-flex>

                    <v-flex xs12 sm6 md6>
                        <v-text-field v-model="emailedit" label="Email" placeholder="Enter Email" outlined @input="$v.groupcodeedit.$touch()" @blur="$v.groupcodeedit.$touch()" disabled />
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text v-on:click="cancel">Cancel</v-btn>
            <v-btn color="blue darken-1" text v-on:click="updateManageUser()">
                Update</v-btn>
        </v-card-actions>
    </form>
</v-card>
</v-dialog>
</v-toolbar>
<v-data-table :headers="headers" :items="dataList" class="elevation-1">
    <template v-slot:item="props">
        <tr @click="handleClick(props.item)">
          <td class="text-xs-right">{{ props.item.supplychain }}</td>
          <td class="text-xs-right">{{ props.item.groupcode }}</td>
          <td class="text-xs-right">{{ props.item.email }}</td>
          <td class="text-xs-right custom-width">
            <p
              v-if="props.item.status == 'invited'"
              class="light status-class mb-0"
            >
              {{ props.item.status }}
            </p>
            <p
              v-if="props.item.status == 'verification pending'"
              class="yellow status-class mb-0"
            >
              {{ props.item.status }}
            </p>
            <p
              v-if="props.item.status == 'verified account'"
              class="green status-class mb-0"
            >
              {{ props.item.status }}
            </p>
          </td>
        </tr>
      </template>
    <template v-slot:no-data>
        <span>No data found</span>
      </template>
</v-data-table>
<v-snackbar :color="snackbar.color" v-model="snackbar.show" top right rounded>
    {{ snackbar.message }}
    <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" v-on:click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
</v-snackbar>
</div>
</template>

<script>
    import {
        validationMixin
    } from "vuelidate";
    import AccountService from "../services/accountservices";
    import {
        required
    } from "vuelidate/lib/validators";
    import router from "../router";
    import GroupSiteManagement from "@/views/group-management/group-site-management";
    export default {
        mixins: [validationMixin],
        supplyChainList: "",

        data() {
            return {
                roleDialoghead: "Group/Site Management",
                snackbar: {
                    show: false,
                    message: null,
                    color: null,
                },
                dataUpdate: {},
                dialog: false,
                role: false,
                submitStatus: null,
                roleData: [],
                dataList: [],
                forFilterSullpChain: [],
                supplyChainList: [],
                supplyedit: [],
                supplyid: [],
                supplyeditArray: [],
                status: ["Verified Account", "Verification Pending", "Invited", "All"],
                supplyList: [],
                emailedit: "",
                groupcodeedit: "",
                statusCheck: "",
                supplyCheck: "",
                groupCodeCheck: "",
                groupCodes: [],
                headers: [{
                    text: "Supply Chain",
                    align: "left",
                    sortable: true,
                    value: "supplychain",
                }, {
                    text: "Group Code",
                    align: "left",
                    sortable: true,
                    value: "groupcode",
                }, {
                    text: "Site Code",
                    align: "left",
                    sortable: true,
                    value: "sitecode",
                }, {
                    text: "Status",
                    align: "left",
                    sortable: true,
                    value: "status",
                }, ],
                // editedItem: {
                //     type: '',
                //     name: '',
                //     description: '',
                // },
            };
        },
        validations: {
            // name: {
            //   required,
            // },
            // type: {
            //   required,
            // },
            supplyList: {
                required,
            },
        },

        computed: {
            supplyChainErrors() {
                const errors = [];
                if (!this.$v.supplyList.$dirty) return errors;
                !this.$v.supplyList.required && errors.push("Supply Chain is required");
                return errors;
            },
        },

        watch: {
            dialog(val) {
                val || this.close();
            },
        },

        created() {
            this.getAllroleswithFilter(this.statusCheck,this.supplyCheck,this.groupCodeCheck);
            this.getSupplyChain();
        },

        methods: {
            sendInvitation() {
                router.push("/invite");
            },
            getAllroleswithFilter(statusCheck,supplyCheck,groupCodeCheck) {
                AccountService.getList(statusCheck, supplyCheck, groupCodeCheck)
                    .then((response) => {
                        if (response) {
                            this.dataList = response.data;
                            this.groupCodes = this.dataList.map(x => x.groupcode);
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            },
            filterByStatus(val) {
                this.statusCheck = this.nullchecker(val.toLowerCase());
                this.getAllroleswithFilter(this.statusCheck,this.supplyCheck,this.groupCodeCheck);
               
            },
            fetchsupplyChainData(statusCheck,supplyCheck,groupCodeCheck) {
            return AccountService.getList(statusCheck, supplyCheck, groupCodeCheck)
            },

            async filterBySullpChain(val) {
                this.supplyCheck = this.nullchecker(val);
                // this.getAllroleswithFilter(this.statusCheck,this.supplyCheck,this.groupCodeCheck);
                const getgroupcodeby = await this.fetchsupplyChainData(this.statusCheck,this.supplyCheck,'');
                this.groupCodes = getgroupcodeby.data.map(x => x.groupcode);
                let suplyCode = this.groupCodes.includes(this.supplyCheck)
                this.groupCodeCheck = suplyCode ? this.groupCodeCheck : '';
                const getcompleteData = await this.fetchsupplyChainData(this.statusCheck,this.supplyCheck,this.groupCodeCheck);
                this.dataList = getcompleteData.data;
            },
          filterByGroupCode(val) {
            this.groupCodeCheck = this.nullchecker(val);
            this.getAllroleswithFilter(this.statusCheck,this.supplyCheck,this.groupCodeCheck);
              
          },

          nullchecker(val){
            return (val === null || val.toLowerCase() === "all") ? '': val;
          },
            getSupplyChain() {
                AccountService.getSupplierChain()
                    .then((response) => {
                        if (response) {
                            this.supplyChainList = response.data;
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.snackbar = {
                            message: "OOPS!SOMETHING WENT WRONG",
                            color: "error",
                            show: true,
                        };
                    });
                    this.forFilterSullpChain.push({"name":"M&S"});
                    this.forFilterSullpChain.push({"name":"P2DL"});
                    this.forFilterSullpChain.push({"name":"Costa"});
                    this.forFilterSullpChain.push({"name":"XPO"});
                    this.forFilterSullpChain.push({"name":"All"});
            },

            editrole(id) {
                this.editId = id;
                this.addroleint = false;
                this.editroleint = true;
                this.dialog = true;
            },
            closeSnackbar() {
                this.snackbar = {
                    show: false,
                };
            },
            editItem(item) {
                this.supplyedit.push(item.supplychain);
                this.supplyeditArray = this.supplyedit[0].split(",");
                for (var i = 0; i < this.supplyeditArray.length; i++) {
                    var idOfData = this.supplyChainList.filter(
                        (x) => x.name == this.supplyeditArray[i].trim()
                    );
                    if (idOfData.length == 0) {
                        idOfData = this.supplyChainList.filter(
                            (x) => x.id == this.supplyeditArray[i].trim()
                        );
                    }
                    this.supplyList.push(idOfData[0].id);
                }
                this.supplyid.push(item.inviteid);
                this.emailedit = item.email;
                this.groupcodeedit = item.groupcode;
                this.accountEdit = item.accountid;
                this.dialog = true;
            },

            close() {
                this.dialog = false;
                this.supplyedit = [];
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            save() {
                if (this.editedIndex > -1) {
                    Object.assign(this.roles[this.editedIndex], this.editedItem);
                } else {
                    this.roles.push(this.editedItem);
                }
                this.dialog = false;
            },
            cancel() {
                this.clear();
                this.dialog = false;
                this.role = false;
                this.supplyList = [];
            },
            clear() {
                this.$v.$reset();
                this.name = "";
                this.type = "";
                this.description = "";
            },
            assign() {
                this.role = false;
            },
            updateManageUser() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.snackbar = true;
                    this.snackbar = {
                        message: "Please fill the form correctly",
                        color: "error",
                        show: true,
                    };
                } else {
                    this.dialog = false;
                    this.dataUpdate = {
                        group_code: this.groupcodeedit.toUpperCase(),
                        email: this.emailedit,
                        supply_chain_id: this.supplyList,
                        account_id: this.accountEdit,
                    };
                    AccountService.updateSupplyChain(this.dataUpdate)
                        .then((response) => {
                            if (response) {
                                this.getAllroles();
                                this.snackbar = {
                                    message: "Successfully Updated",
                                    color: "success",
                                    show: true,
                                };
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            this.snackbar = {
                                message: "OOPS!SOMETHING WENT WRONG",
                                color: "error",
                                show: true,
                            };
                        });
                    this.supplyList = [];
                }
            },
            changedState: function(value) {
                this.supplyList = [];
                for (var i = 0; i < value.length; i++) {
                    debugger; // eslint-disable-line no-debugger
                    this.supplyList.push(value[i]);
                }
            },
            revokeInviteItem(item) {
                var data = {
                    group_invite_id: item.inviteid,
                };

                AccountService.revokeInvite(data)
                    .then((response) => {
                        if (response) {
                            this.getAllroles();
                            this.snackbar = {
                                message: "Successfully Revoked",
                                color: "success",
                                show: true,
                            };
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.snackbar = {
                            message: "OOPS!SOMETHING WENT WRONG",
                            color: "error",
                            show: true,
                        };
                    });
            },
            resendInviteItem(item) {
                var data = {
                    group_invite_id: item.inviteid,
                };
                AccountService.resendInvite(data)
                    .then((response) => {
                        if (response) {
                            this.getAllroles();
                            this.snackbar = {
                                message: "Successfully Resend",
                                color: "success",
                                show: true,
                            };
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.snackbar = {
                            message: "OOPS!SOMETHING WENT WRONG",
                            color: "error",
                            show: true,
                        };
                    });
            },
            resendVerification(item) {
                var details = {
                    group_invite_id: item.inviteid,
                    account_id: item.accountid,
                    user_id: item.userid,
                };
                AccountService.resendverificationMail(details)
                    .then((response) => {
                        if (response) {
                            this.getAllroles();
                            this.snackbar = {
                                message: "Successfully Resend Verification Mail",
                                color: "success",
                                show: true,
                            };
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        this.snackbar = {
                            message: "OOPS!SOMETHING WENT WRONG",
                            color: "error",
                            show: true,
                        };
                    });
            },
            //  trigger a mutation, or dispatch an action
          handleClick(item) {
            console.log('row clicked ');
            console.log(item);
            this.$router.push({
                name: GroupSiteManagement,
                params: {
                    id: item.id,
                  _item : item
                },
            });
          },
        },
    };
</script>
<style>
    .filter-status .v-text-field__details {
        display: none;
    }
    
    .light {
        background-color: #ffba46;
    }
    
    .green {
        background-color: green;
    }
    
    .yellow {
        background-color: yellow;
    }
    
    .status-class {
        padding: 10px;
        border-radius: 50px;
        color: #fff;
        width: 150px;
        text-align: center;
        text-transform: capitalize;
    }
    
    .custom-width {}
    
    .layout.wrap {
        align-items: flex-start;
    }
</style>
