<template>
   <div>
    <v-toolbar text color="white">
      <v-toolbar-title>Account Configuration</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-autocomplete
      :items="entries"
      item-text="account_name"
      item-value="id"
      placeholder="Enter Group Account"
      class="mb-2 mr-2"
      @change="filterByGroupAccount($event)"
      ></v-autocomplete>

      
    </v-toolbar>


    <v-dialog persistent v-model="dialog" max-width="1000px">
        <v-card>
          <form @submit.prevent="userCreateForm">
            <v-card-title class="headline">
              <span>Edit Account</span>
            </v-card-title>
            <v-card-text v-if="edituserint == true">
              <v-container grid-list-md>
                <v-layout wrap>
                  <!-- <v-card-text color="blue darken-1"
                    ><v-icon> mdi-human</v-icon> PERSONAL INFO</v-card-text
                  > -->
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="accountNamEdit" label="Account Name"  readonly/>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="sitenamEdit" label="Site Name" readonly/>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="groupcodEdit" label="Group code" readonly/>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="sitecodeEdit" label="Site code" readonly/>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                  <v-select
                    :items="modules"
                     outlined
                     v-model="selectedModule"
                     label="Account Module"
                     multiple
                    @change="getSelectedModule($event)"
                    item-text="displayText"
                    item-value="key"
                  ></v-select>
                  </v-flex>
                </v-layout>
              </v-container>
               
                </v-layout>
              </v-container>
            </v-card-text>
              <v-card-actions v-if="edituserint == true">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="updateAccount()">Update</v-btn>
              <v-btn color="blue darken-1" text @click="cancel">Cancel</v-btn>
            </v-card-actions>
          </form>
        </v-card>
      </v-dialog>

    <v-data-table :headers="headers" :items="userlist" class="elevation-1">
      <template v-slot:item="props">
        <tr>
          <td class="text-xs-right">
            {{ props.item.account_name}}
          </td>
          <td class="text-xs-right">
            {{ props.item.site_name }}
          </td>
          <td class="px-0">
            <v-icon class="mr-2" @click="editAccount(props.item)">
              mdi-account-edit
            </v-icon>
          </td>
        </tr>
        <v-snackbar
          :color="snackbar.color"
          v-model="snackbar.show"
          top
          right
          rounded
        >
          {{ snackbar.message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>

  </div>
</template>

<script>
import AccountService from "../services/accountservices"
import UserManagementService from "../services/user-managementservices";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  name: "AccountConfiguration",
   mixins: [validationMixin],
  data() {
    return {
    edituserint : false,
    dialog: false,
    account_idEdit : "",
    accountNamEdit: "",
    groupcodEdit: "",
    modules: [
      { key: 'cds', displayText: 'CDS' },
      { key: 'sps', displayText: 'SPS' },
      { key: 'trackandtrace', displayText: 'Track & Trace' },
    ],
    sitecodeEdit: "",
    sitenamEdit: "",
    selectedModule: [],
    getselecteId:"",
    entries: [],
    userlist:[],
        headers: [
        { text: "Account Name", align: "left", sortable: true, value: "account_name" },
        {
          text: "Site Name",
          align: "left",
          sortable: true,
          value: "site_name",
        },
        { text: "Action", align: "left", sortable: false, value: "" },
      ],
        snackbar: {
        show: false,
        message: null,
        color: null,
      },
    };
  },
  validations: {
    modules:{ required },
  },


  computed: {
  },

  watch: {
   
  },
  created() {
      this.getGroupAccounts();
    
  },
  methods: {

      getGroupAccounts() {
      UserManagementService.getgroupaccounts()
        .then((response) => {
           this.entries = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
     },

     getsiteDetails(event) {
      this.getselecteId = event;
      AccountService.getsiteDetails(event)
     
        .then((response) => {
          this.userlist = [];
          this.userlist = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    filterByGroupAccount(event){
    this.getsiteDetails(event);
    },

    editAccount(event){
       this.reset();
       this.edituserint = true;
       this.dialog = true;
       if(event){
         this.account_idEdit = event.account_id;
         this.accountNamEdit = event.account_name;
         this.sitenamEdit = event.site_name;
         this.sitecodeEdit = event.site_code;
         this.groupcodEdit = event.group_code;
         this.selectedModule = event.modules;
       }
    },

    getSelectedModule(event){
      this.selectedModule = event;
    },

       updateAccount() {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.snackbar = true;
          this.snackbar = {
            message: "Please fill the form correctly",
            color: "error",
            show: true,
          };
        } else {
          var updateAccounts = {
              "account_id": this.account_idEdit,
              "modules": this.selectedModule
          };
          AccountService.updateAccount(updateAccounts)
            .then((response) => {
              if (response) {
                this.getsiteDetails(this.getselecteId);
                this.snackbar = {
                  message: "Account updated succesfully",
                  color: "success",
                  show: true,
                };
                this.dialog = false;
              }
              this.reset();
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = true;

              this.snackbar = {
                message: "OOPS!SOMETHING WENT WRONG",
                color: "error",
                show: true,
              };
            });
        }
    },

     cancel() {
      this.dialog = false;
      this.edituserint = false;
    },

    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
    },

    reset(){
       this.account_idEdit = '';
       this.accountNamEdit = '';
       this.sitenamEdit = '';
       this.sitecodeEdit = '';
       this.groupcodEdit = '';
       this.selectedModule = [];
    }
  },
};
</script>