<template>
  <div v-if="account">
    <v-snackbar
        :color="snackbar.color"
        v-model="snackbar.show"
        top
        right
        rounded
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="closeSnackbar">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-spacer></v-spacer>
    <v-card>
      <v-card-title class="headline">
        <b>ACCOUNT ({{account.group_code}})</b>
      </v-card-title>
    <v-spacer></v-spacer>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md6>
              <v-select :items="supplyChainList" item-text="name" item-value="id" v-model="supplyChains" label="Supply Chain" placeholder="Enter Supply Chain" multiple required outlined>
              </v-select>
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.group_code" label="Group Code" placeholder="Enter Group Code" outlined disabled />
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.trading_name" label="Trading Name" placeholder="Enter Trading Name" disabled outlined  />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.account_type" label="Account Type" outlined disabled />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.company" label="Company" placeholder="Company" outlined  />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.site_name" label="Site Name" placeholder="Site Name" outlined />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.emergency_phone_number" label="Emergency Phone Number" placeholder="Not Set" outlined  />
            </v-flex>

            <v-flex xs12 sm6 md6>
              <v-text-field v-model="account.site_type" label="Site Type" placeholder="Site Type" outlined disabled />
            </v-flex>
            <v-flex xs12 sm6 md6>
              <v-select
                  :items="modules"
                  outlined
                  v-model="selectedModule"
                  label="Account Module"
                  multiple
                  @change="getSelectedModule($event)"
                  item-text="displayText"
                  item-value="key"
              ></v-select>
            </v-flex>


          </v-layout>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text v-on:click="update()">
          Update</v-btn>
      </v-card-actions>
    </v-card>
    <v-spacer></v-spacer>
    <v-card v-if="userlist.length > 0" style="margin-top: 10px">
      <v-card-title> User List</v-card-title>
    <v-data-table :headers="headers" :items="userlist" class="elevation-1">
      <template v-slot:item="props">
        <tr @click="onClick(props.item)">
          <td class="text-xs-right">
            {{ props.item.first_name +'                    '+ props.item.last_name}} <span v-if="props.item.is_support_access"> ( Support User ) </span>
          </td>
          <td class="text-xs-right">
            {{ props.item.email}}
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
    </v-card>
    <v-spacer></v-spacer>
    <v-card v-if="siteAccounts.length > 0" style="margin-top: 10px">
      <v-card-title> Site List</v-card-title>
    <v-data-table :headers="siteheaders" :items="siteAccounts" class="elevation-1">
      <template v-slot:item="props">
        <tr @click="onClickSiteAccount(props.item)">
          <td class="text-xs-right">
            {{ props.item.site_code}}
          </td>
          <td class="text-xs-right">
            {{ props.item.site_name}}
          </td>
        </tr>
      </template>
      <template v-slot:no-data>
        <span>No data found</span>
      </template>
    </v-data-table>
    </v-card>
  </div>
</template>
<script>
import UserManagementService from "../../services/user-managementservices";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AccountService from "../../services/accountservices";

// minLength
export default {
  mixins: [validationMixin],
  data() {
    return {
      modules: [
        { key: 'cds', displayText: 'CDS' },
        { key: 'sps', displayText: 'SPS' },
        { key: 'trackandtrace', displayText: 'Track & Trace' },
      ],
      account: null,
      supplyChainList: [],
      supplyedit: [],
      siteAccounts: [],
      supplyeditArray: [],
      supplyid: [],
      supplyChains: [],
      entries:[],
      userlist:[],
      getAllroles:[],
      selectedModule: [],
      role: [],
      user_id:"",
      account_id: "",
      name: "",
      userDialoghead: "",
      getData: [],
      siteId: "",
      title: "",
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      phone: "",
      company: "",
      edituserint: false,
      currentUser: null,
      account_email_map: null,
      snackbar: {
        show: false,
        message: null,
        color: null,
      },
      dialog: false,
      user: false,
      selected_user: null,
      accountsforuser : [],
      item : null,
      headers: [
        { text: "User", align: "left", sortable: true, value: "" },
        { text: "Email", align: "left", sortable: true, value: "" },
      ],
      siteheaders: [
        { text: "Site Code", align: "left", sortable: true, value: "site_code" },
        { text: "Site Name", align: "left", sortable: true, value: "site_name" },
      ],
    };
  },
  validations: {
    role:{ required },
    supplyChains: {required},
    modules:{ required },
  },
  computed: {
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    if (!this.$router.currentRoute.params._item) {
      //leave page
      this.$router.push("/ManageGroups");
    }
    this.item = this.$router.currentRoute.params._item;
    console.log(this.item);
    if (this.item.account_type){
      if(this.item.account_type === "GroupAccount"){
        console.log("Group Account");
        this.getSitesForGroupAccount();
      }
    }
    AccountService.getAccountConfigurations(this.item.accountid)
        .then((response) => {
          this.selectedModule = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    this.getUserRoles();
    this.getEmails();
    this.getSupplyChain();
  },
  methods: {
    getSitesForGroupAccount(){
      AccountService.getSiteAccountsForGroup(this.account.id).then((response) => {
        console.log(response.data);
        //this.siteAccounts = response.data.filter((item) => item.account_type === "SiteAccount");
        //console.log(this.siteAccounts);
        this.siteAccounts = response.data;
      });
    },
    getEmails(){
      UserManagementService.getEmails().then((response) => {
        this.entries = response.data;
      });
    },
   getUserRoles(){
     UserManagementService.getAllRoles()
        .then((response) => {
          this.getAllroles = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
   },
    filterByEmail(event){
      UserManagementService.getUserByEmail(event)
        .then((response) => {
          this.selected_user = response.data[0];
          console.log('USER');
          console.log(this.selected_user);
          this.getMappedAccountsForUser(this.selected_user.id);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getMappedAccountsForUser(user_id){
      UserManagementService.getMappedAccountsForUser(user_id)
        .then((response) => {
          this.accountsforuser = response.data;
          console.log('ACCOUNTS');
          console.log(this.accountsforuser);
        }).catch((e) => {
          console.log(e);
        });
    },
    userCreateForm() {
      this.$v.$touch();
      if (this.$v.role.$invalid) {
        this.snackbar = true;
        this.snackbar = {
          message: "Please fill the form correctly",
          color: "error",
          show: true,
        };
      } else {
        var userEditData = {
            "user_id": this.selected_user.id,
            "account_id": this.account_id,
            "roles": this.role
        };
        UserManagementService.saveUser(userEditData)
          .then((response) => {
            if (response) {
              console.log(response);
              this.snackbar = {
                message: "User role updated succesfully",
                color: "success",
                show: true,
              };
            }

          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      }
    },
    closeSnackbar() {
      this.snackbar = {
        show: false,
      };
    },
    getSelectedModule(event){
      this.selectedModule = event;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    cancel() {
      this.clear();
      this.dialog = false;
      this.user = false;
      this.$router.push("/ManageGroups");
    },
    clear() {
      this.$v.$reset();
      this.first_name = "";
      this.last_name = "";
      this.username = "";
      this.title = "";
      this.role = [];
      this.email = "";
      this.phone = "";
      this.user_id = "";
      this.account_id = ""; 
    },
    assign() {
      this.user = false;
    },

    getSupplyChain() {
      AccountService.getSupplierChain()
          .then((response) => {
            if (response) {
              this.supplyChainList = response.data;
              this.load();
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
    },
    load() {
      console.log('load')
      var item = this.item;
      console.log('users');
      UserManagementService.getUsersforAccount(item.accountid)
          .then((response) => {
            if (response) {
              this.userlist = response.data;
              console.log(this.userlist);
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      AccountService.getAccountById(item.accountid)
          .then((response) => {
            if (response) {
              this.account = response.data;
              this.account_id = this.account.id;
              console.log(response.data);
              AccountService.getSupplyChains(this.account.id, this.account.group_code)
                  .then((response) => {
                    if (response) {
                      console.log(response.data);
                      this.supplyChains = response.data;
                      console.log('SUPPLY CHAIN')
                      console.log(this.supplyChains);
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    this.snackbar = {
                      message: "OOPS!SOMETHING WENT WRONG",
                      color: "error",
                      show: true,
                    };
                  });
              this.getSitesForGroupAccount();
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
      this.supplyedit.push(item.supplychain);
      this.supplyeditArray = this.supplyedit[0].split(",");
      for (var i = 0; i < this.supplyeditArray.length; i++) {
        var idOfData = this.supplyChainList.filter(
            (x) => x.name == this.supplyeditArray[i].trim()
        );
        if (idOfData.length == 0) {
          idOfData = this.supplyChainList.filter(
              (x) => x.id == this.supplyeditArray[i].trim()
          );
        }
        this.supplyChains.push(idOfData[0].id);
      }
      this.supplyid.push(item.inviteid);
      this.dialog = true;
    },
  onClick(user){
      console.log('clicked');
      console.log(user);
    this.$router.push({
      name: 'userManagement',
      params: {
        user:user,
      },
    });
  }, onClickSiteAccount(site){
      console.log('clicked');
      console.log(site);
      this.$router.push({
        name: 'siteAccountManagement',
        params: {
          site: site,
        },
      });
    },
    updateAccountConfig(){
      var updateAccounts = {
        "account_id": this.item.accountid,
        "modules": this.selectedModule
      };
      AccountService.updateAccount(updateAccounts)
          .then((response) => {
            if (response) {
              this.snackbar = {
                message: "Account updated succesfully",
                color: "success",
                show: true,
              };
              this.dialog = false;
            }
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;

            this.snackbar = {
              message: "OOPS!SOMETHING WENT WRONG",
              color: "error",
              show: true,
            };
          });
    },
    update(){
      if (this.$v.supplyChains.$invalid) {
        this.snackbar = true;
        this.snackbar = {
          message: "Please fill the form correctly",
          color: "error",
          show: true,
        };
      } else {  

        let supplyChainIds = [];
        this.supplyChains.forEach(element => {
          if(element.id){
          supplyChainIds.push(element.id);
          }
          else{
          supplyChainIds.push(element);
          }
        });


        var data = {
          "account_id": this.account.id,
          "company": this.account.company,
          "site_name": this.account.site_name,
          "emergency_phone_number": this.account.emergency_phone_number,
          "main_contact_id": this.account.main_contact_id,
          "supply_chain_ids": supplyChainIds,
        };


        AccountService.updateGroupAccount(this.account.id, data)
            .then((response) => {
              if (response) {
                AccountService.getAccountById(this.account.id).then((response) => {
                  if (response) {
                    this.account = response.data;
                    console.log(response.data);
                    this.updateAccountConfig();
                  }
                });
              }
            })
            .catch((e) => {
              console.log(e);
              this.snackbar = true;
              this.snackbar = {
                message: "OOPS!SOMETHING WENT WRONG",
                color: "error",
                show: true,
              };
            });
      }
    },
    }
};
</script>
