import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from './../views/dashboard.vue';
import Profile from './../views/profile.vue';
import User from './../views/user.vue';
import Group from './../views/group.vue';
import Invite from './../views/invite.vue';
import DocumentStatusCode from './../views/master-screen/document-status-code.vue'
import AdditionalProcedureCode from './../views/master-screen/additional-procedure-code.vue'
import FourDigitProcedureCode from './../views/master-screen/four-digit-procedure-code.vue'
import RoleManagement from './../views/role_management.vue';
import modulePermissons from './../views/user-management/modulePermissons.vue';
import userManagement from './../views/user-management/userManagement.vue';
import AccountConfiguration from './../views/account-configuration.vue'
// import userdetail from './../views/user-detail/user-detail.vue';
import AccountDetails from './../views/account-details.vue';
import RequestSeal from './../views/request_seal.vue';
import ExchangeRates from './../views/master-screen/exchangeRates.vue';
import CDSCodeLists from './../views/master-screen/cds-code-lists.vue';
import TradeBlocs from './../views/master-screen/trade-blocs.vue';
import Countries from './../views/master-screen/countries.vue';
import IncoTerms from './../views/master-screen/incoterms.vue';
import FreightLocations from './../views/master-screen/freight-locations.vue';
// import SupervisingCustomsOffices from './../views/master-screen/supervising-customs-offices.vue';
// import UKCustomsOffices from './../views/master-screen/uk-customs-office-screen.vue';
import CustomsOffices from './../views/master-screen/customs-offices.vue';
import PackageTypes from './../views/master-screen/package-types.vue';
import NatureOfTransactions from './../views/master-screen/nature-of-transactions.vue';
import BorderControlPosts from './../views/master-screen/border-control-post.vue';
import GoodsLocations from './../views/master-screen/goods-locations.vue';
import TransportMethodsOfPayment from './../views/master-screen/transport-methods-payment.vue';
import ModesOfTransport from './../views/master-screen/modes-transport.vue';
import IdentityOfMeansOfTransport from './../views/master-screen/identity-means-transport.vue';
import DocumentCode from './../views/master-screen/document-code.vue';
import ConditionCode from './../views/master-screen/condition-code.vue';
import TaxTypes from './../views/master-screen/tax-types.vue';
import PartySubRole from './../views/master-screen/party-sub-role.vue';
import VatPartySubRole from './../views/master-screen/vat-party-sub-role.vue';
import MeasurementUnitCode from './../views/master-screen/measurement-unit-code.vue';
import StatementCode from './../views/master-screen/statement-code.vue';
import UNAndLOCODEGB from './../views/master-screen/un-location-code.vue';
import AuthorizationTypeCode from './../views/master-screen/authorisation-type-code.vue';
import ManageGroups from './../views/managegroups.vue';
import Releasenotes from "@/views/releasenotes";
import WCOCodeList from './../views/master-screen/wco-code-list.vue';
import DeclarationCategories from './../views/master-screen/declaration-categories.vue';
import AuthGuard from "../auth/auth-guard-service";
import groupSiteManagement from "@/views/group-management/group-site-management";
import siteAccountManagement from "@/views/group-management/site-account-management";
import AirportDetails from "@/views/master-screen/airportDetails";
import Supplychains from "@/views/supplychains";

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: {
            name: "Invite"
        },
        meta: {
            allowAnonymous: true
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        beforeEnter: AuthGuard,
        meta: {
            name: 'Dashboard',
            allowAnonymous: true
        },
  },
  {
    path: '/managegroups',
    component: ManageGroups,
    beforeEnter: AuthGuard,
      name: 'ManageGroups',
    meta: {
        name: ManageGroups
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: AuthGuard,
    meta: {
      name: 'Dashboard',

        }
    },
    {
        path: '/profile',
        component: Profile,
        beforeEnter: AuthGuard,
        meta: {
            name: 'Profile',

        }
    },
    {
        path: '/user',
        component: User,
        beforeEnter: AuthGuard,
        meta: {
            name: 'User',

        }
    },
    {
        path: '/group',
        component: Group,
        beforeEnter: AuthGuard,
        meta: {
            name: 'Group',

        }
    },
    {
        path: '/invite',
        name: "Invite",
        component: Invite,
        beforeEnter: AuthGuard,
        meta: {
            name: 'Invite',

        }
    },
    {
        path: '/master-screen/document-status-code',
        component: DocumentStatusCode,
        beforeEnter: AuthGuard,
        meta: {
            name: 'DocumentStatusCode',

        }
    },
    {
        path: '/master-screen/additional-procedure-code',
        component: AdditionalProcedureCode,
        beforeEnter: AuthGuard,
        meta: {
            name: 'AdditionalProcedureCode',

        }
    },
    // {
    //     path: '/master-screen/commodity-code-master',
    //     component: CommodityCodeMaster,
    //     meta: {
    //         name: 'CommodityCodeMaster',

    //     },
    // },
    // {
    //     path: '/master-screen/commodity-code-lookup',
    //     component: CommodityCodeLookup,
    //     name: 'CommodityCodeLookup',
    //     props: true,
    // },
    {
        path: '/master-screen/four-digit-procedure-code',
        component: FourDigitProcedureCode,
        beforeEnter: AuthGuard,
        meta: {
            name: 'FourDigitProcedureCode',

        }
    },{
        path: '/master-screen/cds-code-lists',
        component: CDSCodeLists,
        beforeEnter: AuthGuard,
        meta: {
            name: 'CDSCodeLists',
        }
    },
    {
        path: '/master-screen/exchangerates',
        component: ExchangeRates,
        beforeEnter: AuthGuard,
        meta: {
            name: 'ExchangeRates',

        }
    },
    {
        path: '/master-screen/tradeblocs',
        component: TradeBlocs,
        beforeEnter: AuthGuard,
        meta: {
            name: 'TradeBlocs',

        }
    },
    {
        path: '/master-screen/countries',
        component: Countries,
        beforeEnter: AuthGuard,
        meta: {
            name: 'Countries',

        }
    },
    {
        path: '/master-screen/incoterms',
        component: IncoTerms,
        beforeEnter: AuthGuard,
        meta: {
            name: 'IncoTerms',

        }
    },
    {
        path: '/master-screen/freightlocations',
        component: FreightLocations,
        beforeEnter: AuthGuard,
        meta: {
            name: 'FreightLocations',

        }
    },
    // {
    //     path: '/master-screen/supervisingcustomsoffices',
    //     component: SupervisingCustomsOffices,
    //     beforeEnter: AuthGuard,
    //     meta: {
    //         name: 'SupervisingCustomsOffices',

    //     }
    // },
//    {
//         path: '/master-screen/supervisingcustomsoffices',
//         component: SupervisingCustomsOffices,
//         beforeEnter: AuthGuard,
//         meta: {
//             name: 'SupervisingCustomsOffices',

//         }
//     },
    {
        path: '/master-screen/customsOffices',
        component: CustomsOffices,
        beforeEnter: AuthGuard,
        meta: {
            name: 'CustomsOffices',

        }
    },
    {
        path: '/master-screen/packagetypes',
        component: PackageTypes,
        beforeEnter: AuthGuard,
        meta: {
            name: 'PackageTypes',

        }
    },
    {
        path: '/master-screen/NatureOfTransactions',
        component: NatureOfTransactions,
        beforeEnter: AuthGuard,
        meta: {
            name: 'NatureOfTransactions',
        }
    },
    {
        path: '/master-screen/Bordercontrolposts',
        component: BorderControlPosts,
        beforeEnter: AuthGuard,
        meta: {
            name: 'BorderControlPosts',
        }
    },
    {
        path: '/master-screen/goodslocations',
        component: GoodsLocations,
        beforeEnter: AuthGuard,
        meta: {
            name: 'GoodsLocations',
        }
    },
    {
        path: '/master-screen/transportmethodsofpayment',
        component: TransportMethodsOfPayment,
        beforeEnter: AuthGuard,
        meta: {
            name: 'TransportMethodsOfPayment',
        }
    },
    {
        path: '/master-screen/modesoftransport',
        component: ModesOfTransport,
        beforeEnter: AuthGuard,
        meta: {
            name: 'ModesOfTransport',
        }
    },
    {
        path: '/master-screen/identityofmeansoftransport',
        component: IdentityOfMeansOfTransport,
        beforeEnter: AuthGuard,
        meta: {
            name: 'IdentityOfMeansOfTransport',
        }
    },
    {
        path: '/master-screen/documentcode',
        component: DocumentCode,
        beforeEnter: AuthGuard,
        meta: {
            name: 'DocumentCode',
        }
    },
    {
        path: '/master-screen/conditioncode',
        component: ConditionCode,
        beforeEnter: AuthGuard,
        meta: {
            name: 'ConditionCode',
        }
    },
    {
        path: '/master-screen/taxtypes',
        component: TaxTypes,
        beforeEnter: AuthGuard,
        meta: {
            name: 'TaxTypes',
        }
    },
    {
        path: '/master-screen/partysubrole',
        component: PartySubRole,
        beforeEnter: AuthGuard,
        meta: {
            name: 'PartySubRole',
        }
    },
    {
        path: '/master-screen/vatpartysubrole',
        component: VatPartySubRole,
        beforeEnter: AuthGuard,
        meta: {
            name: 'VatPartySubRole',
        }
    },
    {
        path: '/master-screen/measurementunitcode',
        component: MeasurementUnitCode,
        beforeEnter: AuthGuard,
        meta: {
            name: 'MeasurementUnitCode',
        }
    },
    {
        path: '/master-screen/statementcode',
        component: StatementCode,
        beforeEnter: AuthGuard,
        meta: {
            name: 'StatementCode',
        }
    },
    {
        path: '/master-screen/unlocationcode',
        component: UNAndLOCODEGB,
        beforeEnter: AuthGuard,
        meta: {
            name: 'UNAndLOCODEGB',
        }
    },
    {
        path: '/master-screen/authorizationtypecode',
        component: AuthorizationTypeCode,
        beforeEnter: AuthGuard,
        meta: {
            name: 'AuthorizationTypeCode',
        }
    },
    {
        path: '/role',
        component: RoleManagement,
        beforeEnter: AuthGuard,
        meta: {
            name: 'Role',

        }
    },
    {
        path: '/user/modulePermissions',
        name: 'modulePermissons',
        component: modulePermissons,
        beforeEnter: AuthGuard,
        meta: {
            name: 'modulePermissons',
        }
    },
    {
        path: '/userManagement',
        component: userManagement,
        name: 'userManagement',
        beforeEnter: AuthGuard,
        meta: {
            name: 'UserManagement',
        }
    },
    {
        path: '/groupSiteManagement',
        component: groupSiteManagement,
        beforeEnter: AuthGuard,
        name: groupSiteManagement,
        meta: {
            name: 'GroupSiteManagement',

        }
    },
    {
        path: '/siteManagement',
        component: siteAccountManagement,
        beforeEnter: AuthGuard,
        name: 'siteAccountManagement',
        meta: {
            name: 'siteAccountManagement',

        }
    },
    {
        path: '/accountconfigurations',
        component: AccountConfiguration,
        beforeEnter: AuthGuard,
        meta: {
            name: 'Account Configuration',

        }
    },
    {
        path: '/user/AccountDetails/:id',
        name: 'AccountDetails',
        component: AccountDetails,
        beforeEnter: AuthGuard,
        meta: {
            name: 'AccountDetails',
        }
    },
    // {
    //   path: '/userdetails',
    //   name: 'userdetail',
    //   component: userdetail,
    //   meta: {
    //     name: 'userdetail',
    //   }
    // },
    {
        path: '/requestseal',
        component: RequestSeal,
        beforeEnter: AuthGuard,
        meta: {
            name: 'RequestSeal',

        }
    },
    {
        path: '/releasenotes',
        component: Releasenotes,
        beforeEnter: AuthGuard,
        meta: {
            name: 'Releasenotes',

        }
    },
    {
        path: '/supplychains',
        component: Supplychains,
        beforeEnter: AuthGuard,
        meta: {
            name: 'SupplyChains',

        }
    },
    {
        path: '/master-screen/wco-code-list',
        component: WCOCodeList,
        beforeEnter: AuthGuard,
        meta: {
            name: 'WCOCodeList',
        }
    },
    {
        path: '/master-screen/declaration-categories',
        component: DeclarationCategories,
        beforeEnter: AuthGuard,
        meta: {
            name: 'DeclarationCategories',
        }
    },
    {
        path: '/master-screen/airport-details',
        component: AirportDetails,
        meta: {
            name: 'AirportDetails',
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
