<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="pt-0 pb-0">
        <v-file-input
          truncate-length="20"
          accept=".xlsx"
          placeholder="Click here to select file" 
          v-model="currentFile"
        ></v-file-input>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="2" xl="2" class="pt-0 pb-0">
        <v-btn
          block
          :loading="buttonDisable"
          :disabled="buttonDisable"
          color="blue-grey"
          title="Upload file"
          class="white--text mt-3"
          @click="uploadFile">Upload
          <v-icon right> mdi-cloud-upload </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="3" xl="2" class="pt-0 pb-0" @if="CDSFileList.length">
        <v-select
            item-text="label"
            item-value="fileKey"
            v-model="CDSFile"
            :items="CDSFileList"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="4" md="4" lg="3" xl="2" class="pt-0 pb-0" @if="CDSFileList.length">
        <v-btn
          block
          :loading="buttonDisableDownload"
          :disabled="buttonDisableDownload"
          color="primary"
          class="white--text mt-3"
          @click="downloadFile">Download
          <v-icon right> mdi-cloud-download </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!--file change pop-up-->
    <div>
      <v-dialog v-model="dialog" md-fullscreen="true" persistent>
        <!--temporary button-->
        <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template> -->
        <v-card>
          <v-card-title>
            <span class="text-h5">Review the changes</span>
          </v-card-title>
          <v-card-text>
            <v-card  elevation="2" class="pt-3 pb-3 pl-3 pr-3 mb-3 text-right">
              <v-row
                align="start"
                no-gutters>
                <v-col cols="12" sm="6" class="text-start">
                  <span class="d-block pt-2 red--text">{{ statusNote }}</span>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-checkbox v-for="(item, index) in dataStatus" :key="index" :label="item.text" :class="item.class" v-model="item.checked" color="grey" @click="filterTable()"></v-checkbox>
                </v-col>
              </v-row>
            </v-card>

            <v-tabs v-model="tab">
              <v-tab v-for="(item, index) in tabs" :key="index" @click="setStatusNote(index)">{{ item.tabHeading }}</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(item, index) in tabs"
                :key="index">
                <v-card>                  
                  <v-data-table
                      :headers="item.tableHeader"
                      fixed-header
                      height="40vh"
                      :items="item.data"
                      :items-per-page="5">
                      <template v-slot:item="props">
                        <!-- dataStatus[props.item.dataStatus.toLowerCase().replace(/ /g,'')] ? dataStatus[props.item.dataStatus.toLowerCase().replace(/ /g,'')].color : 'grey--text' -->
                        <tr :class="status(props.item.dataStatus,'color')">
                          <td v-for="(item, index) in Object.fromEntries(Object.entries(props.item).filter(([key]) => !denyFields.includes(key)))" :key="index">
                            {{ Array.isArray(item) && item.join(', ') || item }}
                          </td>
                        </tr>
                      </template>
                  </v-data-table>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="discardfile()">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="saveFile()">
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    
  </div>
</template>


<script>
import MasterScreenService from "../services/master-screenservices.js";
import moment from 'moment';
export default {
  mixins: [],
  name: "UploadDocuments",
  data() {
    return {
      currentFile: undefined,
      buttonDisable: false,
      buttonDisableDownload: false,
      buttonDisableDownloadAdditional: false,
      activeRouterkey: undefined,
      dialog: false,
      showUpdated:false,
      tab: null,
      CDScodeLists: null,
      result: [],
      tabs:[],
      activeTab:0,
      dataStatus:[
        { class: "added", text:"Added", values:['added'], color:"blue--text", checked:true},
        { class: "updated", text:"Modified", values:['updated'], color:"orange--text", checked:true},
        { class: "deleted" , text:"Deleted", values:['deleted',"deletedormodified"], color:"red--text", checked:true},
        { class: "no-change", text:"No Change", values:['nochange'], color:"grey--text", checked:true },
      ],
      CDSFile:{},
      CDSFileList:[],
      reviewFileKey:'',
      reviewFileName:'',
      denyFields: ["dataStatus","id","sortOrder","status"],
      replaceHeaders:[
        {find : "isO3", replace:"ISO3"},
        {find : "iso", replace:"ISO"},
        {find : "jobType", replace: "Import/Export"},
        {find : "roroLocationEnabled", replace: "RORO Location Enabled"}
      ],
      statusNote:"",
    };
  },
  created() {
    this.activeRouterkey = sessionStorage.getItem("activeRouterkey");

    // Download files
    this.CDScodeLists = JSON.parse(localStorage.getItem('CDScodeLists'));
    if(this.CDScodeLists.length) {
      let current = this.CDScodeLists.find((l) => l.key == this.activeRouterkey);
      this.CDSFileList = current.files;
      this.CDSFile = this.CDSFileList.find((i) => i.version == current.version);
    }
  },
  methods: {
    uploadFile() {
      if (!this.currentFile) {
        this.sendResponse(undefined);
        return;
      } else {
        console.log(this.currentFile);
        this.buttonDisable = true;
        MasterScreenService.customUpload(this.currentFile, this.activeRouterkey)
          .then((response) => {
            this.reviewFileKey = response?.data[0].fileKey;
            this.reviewFileName = response?.data[0].fileName;
            this.buttonDisable = false;
            // console.log(response.data[0].data,"upload Response");
            if (response.status == 200) {
              this.currentFile = undefined;
              this.openDialog(response.data);
            } else {
              this.sendResponse(false);
            }
          })
          .catch((e) => {
            console.log(e);
            this.sendResponse(false);
            this.buttonDisable = false;
            return;
          });
      }
    },
    
    // Generate Tabs
    generateTabs(){
      let status = this.dataStatus.filter((i) => i.checked).map((i) => { return  i.values }).flat(2);
      let noChangeStatus = this.dataStatus[3].values;
      let forFilter = JSON.parse(JSON.stringify(this.result));
      this.tabs = forFilter.map((tab,index) => {
        tab.tableHeader = this.generateHeaders(this.result[index].data[this.result[index].data.length - 1]);
        tab.data = forFilter[index].data.filter((item) => status.includes(item.dataStatus.toLowerCase().replace(/ /g,'')));
        tab.noChangeRows = forFilter[index].data.filter((item) => noChangeStatus.includes(item.dataStatus.toLowerCase().replace(/ /g,''))).length;
        tab.totalRows = forFilter[index].data.length;
        return tab;
      });
      this.setStatusNote(this.activeTab);
    },

    // Generate Table headers
    generateHeaders(data){
      let tableHeaders = Object.keys(data).filter((i) => !this.denyFields.includes(i) );
      return tableHeaders.map((value) => {
        return {
            text: this.textTransform(value),
            align: 'start',
            sortable: true,
            value: value,
          }
      });
    },
    
    // Table head text transform
    textTransform(cText){
      let find = this.replaceHeaders.filter((i) => i.find.match(cText));
      if(find.length) return find[0].replace;
      
      const text = cText.replace(/([a-z])([A-Z])/g, '$1 $2').split(" ");
      let flat ="";
      text.forEach(word=>{    
          flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + " "
      });
      return flat;
    },
    
    // Get stattus properties
    status(dataStatus, property = 'class'){
      let status = this.dataStatus.filter((i) => i.values.includes(dataStatus.toLowerCase().replace(/ /g,'')));
      if(status.length) 
        return status[0][property];
      else
        return this.dataStatus[0][property];
    },

    // Filter table data
    filterTable(){
      this.generateTabs();
    },

    // Set status label
    setStatusNote(i){
      this.activeTab = i;
      let totalRows = this.tabs[i].totalRows;
      let noChangeRows = this.tabs[i].noChangeRows;
      let changedRows = totalRows - noChangeRows;
      if(totalRows == noChangeRows) this.statusNote = "No changes found."; else this.statusNote = ""+changedRows+" change(s) found."
    },

    openDialog(res){
        this.dialog = true;
        this.result = res;
        this.dataStatus = this.dataStatus.map((s) => { s.checked = true; return s; })
        this.generateTabs();
    },

    downloadFile() {
      let currentFileKey = typeof this.CDSFile === 'object' && this.CDSFile.fileKey || this.CDSFile;
      let currentFile =  this.CDSFileList.find((i) => i.fileKey === currentFileKey);
      this.buttonDisableDownload = true;
      MasterScreenService.customDownload(encodeURIComponent(currentFile.fileKey), encodeURIComponent(currentFile.fileName))
        .then((response) => {
          if (response && response.data) {
              let urllink = response.data.fileUri;
              this.buttonDisableDownload = false;
              const link = document.createElement("a");
              link.href = urllink;
              link.click();
              this.sendResponse("completed");
          } else {
              this.buttonDisableDownload = false;
              this.sendResponse("nofile");
          }
        })
        .catch((e) => {
          console.log(e);
          this.sendResponse("nofile");
          this.buttonDisableDownload = false;
        return;
      });
    },

    sendResponse(value) {
      this.$emit("sendResponse", value);
    },

    discardfile(){
    this.dialog = false;
    MasterScreenService.disacrdFileChanges(this.reviewFileKey).then((response) => {
    console.log(response);
    this.sendResponse('discard');
    }).catch((e) => {
        console.log(e);
     });
    },

    saveFile(){
    this.dialog = false;
    this.fileKey = 'CdsCodeList/DeclarationCategories (3).xlsx_0bbdff34-8074-490d-a69e-11c6ed78bd54';
    this.fileName
    let payload ={
        fileKey: this.reviewFileKey ,
        fileName: this.reviewFileName
    }

    MasterScreenService.saveFileChanges(this.activeRouterkey, payload).then((response) => {
    console.log(response,"save Response");    
    var updatedDetailsForScreen =
              "Last updated - " +
              moment(response?.data?.lastUpdated).format("DD-MM-YYYY") +
              " - By - " +
              response?.data?.updatedBy +
              " - File - " +
              response?.data?.fileName;
            sessionStorage.setItem(
              "updatedDetailsForScreen",
              updatedDetailsForScreen
            );
    this.sendResponse(true);
    }).catch((e) => {
        console.log(e);
     });
    }
  },
};
</script>
<style>
.v-input--checkbox{
  display: inline-block;
  margin-right: 6px;
  padding-top: 0;
  margin-top: 0 !important;
}
.v-input--checkbox .v-input__slot{
  margin-bottom: 0;
}
.v-input--checkbox .v-messages{
  display: none;
}
.v-input--checkbox:last-child{
  margin-right: inherit;
}
.v-input--checkbox.nochange label{
  color: gray;
}
.v-input--checkbox.added label{
  color: #1976d2;
}
.v-input--checkbox.updated label{
  color: orange;
}
.v-input--checkbox.deleted label{
  color: red;
}
</style>