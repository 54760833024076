<template lang="html">
    <div>
       <v-toolbar text color="white">
        <v-toolbar-title>CDS code Lists ({{getData.length}})</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
      </v-toolbar>
         <!-- <v-card-title class="justify-content-end">
            <v-flex md4 pr-2 >
                <v-text-field v-model="search" label="Search"
                append-icon="mdi-magnify" single-line hide-details ></v-text-field>
            </v-flex>
        </v-card-title> -->
        <div>
            <v-card class="mt-5 mb-5">
            <v-card-text class="resize">
                <v-container>
                <v-row>
                    <v-col cols="8">
                    </v-col>
                    <v-col cols="4">
                    <v-text-field v-model="search" label="Search" class="mt-3"
                        append-icon="mdi-magnify" single-line hide-details ></v-text-field>
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
            </v-card>
        </div>
      <v-data-table :headers="headers" :items="getData" 
           :search="search" class="elevation-1">
        <template v-slot:item="props">
          <tr>
            <td @click='getDescriptive(props.item)' class="text-xs-right pointer">{{ props.item.codeListName }}</td>
            <td class="text-xs-right" >{{ props.item.dataElement }}</td>
            <td class="text-xs-right">{{ props.item.sadBox }}</td>
            <td class="text-xs-right">{{ props.item.lastUpdated | formatDate}}</td>
            <td class="text-xs-right" >{{ props.item.method }}</td>
          </tr>
        </template>
        <template v-slot:no-data>
  <span>No data found</span>
</template>
        </v-data-table>
</div>
</template>
<script>
import MasterScreenService from "../../services/master-screenservices";
import router from "../../router/index";
import moment from 'moment';
export default {
  name: "CDSCodeLists",
  data() {
    return {
      search: "",
      getData: [],
      headers: [
        {
          text: "Code List Name",
          align: "left",
          sortable: true,
          value: "codeListName",
        },
        {
          text: "Data Element",
          align: "left",
          sortable: true,
          value: "dataElement",
        },
        {
          text: "SAD Box",
          align: "left",
          sortable: true,
          value: "sadBox",
        },
        {
          text: "Last Updated",
          align: "left",
          sortable: true,
          value: "lastUpdated",
        },
        {
          text: "Method",
          align: "left",
          sortable: true,
          value: "method",
        },
      ],
    };
  },
  computed: {},
  created() {
    MasterScreenService.getCDScodeLists()
      .then((response) => {
        this.getData = response.data;
        const files = JSON.stringify(this.getData.map((i) => { 
          return { 
            key: i.key,
            version:i.version,
            fileName:i.fileName,
            files:i.files.map((f) => {
              return {
                label: f.displayName,
                version:f.version,
                fileKey:f.fileKey,
                fileName:f.fileName
              }
            }) 
          }; 
        }));
        localStorage.setItem('CDScodeLists', files);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  methods: {
    getDescriptive(item) {
      var keySetForhref = [
        {
          key: "DocumentCodes",
          href: "/master-screen/documentcode",
        },
        {
          key: "DocumentStatusCodes",
          href: "/master-screen/document-status-code",
        },
        {
          key: "4DigitProcedureCodes",
          href: "/master-screen/four-digit-procedure-code",
        },
        {
          key: "3DigitProcedureCodes",
          href: "/master-screen/additional-procedure-code",
        },
        {
          key: "DeclarationCategory",
          href: "/master-screen/declaration-categories",
        },
        {
          key: "GoodsLocationCodes",
          href: "/master-screen/goodslocations",
        },
        {
          key: "TransportModeCodes",
          href: "/master-screen/modesoftransport",
        },
        {
          key: "StatementCodes",
          href: "/master-screen/statementcode",
        },
        {
          key: "CountryCodes",
          href: "/master-screen/countries",
        },
        {
          key: "UNLOCODEGB",
          href: "/master-screen/unlocationcode",
        },
        {
          key: "WCOCodes",
          href: "/master-screen/wco-code-list",
        },
      ];
      if (keySetForhref.filter((x) => x.key == item.key).length > 0) {
        var updatedDetailsForScreen = "Last updated - " +  moment(item?.lastUpdated).format('DD-MM-YYYY') + " - By - " + item?.updatedBy + " - File - " + item?.fileName;
        sessionStorage.setItem("updatedDetailsForScreen", updatedDetailsForScreen);
        let activeRouterkey = keySetForhref.filter((x) => x.key == item.key)[0]?.key;
        sessionStorage.setItem("activeRouterkey", activeRouterkey);
        router.push(keySetForhref.filter((x) => x.key == item.key)[0]?.href);
      } else {
        console.log("error");
      }
    },
  },
  filters: {
  formatDate: function (date) {
    return moment(date).format('DD-MM-YYYY');
  }
}
};
</script>

<style>
.justify-content-end {
  justify-content: Flex-end;
}
.resize {
  padding: 0px !important;
}
.pointer{
  cursor: pointer;
}
</style>